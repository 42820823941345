import { gql, useQuery } from '@apollo/client';
import { Query, QueryGetOrgBadgeCountsArgs, QueryGetUserBadgeCountsArgs } from 'middleware-types';
import { createContext, useContext } from 'react';
import { useSession } from './session';

// get the badge counts for notifications, user inbound connections etc.
const GET_USER_BADGE_COUNTS = gql`
	query GetUserBadgeCounts($userId: ID!) {
		getUserBadgeCounts(userId: $userId) {
			conversationsBadgeCount
			inboundRequestsBadgeCount
			associateInvitationsBadgeCount
			notificationsBadgeCount
		}
	}
`;

// Single hook to get badge counts for a user.
export const queryUserBadgeCounts = (userId: string) => {
	const { user } = useSession();

	const { data } = useQuery<
		Pick<Query, 'getUserBadgeCounts'> & {
			entityNetworkRequestsNotifications: Query['getUserBadgeCounts'];
		},
		QueryGetUserBadgeCountsArgs
	>(GET_USER_BADGE_COUNTS, {
		fetchPolicy: 'cache-and-network',
		pollInterval: 20000,
		skip: !!user?.siteUserId,
		variables: {
			userId,
		},
		onError: (e) => {
			// fail silently
			console.log(e);
		},
	});
	return {
		conversationsBadgeCount: data?.getUserBadgeCounts.conversationsBadgeCount,
		inboundRequestsBadgeCount: data?.getUserBadgeCounts.inboundRequestsBadgeCount,
		associateInvitationsBadgeCount: data?.getUserBadgeCounts.associateInvitationsBadgeCount,
		notificationsBadgeCount: data?.getUserBadgeCounts.notificationsBadgeCount,
	};
};

// get the badge counts for notifications, user inbound connections etc.
const GET_ORG_BADGE_COUNTS = gql`
	query GetOrgBadgeCounts($organizationId: ID!) {
		getOrgBadgeCounts(organizationId: $organizationId) {
			conversationsBadgeCount
			inboundRequestsBadgeCount
		}
	}
`;

// Single hook to get badge counts for an org.
export const useOrgBadgeCounts = (organizationId: string) => {
	const { user } = useSession();

	const { data } = useQuery<
		Pick<Query, 'getOrgBadgeCounts'> & {
			entityNetworkRequestsNotifications: Query['getOrgBadgeCounts'];
		},
		QueryGetOrgBadgeCountsArgs
	>(GET_ORG_BADGE_COUNTS, {
		fetchPolicy: 'cache-and-network',
		pollInterval: 20000,
		skip: !!user.siteUserId,
		variables: {
			organizationId,
		},
		onError: (e) => {
			// fail silently
			console.log(e);
		},
	});
	return {
		conversationsBadgeCount: data?.getOrgBadgeCounts.conversationsBadgeCount,
		inboundRequestsBadgeCount: data?.getOrgBadgeCounts.inboundRequestsBadgeCount,
	};
};

export const BADGE_COUNT_MAX = 99;

interface UserBadgeCounts {
	conversationsBadgeCount?: number;
	inboundRequestsBadgeCount?: number;
	associateInvitationsBadgeCount?: number;
	notificationsBadgeCount?: number;
}

/**
 *
 * @returns A user's badge counts.
 */
type UserBadgeCountsContextState = {
	userBadgeCounts: UserBadgeCounts;
};

const UserBadgeCountsContext = createContext<UserBadgeCountsContextState>({
	userBadgeCounts: {
		conversationsBadgeCount: 0,
		inboundRequestsBadgeCount: 0,
		associateInvitationsBadgeCount: 0,
		notificationsBadgeCount: 0,
	},
});

export const UserBadgeCountProvider = ({ children }: { children: any }) => {
	const { user } = useSession();

	if (user?.siteUserId) {
		return (
			<UserBadgeCountsContext.Provider
				value={{
					userBadgeCounts: {
						conversationsBadgeCount: 0,
						inboundRequestsBadgeCount: 0,
						associateInvitationsBadgeCount: 0,
						notificationsBadgeCount: 0,
					},
				}}>
				{children}
			</UserBadgeCountsContext.Provider>
		);
	}

	const data = queryUserBadgeCounts(user.userId);

	return (
		<UserBadgeCountsContext.Provider
			value={{
				userBadgeCounts: data,
			}}>
			{children}
		</UserBadgeCountsContext.Provider>
	);
};

export const useUserBadgeCounts = (): UserBadgeCountsContextState =>
	useContext<UserBadgeCountsContextState>(UserBadgeCountsContext);
