import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { useToast } from 'components/ui/toast';
import { UUID } from 'crypto';
import {
	ChatMessageRating,
	CustomInstructionsInput,
	Mutation,
	MutationChatConversationDeleteSavedArgs,
	MutationChatConversationSaveArgs,
	MutationChatCustomInstructionsUpdateArgs,
	MutationChatMessageLeaveFeedbackArgs,
	MutationChatMessageRequestArgs,
	Query,
	QueryChatConversationReplaySavedArgs,
	QueryChatCustomInstructionsGetArgs,
	SaveConversationRequest,
	UserChatFile,
	UserChatMessage,
} from 'middleware-types';
import { useState } from 'react';
import { handleNoResponse, responseHasErrors } from 'utils/errors';
import { useSession } from 'utils/session';

const CHAT_RESPONSE_FIELDS = gql`
	fragment ChatResponseFields on UserChatMessageResponse {
		chatConversationId
		chatMessages {
			chatMessageId
			sequence
			role
			content
			functionName
			rating
			files {
				chatFileId
				name
			}
			footnotes {
				chatFootnoteId
				url
				fileName
				snippet
				urlDisplayName
			}
		}
	}
`;

const GET_LATEST = gql`
	${CHAT_RESPONSE_FIELDS}
	query GetLatest($userId: ID!) {
		chatConversationGetLatest(userId: $userId) {
			conversation {
				...ChatResponseFields
			}
		}
	}
`;

const CHAT_REQUEST = gql`
	${CHAT_RESPONSE_FIELDS}
	mutation ChatRequest($userId: ID!, $request: UserChatMessageRequest!) {
		chatMessageRequest(userId: $userId, request: $request) {
			...ChatResponseFields
		}
	}
`;

const GET_CONVERSATION_REPLAY = gql`
	${CHAT_RESPONSE_FIELDS}
	query replayChat($userId: ID!, $conversationId: ID!) {
		chatConversationReplaySaved(userId: $userId, conversationId: $conversationId) {
			...ChatResponseFields
		}
	}
`;

export const useEvox = () => {
	const { user } = useSession();
	const toast = useToast();

	const [conversationId, setConversationId] = useState<UUID | null>(null);
	const [messages, setMessages] = useState<UserChatMessage[]>([]);
	const [pendingRequest, setPendingRequest] = useState<string | null>(null);
	const [pendingFileRequest, setPendingFileRequest] = useState<UserChatFile[] | undefined>(
		undefined
	);
	const [chatHasBeenReset, setChatHasBeenReset] = useState(false);

	const { loading: latestConversationLoading } = useQuery<
		Pick<Query, 'chatConversationGetLatest'>
	>(GET_LATEST, {
		variables: {
			userId: user.userId,
		},
		onCompleted: (data) => {
			const latestConversation = data.chatConversationGetLatest.conversation;
			if (latestConversation === null || latestConversation === undefined) return;
			setConversationId(latestConversation.chatConversationId as UUID);
			const messages = latestConversation.chatMessages ?? [];
			setMessages(messages);
		},
		fetchPolicy: 'no-cache',
	});

	const [_sendRequest, { loading: requestLoading }] = useMutation<
		Pick<Mutation, 'chatMessageRequest'>,
		MutationChatMessageRequestArgs
	>(CHAT_REQUEST);

	const [_replayConversation, { loading: replayLoading }] = useLazyQuery<
		Pick<Query, 'chatConversationReplaySaved'>,
		QueryChatConversationReplaySavedArgs
	>(GET_CONVERSATION_REPLAY);

	const resetChat = () => {
		setConversationId(null);
		setMessages([]);
		setPendingRequest(null);
		setPendingFileRequest(undefined);
		setChatHasBeenReset(true);
	};

	const sendRequest = (request: string, requestFiles?: UserChatFile[]) => {
		setPendingRequest(request);
		let fileIds;
		if (requestFiles) {
			fileIds =
				requestFiles && requestFiles.length > 0
					? requestFiles.map((file) => file.chatFileId)
					: undefined;
			setPendingFileRequest(requestFiles);
		}

		_sendRequest({
			variables: {
				userId: user.userId,
				request: {
					conversationId: conversationId ?? undefined,
					content: request,
					fileIds: fileIds,
				},
			},
		})
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) return;
				if (!res.data) return;
				setPendingRequest(null);
				setPendingFileRequest(undefined);
				if (conversationId === null) {
					setConversationId(res.data.chatMessageRequest.chatConversationId as UUID);
				}
				const newMessages = res.data.chatMessageRequest.chatMessages;
				if (newMessages !== undefined) {
					setMessages((currentMessages) => [...currentMessages, ...newMessages]);
				}
			})
			.catch((e) => {
				if (e.message === 'The user aborted a request.') {
					return;
				} else if (e && e.graphQLErrors && e.graphQLErrors.length > 0) {
					// Extract any user message that might have been provided with the returned error so we can
					// display a more helpful error. Else, it will display a generic error message.
					let userMessage =
						e.graphQLErrors[0]?.extensions?.response?.body?.details?.userMessage;
					if (userMessage !== undefined) {
						toast.push(userMessage, { variant: 'error' });
						return;
					}
				}

				handleNoResponse({ toast });
			});
	};

	const replayConversation = (conversationId: string) => {
		_replayConversation({ variables: { userId: user.userId, conversationId } })
			.then((res) => {
				if (res.data) {
					setChatHasBeenReset(false);
					setConversationId(
						res.data.chatConversationReplaySaved.chatConversationId as UUID
					);
					const messages = res.data.chatConversationReplaySaved.chatMessages ?? [];
					setMessages(messages);
				}
			})
			.catch(() => {
				handleNoResponse({ toast });
			});
	};

	return {
		conversationId,
		messages,
		pendingRequest,
		pendingFileRequest,
		resetChat,
		sendRequest,
		requestLoading,
		replayConversation,
		replayLoading,
		chatHasBeenReset,
		latestConversationLoading,
	};
};

const GET_SAVED_CONVERSATIONS = gql`
	query getSavedChats($userId: ID!) {
		chatConversationGetAllSaved(userId: $userId) {
			savedConversations {
				conversationId
				conversationName
			}
		}
	}
`;

export const useSavedConversations = () => {
	const { user } = useSession();
	const toast = useToast();

	const { data, loading } = useQuery<Pick<Query, 'chatConversationGetAllSaved'>>(
		GET_SAVED_CONVERSATIONS,
		{
			variables: {
				userId: user.userId,
			},
			onError: () =>
				toast.push('Unable to load saved conversations', {
					variant: 'error',
				}),
		}
	);
	const savedConversations = data?.chatConversationGetAllSaved.savedConversations ?? [];

	return {
		savedConversations,
		loading,
	};
};

const SAVE_CONVERSATION = gql`
	mutation saveConversation(
		$userId: ID!
		$conversationId: ID!
		$request: SaveConversationRequest!
	) {
		chatConversationSave(userId: $userId, conversationId: $conversationId, request: $request) {
			conversationId
			conversationName
		}
	}
`;

export const useSaveConversation = () => {
	const toast = useToast();
	const { user } = useSession();

	const [_saveConversation] = useMutation<
		Pick<Mutation, 'chatConversationSave'>,
		MutationChatConversationSaveArgs
	>(SAVE_CONVERSATION, {
		refetchQueries: [GET_SAVED_CONVERSATIONS],
		awaitRefetchQueries: true,
	});

	const saveConversation = async (conversationId: string, request: SaveConversationRequest) => {
		return await _saveConversation({
			variables: {
				userId: user.userId,
				conversationId,
				request,
			},
		})
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				toast.push('Successfully saved conversation.', {
					variant: 'success',
				});
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	return saveConversation;
};

const DELETE_CONVERSATION = gql`
	mutation deleteConversation($userId: ID!, $conversationId: ID!) {
		chatConversationDeleteSaved(userId: $userId, conversationId: $conversationId)
	}
`;

export const useDeleteSavedConversation = () => {
	const toast = useToast();
	const { user } = useSession();

	const [_deleteConversation, { loading }] = useMutation<
		Pick<Mutation, 'chatConversationDeleteSaved'>,
		MutationChatConversationDeleteSavedArgs
	>(DELETE_CONVERSATION, {
		refetchQueries: [GET_SAVED_CONVERSATIONS],
		awaitRefetchQueries: true,
	});

	const deleteConversation = async (conversationId: string) => {
		return await _deleteConversation({
			variables: {
				userId: user.userId,
				conversationId,
			},
		})
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				toast.push('Successfully deleted conversation.', {
					variant: 'success',
				});
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	return { deleteConversation, loading };
};

const GET_CHAT_INSTRUCTIONS = gql`
	query GetChatInstructions($userId: ID!) {
		chatCustomInstructionsGet(userId: $userId) {
			enableCustomInstructions
			about
			responseTargeting
		}
	}
`;

const UPDATE_CHAT_INSTRUCTIONS = gql`
	mutation UpdateChatInstructions($userId: ID!, $customInstructions: CustomInstructionsInput!) {
		chatCustomInstructionsUpdate(userId: $userId, customInstructions: $customInstructions)
	}
`;

export const useChatInstructions = () => {
	const { user } = useSession();
	const toast = useToast();

	const { data, loading } = useQuery<
		Pick<Query, 'chatCustomInstructionsGet'>,
		QueryChatCustomInstructionsGetArgs
	>(GET_CHAT_INSTRUCTIONS, {
		variables: {
			userId: user.userId,
		},
		onError: () =>
			toast.push('Unable to load chat instructions', {
				variant: 'error',
			}),
	});

	const [_updateChatInstructions] = useMutation<
		Pick<Mutation, 'chatCustomInstructionsUpdate'>,
		MutationChatCustomInstructionsUpdateArgs
	>(UPDATE_CHAT_INSTRUCTIONS, {
		refetchQueries: [GET_CHAT_INSTRUCTIONS],
		awaitRefetchQueries: true,
	});

	const updateChatInstructions = async (customInstructions: CustomInstructionsInput) => {
		return await _updateChatInstructions({
			variables: { userId: user.userId, customInstructions },
		})
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				toast.push('Successfully updated custom chat instructions.', {
					variant: 'success',
				});
				return true;
			})
			.catch((e) => {
				console.log(JSON.stringify(e));
				handleNoResponse({ toast });
				return false;
			});
	};

	return {
		chatInstructions: data?.chatCustomInstructionsGet,
		loading,
		updateChatInstructions,
	};
};

const LEAVE_FEEDBACK = gql`
	mutation LeaveFeedback($userId: ID!, $messageId: ID!, $request: ChatMessageFeedback!) {
		chatMessageLeaveFeedback(userId: $userId, messageId: $messageId, request: $request) {
			chatMessageId
			rating
		}
	}
`;

export const useEvoxFeedback = () => {
	const { user } = useSession();

	const [_leaveFeedback] = useMutation<
		Pick<Mutation, 'chatMessageLeaveFeedback'>,
		MutationChatMessageLeaveFeedbackArgs
	>(LEAVE_FEEDBACK);

	const leaveFeedback = (messageId: string, rating: ChatMessageRating) => {
		_leaveFeedback({
			variables: {
				userId: user.userId,
				messageId: messageId,
				request: {
					rating,
				},
			},
		});
	};

	return { leaveFeedback };
};
