import {
	AddCircleOutlined,
	BadgeOutlined,
	DeleteOutlined,
	EditOutlined,
	ErrorOutlined,
	MoreHorizOutlined,
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
	Avatar,
	Box,
	Button,
	Card,
	CardContent,
	CardHeader,
	Grid,
	IconButton,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	Skeleton,
	Stack,
	Tab,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Tabs,
	Typography,
} from '@mui/material';
import {
	useCanAdjusterLicensingManualMutation,
	useCanLicensingQuery,
	useLicenseTypesQuery,
} from 'components/pages/user/profile/licensing/can/hooks';
import { Alert } from 'components/ui/alert';
import { EmptyStateAvatar } from 'components/ui/empty-state-avatar';
import { DatePickerField, SelectField, TextField } from 'components/ui/fields';
import { Flag } from 'components/ui/flag';
import {
	ConfirmModalContent,
	ModalActionButton,
	ModalActions,
	ModalContent,
	useModal,
} from 'components/ui/modal';
import { PageContent, PageTitle } from 'components/ui/page';
import { useToast } from 'components/ui/toast';
import { format } from 'date-fns';
import { Formik } from 'formik';
import {
	AddressFieldLookup,
	CanAdjusterLicensingOutput,
	CanLicenseTypeLookup,
	CanProvinceAdjusterLicenseOutput,
} from 'middleware-types';
import { useEffect, useState } from 'react';
import { useCanProvinces } from 'utils/useCountries';
import { UserProfileAspectType, usePrivacy } from 'utils/usePrivacy';
import { useValidation } from 'utils/useValidation';
import { zoneDateOnly } from 'utils/utils';
import * as Yup from 'yup';

/**
 * CanLicenseInformationEdit - Edit view of the top card on CAN Licenses.
 *
 * @param {({
 *     userId: string;
 *     licensing: CanAdjusterLicensingOutput;
 *     onFinishedEdit: () => void;
 * })} props
 * @return {*}
 */
const CanLicenseInformationEdit = (props: {
	userId: string;
	licensing: CanAdjusterLicensingOutput;
	onFinishedEdit: () => void;
}) => {
	const validation = useValidation('CanAdjusterLicensing');
	const mutation = useCanAdjusterLicensingManualMutation(props.userId);
	const toast = useToast();

	type FormValues = {
		ciprNumber: string | undefined;
	};

	const onSubmit = (values: FormValues) => {
		mutation
			.updateLicenses({
				...values,
				ciprNumber: values.ciprNumber ?? '',
				provincesLicensing: props.licensing?.provincesLicensing ?? [],
			})
			.then((success) => {
				if (!success) return;
				toast.push('Successfully updated licensing data.', {
					variant: 'success',
				});
				props.onFinishedEdit();
			});
	};

	return (
		<Card>
			<Formik<FormValues>
				initialValues={{
					ciprNumber: props.licensing?.ciprNumber ?? '',
				}}
				validationSchema={validation.schema}
				onSubmit={onSubmit}>
				{(fprops) => (
					<>
						<CardHeader
							title={
								<Typography
									variant="h2"
									height="2rem"
									marginBottom={{ xs: 2, md: 'unset' }}>
									Canada Licensing Information
								</Typography>
							}
							action={
								<Stack
									direction="row"
									spacing={1}
									style={{ marginRight: '0.5rem' }}>
									<Button
										variant="outlined"
										disabled={fprops.isSubmitting}
										onClick={props.onFinishedEdit}>
										Cancel
									</Button>
									<LoadingButton
										color="primary"
										variant="contained"
										loading={fprops.isSubmitting}
										disabled={
											validation.loading || !fprops.isValid || !fprops.dirty
										}
										onClick={() => fprops.submitForm()}>
										Save
									</LoadingButton>
								</Stack>
							}
						/>
						<CardContent>
							{mutation.error && <Alert error={mutation.error} />}
							<Grid container spacing={1} columnSpacing={2}>
								<Grid xs={12} md={12} item>
									{validation.loading ? (
										<Skeleton
											variant="rectangular"
											height={30}
											width={'100%'}
										/>
									) : (
										<TextField
											label="CIPR Number"
											name="ciprNumber"
											required
											fullWidth
										/>
									)}
								</Grid>
							</Grid>
						</CardContent>
					</>
				)}
			</Formik>
		</Card>
	);
};

/**
 * CanLicenseInformationRead - Read view of the top Canada card.
 *
 * @param {({
 *     userId: string;
 * 	   displayName: string | undefined;
 *     licensing: CanAdjusterLicensing
 * })} props
 * @return {*}
 */
const CanLicenseInformationRead = (props: {
	userId: string;
	displayName: string | undefined;
	licensing: CanAdjusterLicensingOutput;
}) => {
	const [isEditing, setIsEditing] = useState<boolean>(false);
	const { hasAccess } = usePrivacy(props.userId);
	const hasAccessAlterLicensing = hasAccess(UserProfileAspectType.AlterLicensing);

	if (isEditing)
		return (
			<CanLicenseInformationEdit
				userId={props.userId}
				licensing={props.licensing}
				onFinishedEdit={() => setIsEditing(false)}
			/>
		);

	return (
		<Card>
			<CardHeader
				title={
					<Typography variant="h2" height="2rem" marginBottom={{ xs: 2, md: 'unset' }}>
						Canada Licensing Information
					</Typography>
				}
				action={
					hasAccessAlterLicensing && (
						<IconButton onClick={() => setIsEditing(true)}>
							<EditOutlined />
						</IconButton>
					)
				}
			/>
			<CardContent>
				<Grid
					container
					alignItems="center"
					justifyContent={{ xs: 'center', md: 'unset' }}
					spacing={1}>
					<Grid item sm={12} md={3}>
						<Stack alignItems="center" spacing={1}>
							<Avatar
								variant="rounded"
								style={{ width: '100px', height: '100px', fontSize: '4rem' }}>
								<Flag code="CA" className="h-full w-full bg-cover" />
							</Avatar>
							<Typography variant="h2">{props.displayName}</Typography>
						</Stack>
					</Grid>
					<Grid container item sm={12} md={9} alignContent="flex-start" spacing={2}>
						<Grid item xs={12} md={4}>
							<Stack direction="row" spacing={2}>
								<BadgeOutlined fontSize="small" color="primary" />
								<strong>CIPR Number</strong>
							</Stack>
						</Grid>
						<Grid item xs={12} md={8}>
							{props.licensing?.ciprNumber}
						</Grid>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
};

/**
 * AddOrUpdateLicenseModal - Update License modal
 *
 * @param {{ userId: string; licensing: CanAdjusterLicensingOutput; province: AddressFieldLookup; licenseIndex?: number }} props
 * @return {*}
 */
const AddOrUpdateLicenseModal = (props: {
	userId: string;
	licensing: CanAdjusterLicensingOutput;
	province: AddressFieldLookup;
	licenseIndex?: number;
}) => {
	const toast = useToast();
	const { closeModal } = useModal();
	const { licenseTypes } = useLicenseTypesQuery(props?.province.value);
	const { error, updateLicenses } = useCanAdjusterLicensingManualMutation(props.userId);

	const selectedLicense = props.licensing.provincesLicensing
		?.find((sl) => sl.provinceAddressFieldLookupId === props.province.id)
		?.provinceLicenses?.find((l, i) => i === props.licenseIndex);

	type FormValues = {
		number: string;
		adjusterAlias: string | undefined | null;
		supervisingEntity: string | undefined | null;
		originalIssueDate: Date | null;
		expirationDate: Date | null;
		licenseTypeId: string;
		designation: 'None' | 'Cip' | 'Fcip' | undefined;
	};

	const validationSchema = Yup.object().shape({
		originalIssueDate: Yup.date()
			.nullable()
			.typeError('Please enter a valid date in MM/DD/YYYY format')
			.test('expirationDate', 'Date is after expiration', (val, context) => {
				if (val && context.parent.expirationDate && val > context.parent.expirationDate) {
					return false;
				}
				return true;
			}),
		expirationDate: Yup.date()
			.nullable()
			.typeError('Please enter a valid date in MM/DD/YYYY format')
			.test('originalIssueDate', 'Date is before issue date', (val, context) => {
				if (
					val &&
					context.parent.originalIssueDate &&
					val < context.parent.originalIssueDate
				) {
					return false;
				}
				return true;
			}),
		number: Yup.string()
			.nullable()
			.test('duplicateLicenseNumber', 'Duplicate license number', (val) => {
				if (
					!selectedLicense &&
					props.licensing?.provincesLicensing
						?.find((pl) => pl.provinceAddressFieldLookupId === props.province.id)
						?.provinceLicenses?.find((pl) => pl.number === val)
				)
					return false;
				return true;
			}),
	});

	const onSubmit = async (values: FormValues) => {
		await updateLicenses({
			...props.licensing,
			provincesLicensing: props.licensing.provincesLicensing.map((pl) => {
				if (pl.provinceAddressFieldLookupId !== props.province.id) return pl;
				const plicenses = pl.provinceLicenses.filter((_, i) => i !== props.licenseIndex);
				const provinceLicenses = [
					// part of the array before the specified index
					...plicenses.slice(0, props.licenseIndex ?? 0),
					{
						...values,
						adjusterAlias: values.adjusterAlias ?? undefined,
						supervisingEntity: values.supervisingEntity ?? undefined,
						designation: values?.designation ?? 'None',
						expirationDate: values.expirationDate
							? format(values.expirationDate, 'yyyy-MM-dd')
							: null,
						originalIssueDate: values.originalIssueDate
							? format(values.originalIssueDate, 'yyyy-MM-dd')
							: null,
					},
					// part of the array after the specified index
					...plicenses.slice(props.licenseIndex ?? 0),
				];
				return {
					...pl,
					provinceLicenses,
				};
			}),
		}).then((success) => {
			if (!success) return;
			toast.push('Successfully updated licensing data.', {
				variant: 'success',
			});
			closeModal();
		});
	};

	return (
		<Formik<FormValues>
			initialValues={{
				number: selectedLicense?.number ?? '',
				adjusterAlias: selectedLicense?.adjusterAlias ?? '',
				supervisingEntity: selectedLicense?.supervisingEntity ?? '',
				originalIssueDate: selectedLicense?.originalIssueDate
					? zoneDateOnly(selectedLicense.originalIssueDate)
					: null,
				expirationDate: selectedLicense?.expirationDate
					? zoneDateOnly(selectedLicense.expirationDate)
					: null,
				licenseTypeId: selectedLicense?.licenseTypeId ?? '',
				designation: selectedLicense?.designation as 'None' | 'Cip' | 'Fcip' | undefined,
			}}
			validationSchema={validationSchema}
			onSubmit={onSubmit}>
			{(formik) => (
				<>
					<ModalContent>
						{error && <Alert error={error} />}
						<Grid container spacing={3}>
							<Grid xs={12} md={6} item>
								<TextField
									label="License Number"
									required
									type="text"
									name="number"
								/>
							</Grid>
							<Grid xs={12} md={6} item>
								<SelectField name="licenseTypeId" required label="License Type">
									{licenseTypes &&
										licenseTypes.map((licenseType) => (
											<MenuItem key={licenseType.id} value={licenseType.id}>
												{licenseType.name}
											</MenuItem>
										))}
								</SelectField>
							</Grid>
							<Grid xs={12} md={6} item>
								<DatePickerField
									label="Original Issue Date"
									name="originalIssueDate"
								/>
							</Grid>
							<Grid xs={12} md={6} item>
								<DatePickerField label="Expiration Date" name="expirationDate" />
							</Grid>
							<Grid xs={12} md={6} item>
								<SelectField
									label="Designation"
									name="designation"
									required
									fullWidth>
									<MenuItem value="None">None</MenuItem>
									<MenuItem value="Cip">CIP</MenuItem>
									<MenuItem value="Fcip">FCIP</MenuItem>
								</SelectField>
							</Grid>
							<Grid xs={12} md={6} item>
								<TextField
									label="Supervising Entity"
									type="text"
									name="supervisingEntity"
								/>
							</Grid>
							<Grid xs={12} md={6} item>
								<TextField label="Alias" type="text" name="adjusterAlias" />
							</Grid>
						</Grid>
					</ModalContent>
					<ModalActions>
						<ModalActionButton variant="outlined">Cancel</ModalActionButton>
						<LoadingButton
							loading={formik.isSubmitting}
							disabled={!formik.dirty || !formik.isValid}
							variant="contained"
							onClick={formik.submitForm}
							color="primary">
							{props.licenseIndex !== undefined ? 'Update License' : 'Add License'}
						</LoadingButton>
					</ModalActions>
				</>
			)}
		</Formik>
	);
};

/**
 * ProvinceLicenseRow - A single row in the Licenses table
 *
 * @param {{ ...props }}
 * @return {*}
 */
const ProvinceLicenseRow = ({
	index,
	license,
	licenseTypes,
	hasAccessAlterLicensing,
	addOrUpdateLicense,
	deleteLicense,
}: {
	index: number;
	license: CanProvinceAdjusterLicenseOutput;
	licenseTypes: CanLicenseTypeLookup[];
	hasAccessAlterLicensing: boolean;
	addOrUpdateLicense: (index?: number) => void;
	deleteLicense: (index: number) => void;
}) => {
	const [licenseContextEl, setLicenseContextEl] = useState<HTMLElement | null>(null);
	return (
		<TableRow>
			<TableCell>{license.number}</TableCell>
			<TableCell>
				{licenseTypes.find((licenseType) => licenseType.id === license.licenseTypeId)
					?.name ?? ''}
			</TableCell>
			<TableCell>
				{license.originalIssueDate
					? format(zoneDateOnly(license.originalIssueDate), 'MM/dd/yyyy')
					: ''}
			</TableCell>
			<TableCell>
				{license.originalIssueDate
					? format(zoneDateOnly(license.expirationDate), 'MM/dd/yyyy')
					: ''}
			</TableCell>
			<TableCell>{license.designation.toUpperCase()}</TableCell>
			<TableCell>{license.supervisingEntity}</TableCell>
			<TableCell>{license.adjusterAlias}</TableCell>
			<TableCell size="small" padding="none">
				{hasAccessAlterLicensing && (
					<IconButton onClick={(ev) => setLicenseContextEl(ev.currentTarget)}>
						<MoreHorizOutlined />
					</IconButton>
				)}
				<Menu
					open={Boolean(licenseContextEl)}
					anchorEl={licenseContextEl}
					onClose={() => setLicenseContextEl(null)}>
					<MenuItem onClick={() => addOrUpdateLicense(index)}>
						<ListItemIcon>
							<EditOutlined />
						</ListItemIcon>
						<ListItemText>Update License</ListItemText>
					</MenuItem>
					<MenuItem onClick={() => deleteLicense(index)}>
						<ListItemIcon>
							<DeleteOutlined />
						</ListItemIcon>
						<ListItemText>Delete License</ListItemText>
					</MenuItem>
				</Menu>
			</TableCell>
		</TableRow>
	);
};

/**
 * ProvinceLicense - A single province license tab.
 *
 * @param {{ userId: string; licensing: CanAdjusterLicensing; province: AddressFieldLookup }} props
 * @return {*}
 */
const ProvinceLicense = (props: {
	userId: string;
	licensing: CanAdjusterLicensingOutput;
	province: AddressFieldLookup;
}) => {
	const { province, licensing, userId } = props;
	const { showModal, closeModal } = useModal();
	const licenses = licensing.provincesLicensing.find(
		(pl) => pl.provinceAddressFieldLookupId === province.id
	);
	if (!licenses) return <></>;
	const { hasAccess } = usePrivacy(userId);
	const hasAccessAlterLicensing = hasAccess(UserProfileAspectType.AlterLicensing);
	const { licenseTypes } = useLicenseTypesQuery(props.province.value);
	const addOrUpdateLicense = (index?: number) => {
		showModal({
			title: `${index !== undefined ? 'Update' : 'Add'} License for ${
				props.province.displayName
			}`,
			maxWidth: 'md',
			content: (
				<AddOrUpdateLicenseModal
					userId={userId}
					licensing={licensing}
					province={province}
					licenseIndex={index}
				/>
			),
		});
	};

	const deleteLicense = (index: number) => {
		const DeleteActions = () => {
			const toast = useToast();
			const { loading, updateLicenses } = useCanAdjusterLicensingManualMutation(userId);
			return (
				<ModalActions>
					<ModalActionButton size="large" variant="outlined">
						Cancel
					</ModalActionButton>
					<LoadingButton
						size="large"
						variant="contained"
						color="error"
						loading={loading}
						onClick={async () => {
							await updateLicenses({
								...licensing,
								provincesLicensing: licensing.provincesLicensing.map((pl) => {
									if (pl.provinceAddressFieldLookupId !== province.id) return pl;
									return {
										...pl,
										provinceLicenses: pl.provinceLicenses.filter(
											(v, i) => i !== index
										),
									};
								}),
							}).then((success) => {
								if (!success) return;
								toast.push('Successfully updated licensing data.', {
									variant: 'success',
								});
								closeModal();
							});
						}}>
						Delete
					</LoadingButton>
				</ModalActions>
			);
		};

		showModal({
			title: 'Are you sure?',
			content: (
				<ConfirmModalContent
					visual={
						<EmptyStateAvatar
							avatarProps={{ bgcolor: 'error.50' }}
							iconProps={{ color: 'error.500' }}
							icon={<ErrorOutlined />}
						/>
					}
					subheadline={`Delete license for ${province.displayName}`}
					informativeContent="Do you really want to delete the license? This process cannot be undone."
				/>
			),
			actions: <DeleteActions />,
		});
	};

	const deleteProvince = () => {
		const DeleteActions = () => {
			const toast = useToast();
			const { loading, updateLicenses } = useCanAdjusterLicensingManualMutation(userId);
			return (
				<ModalActions>
					<ModalActionButton size="large" variant="outlined">
						Cancel
					</ModalActionButton>
					<LoadingButton
						size="large"
						variant="contained"
						color="error"
						loading={loading}
						onClick={async () => {
							await updateLicenses({
								...licensing,
								provincesLicensing: licensing.provincesLicensing.filter(
									(pl) => pl.provinceAddressFieldLookupId !== province.id
								),
							}).then((success) => {
								if (!success) return;
								toast.push('Successfully updated licensing data.', {
									variant: 'success',
								});
								closeModal();
							});
						}}>
						Delete
					</LoadingButton>
				</ModalActions>
			);
		};

		showModal({
			title: 'Are you sure?',
			content: (
				<ConfirmModalContent
					visual={
						<EmptyStateAvatar
							avatarProps={{ bgcolor: 'error.50' }}
							iconProps={{ color: 'error.500' }}
							icon={<ErrorOutlined />}
						/>
					}
					subheadline={`Delete licenses for ${province.displayName}`}
					informativeContent="Do you really want to delete the licensing data for this province? This process cannot be undone."
				/>
			),
			actions: <DeleteActions />,
		});
	};

	return (
		<Stack spacing={2}>
			<Stack
				direction="row"
				justifyContent="space-between"
				alignItems="center"
				style={{ marginRight: '-0.5rem' }}>
				<Stack>
					<Typography variant="h1">{province?.displayName ?? ''}</Typography>
				</Stack>
				{hasAccessAlterLicensing && (
					<IconButton onClick={() => deleteProvince()}>
						<DeleteOutlined />
					</IconButton>
				)}
			</Stack>
			<Stack direction="row" alignItems="center">
				<Typography variant="h2">Licenses</Typography>
				{hasAccessAlterLicensing && (
					<IconButton onClick={() => addOrUpdateLicense(undefined)}>
						<AddCircleOutlined color="primary" />
					</IconButton>
				)}
			</Stack>
			{licenses.provinceLicenses.length > 0 ? (
				<TableContainer>
					<Table stickyHeader>
						<TableHead>
							<TableRow>
								<TableCell style={{ width: '15%' }}>License Number</TableCell>
								<TableCell style={{ width: '15%' }}>License Type</TableCell>
								<TableCell style={{ width: '10%' }}>Original Issue Date</TableCell>
								<TableCell style={{ width: '10%' }}>Expiration Date</TableCell>
								<TableCell style={{ width: '10%' }}>Designation</TableCell>
								<TableCell style={{ width: '15%' }}>Supervising Entity</TableCell>
								<TableCell style={{ width: '15%' }}>Alias</TableCell>
								<TableCell size="small" style={{ width: '7%' }}></TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{licenses.provinceLicenses.map((license, i) => (
								<ProvinceLicenseRow
									key={i}
									index={i}
									license={license}
									licenseTypes={licenseTypes}
									hasAccessAlterLicensing={hasAccessAlterLicensing}
									addOrUpdateLicense={addOrUpdateLicense}
									deleteLicense={deleteLicense}
								/>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			) : (
				<Box
					width="100%"
					bgcolor="#f2f2f2"
					padding="35px"
					borderRadius="0.5rem"
					textAlign="center">
					<span>No licenses added</span>
				</Box>
			)}
		</Stack>
	);
};

/**
 * ManageCANLicenseScreen - Manage CAN Licensing screen.  Lets you read and edit licensing
 * for Canada.
 *
 * @return {*}
 */
export const ManageCanLicensePage = ({ userId }: { userId: string }) => {
	const { loading: loadingProvinces, provinces } = useCanProvinces();
	const { licensing, loading, displayName } = useCanLicensingQuery(userId);
	const [selectedTab, setSelectedTab] = useState<number>(0);
	const [contextEl, setContextEl] = useState<HTMLElement | null>(null);
	const provincesToRemove = ['Quebec', 'Nunavut', 'Yukon Territory'];
	const toast = useToast();
	const mutation = useCanAdjusterLicensingManualMutation(userId);
	const { hasAccess } = usePrivacy(userId);
	const hasAccessAlterLicensing = hasAccess(UserProfileAspectType.AlterLicensing);
	useEffect(() => {
		// When we get a new set of licenses and the length is less than the selected province
		// we know that a province is deleted and therefore the selected tab should just be removed.
		setSelectedTab(
			Math.max(0, Math.min(selectedTab, (licensing?.provincesLicensing?.length ?? 1) - 1))
		);
	}, [licensing?.provincesLicensing?.length]);

	// TODO: Page Skeleton
	if (!licensing) return <></>;

	const addProvince = (province: AddressFieldLookup) => {
		setContextEl(null);
		mutation
			.updateLicenses({
				...licensing,
				provincesLicensing: [
					{
						provinceAddressFieldLookupId: province.id,
						provinceLicenses: [],
					},
					...licensing.provincesLicensing,
				],
			})
			.then((success) => {
				if (!success) return;
				toast.push('Successfully updated licensing data.', {
					variant: 'success',
				});
			});
	};

	return (
		<>
			<PageTitle title="Canada Licensing" />
			<PageContent>
				<Stack
					spacing={2}
					// className={styles.ManageLicensing}
					margin={{ xs: '-12px', md: 'unset' }}>
					<CanLicenseInformationRead
						userId={userId}
						displayName={displayName}
						licensing={licensing}
					/>
					{!loading && licensing && !loadingProvinces && (
						<Card>
							<CardContent>
								<Stack
									direction="row"
									justifyContent="space-between"
									alignItems="center"
									sx={{ mt: -1.5, mb: 2 }}>
									{licensing?.provincesLicensing && (
										<Tabs
											variant="scrollable"
											allowScrollButtonsMobile
											value={Math.max(
												0,
												Math.min(
													selectedTab,
													licensing.provincesLicensing.length - 1
												)
											)}
											onChange={(_e, v) => setSelectedTab(v)}>
											{licensing.provincesLicensing?.map((pl) => (
												<Tab
													key={pl.provinceAddressFieldLookupId}
													label={
														provinces?.find(
															(p) =>
																p.id ===
																pl.provinceAddressFieldLookupId
														)?.value ?? ''
													}
												/>
											))}
										</Tabs>
									)}
									{hasAccessAlterLicensing && (
										<IconButton
											disabled={mutation.loading}
											onClick={(ev) => setContextEl(ev.currentTarget)}>
											<AddCircleOutlined color="primary" />
										</IconButton>
									)}
									<Menu
										open={Boolean(contextEl)}
										anchorEl={contextEl}
										onClose={() => setContextEl(null)}>
										{provinces
											.filter(
												(p) =>
													p.displayName !== undefined &&
													!provincesToRemove.includes(p.displayName) &&
													!licensing.provincesLicensing.find(
														(l) =>
															l?.provinceAddressFieldLookupId === p.id
													)
											)
											.map((province) => (
												<MenuItem
													onClick={() => addProvince(province)}
													key={province.id}>
													<ListItemText>
														{province.displayName}
													</ListItemText>
												</MenuItem>
											))}
									</Menu>
								</Stack>
								{licensing.provincesLicensing?.length === 0 ? (
									<Box
										width="100%"
										bgcolor="#f2f2f2"
										padding="35px"
										borderRadius="0.5rem"
										textAlign="center">
										<span>No provinces added</span>
									</Box>
								) : (
									licensing.provincesLicensing?.map((province, i) => {
										const provinceLookup = provinces?.find(
											(p) => p.id === province.provinceAddressFieldLookupId
										);
										if (!provinceLookup) return <></>;
										return (
											<div key={i} hidden={i !== selectedTab}>
												<ProvinceLicense
													userId={userId}
													province={provinceLookup}
													licensing={licensing}
												/>
											</div>
										);
									})
								)}
							</CardContent>
						</Card>
					)}
				</Stack>
			</PageContent>
		</>
	);
};
