import { List, ListItem, ListItemIcon, ListItemText, Stack, Typography } from '@mui/material';
import { UserEmblemAvatar } from 'components/ui/emblem/emblem-avatar';
import { Search } from 'components/ui/search';
import React from 'react';

export type RoleUser = {
	id: string | null | undefined;
	name: string | null | undefined;
	avatar: string | undefined | null;
};

export type UserListProps = {
	users: RoleUser[];
	disabled?: boolean;
};

export const UserList = (props: UserListProps) => {
	const [searchText, setSearchText] = React.useState<string>('');
	return (
		<Stack spacing={1.5} height="100%" overflow="hidden">
			<Typography variant="h3">Associated Users ({props.users.length})</Typography>
			<Search onChange={(text: string) => setSearchText(text)} disabled={props.disabled} />
			<List disablePadding sx={{ overflow: 'auto', flex: '1 1 auto' }}>
				{props.users
					.filter(
						(u) =>
							searchText === '' ||
							`${u.name}`.toLowerCase().includes(searchText.toLowerCase())
					)
					.map((u, i) => (
						<ListItem key={i} disableGutters>
							<ListItemIcon sx={{ minWidth: 'unset', paddingRight: '1rem' }}>
								{u.id && <UserEmblemAvatar id={u.id} />}
							</ListItemIcon>
							<ListItemText primary={`${u.name}`} />
						</ListItem>
					))}
			</List>
		</Stack>
	);
};
