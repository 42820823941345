import { LoadingButton } from '@mui/lab';
import { ConfirmModalContent, ModalActions, useModal } from 'components/ui/modal';
import { ConnectionState } from 'middleware-types';
import { Permission } from 'utils/permissions';
import { useAssociateUser } from 'utils/useAssociateUser';
import { ConnectionButton } from '../../shared/ConnectionButton';
import { ConnectionStateExtended } from '../../shared/types';
import {
	OrgConnectionInfo,
	useAcceptOrgConnection,
	useCancelOrgConnectionRequest,
	useIgnoreOrgConnection,
	useOrgConnectionRequest,
	useRemoveOrgConnection,
} from './hooks';
import { ErrorOutlined } from '@mui/icons-material';
import { EmptyStateAvatar } from 'components/ui/empty-state-avatar';

interface Props {
	organization: OrgConnectionInfo;
}

export const OrgConnectionButton = ({ organization }: Props) => {
	switch (organization.connectionState) {
		case ConnectionState.Connected:
			return <RemoveConnectionButton organization={organization} />;
		case ConnectionState.Pending:
			return <CancelRequestButton organization={organization} />;
		case ConnectionStateExtended.IncomingRequest:
			return <AcceptDeclineButtons organization={organization} />;
		case ConnectionState.Ignored:
		case ConnectionStateExtended.NotConnected:
			return <RequestButton organization={organization} />;
	}
};

const RequestButton = ({ organization }: Props) => {
	const { requestConnection, loading } = useOrgConnectionRequest();
	const { hasPermission } = useAssociateUser(organization.orgId);

	const showButton = hasPermission(Permission.Org_Social_Network_U);

	if (!showButton) return null;
	return (
		<ConnectionButton
			connectionState={ConnectionStateExtended.NotConnected}
			onClick={() => requestConnection(organization.orgId, organization.entityId)}
			loading={loading}
			color="darkSecondary"
		/>
	);
};

const CancelRequestButton = ({ organization }: Props) => {
	const { cancelConnectionRequest, loading } = useCancelOrgConnectionRequest();
	const { hasPermission } = useAssociateUser(organization.orgId);

	const showButton = hasPermission(Permission.Org_Social_Network_U);

	if (!showButton) return null;
	return (
		<ConnectionButton
			connectionState={ConnectionState.Pending}
			onClick={() => cancelConnectionRequest(organization.orgId, organization.connectionId!)}
			loading={loading}
			color="darkSecondary"
		/>
	);
};

const AcceptDeclineButtons = ({ organization }: Props) => {
	const { acceptConnection, loading: acceptLoading } = useAcceptOrgConnection();
	const { ignoreConnection, loading: ignoreLoading } = useIgnoreOrgConnection();
	const { hasPermission } = useAssociateUser(organization.orgId);
	const loading = acceptLoading || ignoreLoading;

	const showButtons = hasPermission(Permission.Org_Social_Network_U);

	if (!showButtons) return null;
	return (
		<ConnectionButton
			connectionState={ConnectionStateExtended.IncomingRequest}
			onClick={() => acceptConnection(organization.orgId, organization.connectionId!)}
			onClick2={() => ignoreConnection(organization.orgId, organization.connectionId!)}
			loading={loading}
			color="darkSecondary"
		/>
	);
};

const RemoveConnectionButton = ({ organization }: Props) => {
	const { showModal } = useModal();
	const { hasPermission } = useAssociateUser(organization.orgId);

	const showButton = hasPermission(Permission.Org_Social_Network_D);

	if (!showButton) return null;
	return (
		<ConnectionButton
			connectionState={ConnectionState.Connected}
			onClick={() =>
				showModal({
					title: 'Remove Connection',
					content: <DisconnectModal organization={organization} />,
				})
			}
			color="darkSecondary"
		/>
	);
};

const DisconnectModal = ({ organization }: Props) => {
	const { closeModal } = useModal();
	const { removeConnection, loading } = useRemoveOrgConnection();
	const onClick = () => {
		removeConnection(organization.orgId, organization.connectionId!).then((success) => {
			if (success) closeModal();
		});
	};

	return (
		<>
			<ConfirmModalContent
				visual={
					<EmptyStateAvatar
						avatarProps={{ bgcolor: 'error.50' }}
						iconProps={{ color: 'error.500' }}
						icon={<ErrorOutlined />}
					/>
				}
				subheadline={`Disconnect with ${organization.entityName}?`}
				informativeContent={`Do you really want to remove your organization's connection with ${organization.entityName}? This process cannot be undone.`}
			/>
			<ModalActions justifyContent="flex-end">
				<LoadingButton
					size="large"
					variant="outlined"
					loading={loading}
					onClick={closeModal}>
					Cancel
				</LoadingButton>
				<LoadingButton
					size="large"
					variant="contained"
					color="error"
					loading={loading}
					onClick={onClick}>
					Disconnect
				</LoadingButton>
			</ModalActions>
		</>
	);
};
