import { Box, Button } from '@mui/material';
import { EmblemEntityType } from 'middleware-types';
import { useQueryConversations } from './hooks';

interface OrgMessageButtonProps {
	entityId: string;
	entityType: EmblemEntityType;
	orgId: string;
	handle?: string;
}

// Todo - If using OrgMessageButton outside associates table, add a check to ensure the org can message the entity
// Refer to UserMessageButton for an example

export const OrgMessageButton = ({
	entityId,
	entityType,
	orgId,
	handle,
}: OrgMessageButtonProps) => {
	const { conversations } = useQueryConversations(entityId, orgId);

	return (
		<Box>
			<Button
				variant="outlined"
				href={
					conversations.length === 0
						? `/orgs/${handle}/conversations?entityType=${entityType}&entityId=${entityId}`
						: `/orgs/${handle}/conversations?conversationId=${conversations[0].conversationId}`
				}>
				Organization Message
			</Button>
		</Box>
	);
};
