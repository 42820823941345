import { Container, Stack } from '@mui/material';
import { UserProfileOrgConnectionsBar } from 'components/pages/network/org/OrgConnections/orgConnectionsBarWrappers';
import { ProfileAdministration } from 'components/pages/user/profile/admin';
import { ProfileHeader } from 'components/pages/user/profile/header/header';
import { LicensingInformation } from 'components/pages/user/profile/licensing/licensing-info';
import { PageContent, PageTitle } from 'components/ui/page';

export const UserProfilePage = (props: { userId: string }) => {
	return (
		<>
			<PageTitle title="Profile" />
			<PageContent mobileNoPadding>
				<Container maxWidth="md" disableGutters>
					<Stack spacing={{ xs: 2, md: 3 }}>
						<ProfileHeader userId={props.userId} />
						<UserProfileOrgConnectionsBar userId={props.userId} />
						<LicensingInformation userId={props.userId} />
						<ProfileAdministration />
					</Stack>
				</Container>
			</PageContent>
		</>
	);
};
