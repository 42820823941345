import { useState } from 'react';
import {
	DeleteOutlined,
	AssignmentOutlined,
	MoreHorizOutlined,
	ErrorOutlined,
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
	Grid,
	IconButton,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	Skeleton,
} from '@mui/material';
import { handleNoResponse, responseHasErrors } from 'utils/errors';
import {
	ConfirmModalContent,
	ModalActionButton,
	ModalActions,
	ModalContent,
	ModalLoadingButton,
	useModal,
} from 'components/ui/modal';
import { useToast } from 'components/ui/toast';
import {
	useCanAdjusterLicensingManualMutation,
	useDeleteCanAdjusterLicensesMutation,
} from 'components/pages/user/profile/licensing/can/hooks';
import { Formik } from 'formik';
import { Alert } from 'components/ui/alert';
import { TextField } from 'components/ui/fields';
import { useValidation } from 'utils/useValidation';
import { useNavigate } from 'react-router-dom';
import { UserProfileAspectType, usePrivacy } from 'utils/usePrivacy';
import { LicensingProvenance } from '../hooks';
import { EmptyStateAvatar } from 'components/ui/empty-state-avatar';

/**
 * AddManual - a small modal to add us as a license.
 *
 * @param {{userId:string}} props
 * @return {*}
 */
const AddManual = (props: { userId: string; onSubmit: () => void }) => {
	const validation = useValidation('CanAdjusterLicensing');
	const mutation = useCanAdjusterLicensingManualMutation(props.userId);
	const toast = useToast();
	const { closeModal } = useModal();

	type FormValues = {
		ciprNumber: string;
	};

	const onSubmit = async (values: FormValues) => {
		await mutation
			.updateLicenses({
				...values,
				ciprNumber: values.ciprNumber ?? 0,
				provincesLicensing: [],
			})
			.then((success) => {
				if (!success) return;
				toast.push('Successfully added licensing data.', {
					variant: 'success',
				});
				closeModal();
				props.onSubmit();
			});
	};

	return (
		<Formik<FormValues>
			initialValues={{
				ciprNumber: '',
			}}
			validationSchema={validation.schema}
			onSubmit={onSubmit}>
			{(fprops) => (
				<>
					<ModalContent>
						{mutation.error && <Alert error={mutation.error} />}
						<Grid container spacing={1} columnSpacing={2}>
							<Grid xs={12} item>
								{validation.loading ? (
									<Skeleton variant="rectangular" height={30} width={'100%'} />
								) : (
									<TextField
										label="CIPR Number"
										required
										name="ciprNumber"
										fullWidth
									/>
								)}
							</Grid>
						</Grid>
					</ModalContent>
					<ModalActions>
						<ModalActionButton variant="outlined" disabled={fprops.isSubmitting}>
							Cancel
						</ModalActionButton>
						<LoadingButton
							color="primary"
							variant="contained"
							loading={fprops.isSubmitting}
							disabled={validation.loading || !fprops.isValid || !fprops.dirty}
							onClick={() => fprops.submitForm()}>
							Add Licenses
						</LoadingButton>
					</ModalActions>
				</>
			)}
		</Formik>
	);
};

/**
 * useCanLicensing - This hook provides the context menu options and the add license flow for
 * Canada licenses.
 *
 * @param {string} userId
 * @return {*}
 */
export const useCanLicensing = (userId: string) => {
	const { showModal } = useModal();
	const navigate = useNavigate();

	const Add = () => {
		showModal({
			title: 'Add Canada Licensing Information',
			content: (
				<AddManual
					userId={userId}
					onSubmit={() => {
						navigate(`license/can`);
					}}
				/>
			),
		});
	};

	return { Add, ContextMenu: CanContextOptions };
};

/**
 * CanContextOptions - Context menu options for a USA license.
 *
 * @param {{
 *     userId: string;
 * }}
 * @return {*}
 */
export const CanContextOptions = (props: { userId: string; provenance: LicensingProvenance }) => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const { showModal } = useModal();
	const navigate = useNavigate();
	const { hasAccess } = usePrivacy(props.userId);
	const toast = useToast();
	const { deleteLicenses } = useDeleteCanAdjusterLicensesMutation(props.userId);

	const deleteLicense = () => {
		setAnchorEl(null);
		showModal({
			title: 'Are you sure?',
			content: (
				<ConfirmModalContent
					visual={
						<EmptyStateAvatar
							avatarProps={{ bgcolor: 'error.50' }}
							iconProps={{ color: 'error.500' }}
							icon={<ErrorOutlined />}
						/>
					}
					subheadline="Delete Canada licensing?"
					informativeContent="Do you really want to delete the licensing data for this country? This process cannot be undone."
				/>
			),
			actions: (
				<ModalActions>
					<ModalActionButton size="large" variant="outlined">
						Cancel
					</ModalActionButton>
					<ModalLoadingButton
						size="large"
						variant="contained"
						color="error"
						onClick={async () =>
							await deleteLicenses()
								.then((res) => {
									if (responseHasErrors(res.errors, { toast })) {
										return false;
									}
									toast.push('Successfully deleted licensing data.', {
										variant: 'success',
									});
									return true;
								})
								.catch(() => {
									handleNoResponse({ toast });
									return false;
								})
						}>
						Delete
					</ModalLoadingButton>
				</ModalActions>
			),
		});
	};

	const editLicense = () => {
		setAnchorEl(null);
		navigate(`license/can`);
	};

	// If you don't have access to any licensing features, don't show the context menu at all.
	if (
		!hasAccess(UserProfileAspectType.ViewLicensingDetails, UserProfileAspectType.AlterLicensing)
	)
		return <></>;

	return (
		<>
			<IconButton onClick={(ev) => setAnchorEl(ev.currentTarget)}>
				<MoreHorizOutlined />
			</IconButton>
			<Menu open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
				{hasAccess(
					UserProfileAspectType.ViewLicensingDetails,
					UserProfileAspectType.AlterLicensing
				) && (
					<MenuItem onClick={editLicense}>
						<ListItemIcon>
							<AssignmentOutlined />
						</ListItemIcon>
						<ListItemText>License Details</ListItemText>
					</MenuItem>
				)}
				{hasAccess(UserProfileAspectType.AlterLicensing) && (
					<MenuItem onClick={deleteLicense}>
						<ListItemIcon>
							<DeleteOutlined />
						</ListItemIcon>
						<ListItemText>Delete Country</ListItemText>
					</MenuItem>
				)}
			</Menu>
		</>
	);
};
