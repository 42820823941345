import {
	AccountCircleOutlined,
	Cancel,
	Check,
	DoNotDisturb,
	ForumOutlined,
	GppMaybe,
	QuestionMark,
	SendOutlined,
	SvgIconComponent,
} from '@mui/icons-material';
import { Box, Divider, IconButton, Skeleton, Stack, Tooltip, Typography } from '@mui/material';
import { ConnectionState, Emblem } from 'middleware-types';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { useSession } from 'utils/session';
import { useCanMessage } from 'utils/useCanMessage';
import { isDemo } from 'utils/utils';
import { EmblemAvatar } from '../emblem-avatar';

export interface DropdownItem {
	icon: ReactNode;
	label: string;
	value: string | undefined;
}

interface EmblemDropdownContentProps {
	emblem: Emblem;
	subtext?: string;
	items: DropdownItem[];
	loading: boolean;
	hideActions?: boolean;
	profileUrl?: string;
	ProfileIcon?: SvgIconComponent;
	checkSiteUserProfilePermissions?: () => boolean;
	connectionIcons?: {
		connected?: SvgIconComponent;
		notConnected?: SvgIconComponent;
		requestSent?: SvgIconComponent;
		incomingRequest?: SvgIconComponent;
	};
}

export const EmblemDropdownContent = ({
	emblem,
	subtext,
	items,
	loading,
	hideActions = false,
	profileUrl = '',
	ProfileIcon = AccountCircleOutlined,
	checkSiteUserProfilePermissions = () => false,
	connectionIcons,
}: EmblemDropdownContentProps) => {
	const { user } = useSession();
	const isSelf = emblem.id === user.userId;
	const isSiteUser = Boolean(user.siteUserId);
	const canViewProfile = !isSiteUser || checkSiteUserProfilePermissions();
	const entityTypeString = emblem.extraData?.siteUserId ? 'Site User' : emblem.entityType;

	const connectState = emblem.connection?.state;
	let connectMessage = `You are not connected to ${emblem.displayName}.`;
	let ConnectIcon = connectionIcons?.notConnected ?? DoNotDisturb;
	if (connectState === ConnectionState.Connected) {
		connectMessage = `You are connected to ${emblem.displayName}.`;
		ConnectIcon = connectionIcons?.connected ?? Check;
	} else if (connectState === ConnectionState.Pending) {
		if (emblem.connection!.targetUserId === user.userId) {
			connectMessage = `${emblem.displayName} sent you a request.`;
			ConnectIcon = connectionIcons?.incomingRequest ?? QuestionMark;
		} else {
			connectMessage = `You sent a request to ${emblem.displayName}`;
			ConnectIcon = connectionIcons?.requestSent ?? SendOutlined;
		}
	}

	const messageButtonEnabled = useCanMessage(emblem.handle);

	return (
		<Stack overflow="hidden" onClick={(e) => e.stopPropagation()}>
			<Stack direction="row" alignItems="center" spacing={1.5} p={1.5} bgcolor="neutral.50">
				<EmblemAvatar
					emblem={emblem}
					size={64}
					sx={{ border: '1px solid', borderColor: 'neutral.300' }}
					noDropdown
				/>
				<Box>
					<Typography variant="h5">{emblem.displayName}</Typography>
					{subtext && <Typography variant="subtitle2">{subtext}</Typography>}
					{isDemo() && !hideActions && !emblem.deactivated && !emblem.private && (
						<Stack direction="row" ml={-0.5}>
							{!isSelf && !isSiteUser && (
								<Tooltip title={connectMessage}>
									<IconButton size="small">
										<ConnectIcon />
									</IconButton>
								</Tooltip>
							)}
							{!isSelf && !isSiteUser && (
								<IconButton
									size="small"
									disabled={!messageButtonEnabled}
									href={`/app/conversation-emblem-redirect/${emblem.entityType}/${emblem.id}`}>
									<ForumOutlined />
								</IconButton>
							)}
							<IconButton
								size="small"
								component={Link}
								to={profileUrl}
								disabled={!canViewProfile}>
								<ProfileIcon />
							</IconButton>
						</Stack>
					)}
					{emblem.deactivated && (
						<Typography variant="subtitle2" color="error.700">
							Deactivated {entityTypeString}
						</Typography>
					)}
					{emblem.private && (
						<Typography variant="subtitle2" color="primary.700">
							Private {entityTypeString}
						</Typography>
					)}
				</Box>
			</Stack>
			<Divider />
			{emblem.deactivated && (
				<Stack
					bgcolor="error.50"
					direction="row"
					alignItems="flex-start"
					spacing={1}
					p={1.5}>
					<Cancel fontSize="small" color="error" />
					<Typography variant="subtitle2" color="error.700">
						This {entityTypeString.toLowerCase()} is deactivated. They cannot receive
						updates or take any actions.
					</Typography>
				</Stack>
			)}
			{emblem.private && (
				<Stack
					bgcolor="primary.50"
					direction="row"
					alignItems="flex-start"
					spacing={1}
					p={1.5}>
					<GppMaybe fontSize="small" color="primary" />
					<Typography variant="subtitle2" color="neutral.700">
						This {entityTypeString.toLowerCase()} is private. Access to its profile may
						be restricted, and it cannot initiate new connections or conversations.
					</Typography>
				</Stack>
			)}
			<Stack p={1.5} spacing={2} flex={1} overflow={loading ? 'hidden' : 'auto'}>
				{loading ? (
					<Stack direction="row" alignItems="center" spacing={1}>
						<Skeleton variant="rounded" height={18} width={18} />
						<Skeleton variant="text" sx={{ flex: 1 }} />
					</Stack>
				) : (
					items.map((item, index) => {
						if (!item.value) return null;
						return (
							<Stack key={index} direction="row" alignItems="flex-start" spacing={1}>
								{item.icon}
								<Box>
									<Typography variant="h6">{item.label}</Typography>
									<Typography variant="body2">{item.value}</Typography>
								</Box>
							</Stack>
						);
					})
				)}
			</Stack>
		</Stack>
	);
};
