import { ForumOutlined } from '@mui/icons-material';
import { Box, Button, Tooltip } from '@mui/material';
import { EmblemEntityType } from 'middleware-types';
import { useCanMessage } from 'utils/useCanMessage';

interface UserMessageButtonProps {
	entityId: string;
	entityType: EmblemEntityType;
	handle?: string;
}

export const UserMessageButton = ({ entityId, entityType, handle }: UserMessageButtonProps) => {
	const canMessage = useCanMessage(handle);
	const tooltipText = !canMessage ? 'You must be connected to send a message' : '';

	// If the user is an organization and can't message, don't show the message button
	if (!canMessage && entityType === EmblemEntityType.Organization) return <></>;
	return (
		<Tooltip title={tooltipText} placement="top">
			<Box>
				<Button
					disabled={!canMessage}
					startIcon={
						<ForumOutlined sx={{ color: canMessage ? 'neutral.500' : 'neutral.300' }} />
					}
					variant="outlined"
					href={`/app/conversation-emblem-redirect/${entityType}/${entityId}`}>
					Message
				</Button>
			</Box>
		</Tooltip>
	);
};
