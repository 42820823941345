import {
	AssignmentOutlined,
	DeleteOutlined,
	EditOutlined,
	ErrorOutlined,
	GetAppOutlined,
	MoreHorizOutlined,
	SearchOutlined,
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
	Button,
	Chip,
	Divider,
	Grid,
	IconButton,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	Radio,
	Skeleton,
	Stack,
	Typography,
} from '@mui/material';
import {
	useDeleteUsaAdjusterLicensesMutation,
	useExistingLicenseQuery,
	useLookupNIPRQuery,
	useLookupNIPRQueryValidation,
	useUsaAdjusterLicensingManualMutation,
	useUsaAdjusterLicensingNIPRMutation,
} from 'components/pages/user/profile/licensing/usa/hooks';
import { Alert } from 'components/ui/alert';
import { EmptyStateAvatar } from 'components/ui/empty-state-avatar';
import { DatePickerField, NumberField, TextField } from 'components/ui/fields';
import {
	ConfirmModalContent,
	ModalActionButton,
	ModalActions,
	ModalContent,
	ModalLoadingButton,
	useModal,
} from 'components/ui/modal';
import { useToast } from 'components/ui/toast';
import { format } from 'date-fns';
import { Formik } from 'formik';
import { QueryUsaNiprAdjusterLookupArgs } from 'middleware-types';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { handleNoResponse, responseHasErrors } from 'utils/errors';
import { UserProfileAspectType, usePrivacy } from 'utils/usePrivacy';
import { useValidation } from 'utils/useValidation';
import { zoneDateOnly } from 'utils/utils';
import * as Yup from 'yup';
import { LicensingProvenance } from '../hooks';

/**
 * useFindNPN - Hook to expose a series of modals that allow the lookup of NIPR information.
 *
 * @param {string} userId
 * @return {*}
 */
const useFindNPN = (userId: string) => {
	const { showModal } = useModal();

	const searchNPN = (values: QueryUsaNiprAdjusterLookupArgs) => {
		showModal({
			title: 'Import your Licensing Information from NIPR',
			content: (
				<DisplayResults
					userId={userId}
					query={values}
					onBack={() => {
						showModal({
							title: 'Locate your NIPR Profile',
							content: (
								<FindNPN
									userId={userId}
									initialValues={values}
									onSubmit={searchNPN}
								/>
							),
						});
					}}
				/>
			),
		});
	};

	const showFindNPN = () => {
		showModal({
			title: 'Locate your NIPR Profile',
			content: <FindNPN userId={userId} onSubmit={searchNPN} />,
		});
	};

	return { showFindNPN };
};

/**
 * AddManual - a small modal to add us as a license.
 *
 * @param {{userId:string}} props
 * @return {*}
 */
const AddManual = (props: { userId: string; onSubmit: () => void }) => {
	const validation = useValidation('UsaAdjusterLicensing');
	const mutation = useUsaAdjusterLicensingManualMutation(props.userId);
	const toast = useToast();
	const { closeModal } = useModal();

	const validationSchema = Yup.object().shape({
		birthDate: Yup.date().nullable().typeError('Invalid date'),
	});

	type FormValues = {
		firstName: string;
		middleName: string;
		lastName: string;
		suffix: string;
		birthDate: Date | null;
		npn: number | undefined;
	};

	const onSubmit = async (values: FormValues) => {
		await mutation
			.updateLicenses({
				...values,
				npn: values.npn ?? 0,
				birthDate: values?.birthDate
					? format(values?.birthDate, 'yyyy-MM-dd')
					: '0001-01-01',
				statesLicensing: [],
			})
			.then((success) => {
				if (!success) return;
				toast.push('Successfully added licensing data.', {
					variant: 'success',
				});
				closeModal();
				props.onSubmit();
			});
	};

	return (
		<Formik<FormValues>
			initialValues={{
				firstName: '',
				middleName: '',
				lastName: '',
				suffix: '',
				birthDate: null,
				npn: undefined,
			}}
			validationSchema={validation.schema && validationSchema}
			onSubmit={onSubmit}>
			{(fprops) => (
				<>
					<ModalContent>
						{mutation.error && <Alert error={mutation.error} />}
						<Grid container spacing={1} columnSpacing={2}>
							<Grid xs={12} md={3} item>
								{validation.loading ? (
									<Skeleton variant="rectangular" height={30} width={'100%'} />
								) : (
									<TextField
										label="First Name"
										required
										name="firstName"
										fullWidth
									/>
								)}
							</Grid>
							<Grid xs={12} md={3} item>
								{validation.loading ? (
									<Skeleton variant="rectangular" height={30} width={'100%'} />
								) : (
									<TextField label="Middle Name" name="middleName" fullWidth />
								)}
							</Grid>
							<Grid xs={12} md={3} item>
								{validation.loading ? (
									<Skeleton variant="rectangular" height={30} width={'100%'} />
								) : (
									<TextField
										label="Last Name"
										required
										name="lastName"
										fullWidth
									/>
								)}
							</Grid>
							<Grid xs={12} md={3} item>
								{validation.loading ? (
									<Skeleton variant="rectangular" height={30} width={'100%'} />
								) : (
									<TextField label="Suffix" name="suffix" fullWidth />
								)}
							</Grid>
							<Grid xs={12} md={12} item>
								{validation.loading ? (
									<Skeleton variant="rectangular" height={30} width={'100%'} />
								) : (
									<DatePickerField
										label="Date of Birth"
										required
										name="birthDate"
									/>
								)}
							</Grid>
							<Grid xs={12} md={12} item>
								{validation.loading ? (
									<Skeleton variant="rectangular" height={30} width={'100%'} />
								) : (
									<NumberField
										required={true}
										name="npn"
										label="National Producer Number (NPN)"
										positiveNumber={true}
										integerOnly={true}
										maxLength={9}
									/>
								)}
							</Grid>
						</Grid>
					</ModalContent>
					<ModalActions>
						<ModalActionButton variant="outlined" disabled={fprops.isSubmitting}>
							Cancel
						</ModalActionButton>
						<LoadingButton
							color="primary"
							variant="contained"
							loading={fprops.isSubmitting}
							disabled={validation.loading || !fprops.isValid || !fprops.dirty}
							onClick={() => fprops.submitForm()}>
							Add Licenses
						</LoadingButton>
					</ModalActions>
				</>
			)}
		</Formik>
	);
};

/**
 * useUsaLicensing - This hook provides the context menu options and the add license flow for
 * United States licenses.
 *
 * @param {string} userId
 * @return {*}
 */
export const useUsaLicensing = (userId: string) => {
	const { showModal } = useModal();
	const { showFindNPN } = useFindNPN(userId);
	const navigate = useNavigate();
	const { hasAccess } = usePrivacy(userId);

	// Disable a comming soon feature
	// Remove this line when the feature is ready to be enabled
	const disabled = true;

	const addManual = () => {
		showModal({
			title: 'Add Manual US Licensing Information',
			content: (
				<AddManual
					userId={userId}
					onSubmit={() => {
						navigate(`license/usa`);
					}}
				/>
			),
			maxWidth: 'md',
		});
	};

	const Add = () =>
		showModal({
			title: 'Add US Licensing Information',
			content: (
				<ConfirmModalContent
					visual="/img/temp-flag.svg"
					informativeContent="How would you like to input your United States licensing information?"
				/>
			),
			actions: (
				<ModalActions>
					<Button
						size="large"
						variant="outlined"
						disabled={!hasAccess(UserProfileAspectType.ImportLicensing) || disabled}
						onClick={() => showFindNPN()}>
						<GetAppOutlined />
						&nbsp;Import from NIPR
						{disabled && (
							<Chip
								sx={{
									marginLeft: 1,
									color: 'neutral.300',
									borderColor: 'neutral.200',
								}}
								label="COMING SOON"
								size="small"
							/>
						)}
					</Button>
					<Button size="large" variant="outlined" onClick={() => addManual()}>
						<EditOutlined />
						&nbsp;Enter Manually
					</Button>
				</ModalActions>
			),
		});

	return { Add, ContextMenu: UsaContextOptions };
};

/**
 * FindNPN - Modal contents to lookup NIPR numbers.
 *
 * @param {({
 *     initialValues?: NiprLookupArgs | undefined;
 *     onSubmit: (values : {
 *         npnNumber?: number;
 *         firstName?: string;
 *         lastName?: string;
 *         importAdjuster?: undefined;
 *         birthDate: string;
 *     }) => void;
 * })} props
 * @return {*}
 */
const FindNPN = (props: {
	userId: string;
	initialValues?: QueryUsaNiprAdjusterLookupArgs;
	onSubmit: (values: {
		npnNumber?: number;
		firstName?: string;
		lastName?: string;
		importAdjuster?: undefined;
		birthDate: string;
	}) => void;
}) => {
	const { validationSchema, loading } = useLookupNIPRQueryValidation();
	const existingLicensedUser = useExistingLicenseQuery(
		props.userId,
		props?.initialValues !== undefined
	);

	const initialValues = (props.initialValues
		? {
				...props.initialValues,
				birthDate: zoneDateOnly(props.initialValues.birthDate),
		  }
		: null) ?? {
		npnNumber: existingLicensedUser?.npn,
		firstName: existingLicensedUser?.npn ? '' : existingLicensedUser?.name?.firstName ?? '',
		lastName: existingLicensedUser?.npn ? '' : existingLicensedUser?.name?.lastName ?? '',
		birthDate: existingLicensedUser?.birthDate
			? zoneDateOnly(existingLicensedUser.birthDate)
			: null,
		importAdjuster: undefined,
	};
	if (loading || existingLicensedUser.loading) return <FindNPNSkeleton />;

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			enableReinitialize
			onSubmit={(values) => {
				props.onSubmit({
					...values,
					birthDate: values.birthDate ? format(values.birthDate, 'yyyy-MM-dd') : '',
				});
			}}>
			{(fprops) => (
				<>
					<ModalContent
					// className={styles.ImportNIPRModal}
					>
						<Grid container spacing={1} columnSpacing={2}>
							<Grid item>
								<Typography variant="h3">
									Please provide your Date of Birth and National Producer Number.
									If you do not have your NPN, you must provide your First and
									Last name instead.
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<DatePickerField label="Date of Birth" name="birthDate" required />
							</Grid>
							<Grid item xs={12}>
								<Divider />
							</Grid>
							<Grid item xs={12} md={12}>
								<NumberField
									label="National Producer Number"
									name="npnNumber"
									integerOnly
									positiveNumber
									maxLength={9}
								/>
							</Grid>
							<Grid item xs={12}>
								<Divider>OR</Divider>
							</Grid>
							<Grid item xs={12} md={6}>
								<TextField label="First Name" type="text" name="firstName" />
							</Grid>
							<Grid item xs={12} md={6}>
								<TextField label="Last Name" type="text" name="lastName" />
							</Grid>
						</Grid>
					</ModalContent>
					<ModalActions>
						<ModalActionButton variant="outlined">Cancel</ModalActionButton>
						<Button
							variant="contained"
							color="primary"
							disabled={
								!fprops.isValid ||
								!fprops.dirty ||
								loading ||
								(fprops.values.npnNumber === undefined &&
									(fprops.values.firstName === '' ||
										fprops.values.lastName === ''))
							}
							startIcon={<SearchOutlined />}
							onClick={fprops.submitForm}>
							Find
						</Button>
					</ModalActions>
				</>
			)}
		</Formik>
	);
};

/**
 * FindNPNSkeleton - Loading Skeleton for the FindNPN screen of the lookup NIPR modal.
 *
 */
const FindNPNSkeleton = () => {
	return (
		<>
			<ModalContent
			// className={styles.ImportNIPRModal}
			>
				<Grid container spacing={1} columnSpacing={2}>
					<Grid item>
						<Typography variant="h3">
							Please provide your Date of Birth and National Producer Number. If you
							do not have your NPN, you must provide your First and Last name instead.
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Skeleton variant="rectangular" height={30} width={'100%'} />
					</Grid>
					<Grid item xs={12}>
						<Divider />
					</Grid>
					<Grid item xs={12} md={12}>
						<Skeleton variant="rectangular" height={30} width={'100%'} />
					</Grid>
					<Grid item xs={12}>
						<Divider>OR</Divider>
					</Grid>
					<Grid item xs={12} md={6}>
						<Skeleton variant="rectangular" height={30} width={'100%'} />
					</Grid>
					<Grid item xs={12} md={6}>
						<Skeleton variant="rectangular" height={30} width={'100%'} />
					</Grid>
				</Grid>
			</ModalContent>
			<ModalActions>
				<ModalActionButton variant="outlined">Cancel</ModalActionButton>
				<Button variant="contained" color="primary" disabled startIcon={<SearchOutlined />}>
					Find
				</Button>
			</ModalActions>
		</>
	);
};

/**
 * DisplayResults - Results of an NIPR lookup query displayed in the lookup modal.
 *
 * @param {{
 *     userId: string;
 *     query: NiprLookupArgs;
 *     onBack: () => void;
 * }} props
 * @return {*}
 */
const DisplayResults = (props: {
	userId: string;
	query: QueryUsaNiprAdjusterLookupArgs;
	onBack: () => void;
}) => {
	const toast = useToast();
	const { closeModal } = useModal();
	const { updateLicenses, loading: mutLoading } = useUsaAdjusterLicensingNIPRMutation(
		props.userId
	);
	const { results, loading, error } = useLookupNIPRQuery(props.query);
	const [selectedNpn, setSelectedNpn] = useState<number | undefined>(
		results.length > 0 ? results[0].npn : undefined
	);

	const onSubmit = async () => {
		if (!selectedNpn) return;
		await updateLicenses(selectedNpn)
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				toast.push('Successfully imported NIPR data', { variant: 'success' });
				closeModal();
			})
			.catch(() => handleNoResponse({ toast }));
	};

	useEffect(() => {
		setSelectedNpn(results.length > 0 ? results[0].npn : undefined);
	}, [results]);

	if (loading) return <DisplayResultsSkeleton />;
	return (
		<>
			<ModalContent
			// className={styles.DisplayResults}
			>
				{error && <Alert error={error} />}
				{results && results.length > 0 ? (
					<List>
						{results.map((item) => (
							<ListItem key={item.npn} button>
								<ListItemIcon>
									<Radio
										value={item.npn}
										color="primary"
										checked={item.npn === selectedNpn}
										onClick={() => setSelectedNpn(item.npn)}
									/>
								</ListItemIcon>
								<ListItemText
									primary={item.npn}
									secondary={
										<>
											<strong>{item.name}</strong>
											<br />
											{`Date of Birth: ${format(
												zoneDateOnly(item.birthDate),
												'MM/dd/yyyy'
											)}`}
											<br />
											{item.stateOfResidence &&
												`State of Residence: ${item.stateOfResidence}`}
										</>
									}
								/>
							</ListItem>
						))}
					</List>
				) : (
					<div
					// className={styles.NoResults}
					>
						<strong>There are no entities that match your search requirements.</strong>
					</div>
				)}
			</ModalContent>
			<ModalActions>
				<Button variant="outlined" onClick={props.onBack}>
					Back
				</Button>
				{results.length > 0 && (
					<LoadingButton
						variant="contained"
						color="primary"
						loading={mutLoading}
						startIcon={<GetAppOutlined />}
						onClick={onSubmit}
						disabled={selectedNpn === undefined}>
						Import
					</LoadingButton>
				)}
			</ModalActions>
		</>
	);
};

/**
 * DisplayResultsSkeleton - Loading Skeleton for the DisplayResults screen of the NIPR lookup modals.
 *
 */
const DisplayResultsSkeleton = () => (
	<ModalContent>
		<Stack spacing={1}>
			<Skeleton variant="rectangular" height={50} width={'100%'} />
			<Skeleton variant="rectangular" height={50} width={'100%'} />
			<Skeleton variant="rectangular" height={50} width={'100%'} />
		</Stack>
	</ModalContent>
);

/**
 * UsaContextOptions - Context menu options for a USA license.
 *
 * @param {{
 *     userId: string;
 * }}
 * @return {*}
 */
export const UsaContextOptions = (props: { userId: string; provenance: LicensingProvenance }) => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const { showFindNPN } = useFindNPN(props.userId);
	const { showModal } = useModal();
	const navigate = useNavigate();
	const { hasAccess } = usePrivacy(props.userId);
	const toast = useToast();
	const { deleteLicenses } = useDeleteUsaAdjusterLicensesMutation(props.userId);

	const deleteLicense = () => {
		setAnchorEl(null);
		showModal({
			title: 'Are you sure?',
			content: (
				<ConfirmModalContent
					visual={
						<EmptyStateAvatar
							avatarProps={{ bgcolor: 'error.50' }}
							iconProps={{ color: 'error.500' }}
							icon={<ErrorOutlined />}
						/>
					}
					subheadline="Delete United States licensing?"
					informativeContent="Do you really want to delete the licensing data for this country? This process cannot be undone."
				/>
			),
			actions: (
				<ModalActions>
					<ModalActionButton size="large" variant="outlined">
						Cancel
					</ModalActionButton>
					<ModalLoadingButton
						size="large"
						variant="contained"
						color="error"
						onClick={async () =>
							await deleteLicenses()
								.then((res) => {
									if (responseHasErrors(res.errors, { toast })) {
										return false;
									}
									toast.push('Successfully deleted licensing data.', {
										variant: 'success',
									});
									return true;
								})
								.catch(() => {
									handleNoResponse({ toast });
									return false;
								})
						}>
						Delete
					</ModalLoadingButton>
				</ModalActions>
			),
		});
	};

	const reimportLicense = () => {
		showFindNPN();
		setAnchorEl(null);
	};

	const editLicense = () => {
		setAnchorEl(null);

		if (props.provenance === 'Nipr' && hasAccess(UserProfileAspectType.AlterLicensing)) {
			showModal({
				title: 'Are you sure?',
				content: (
					<ConfirmModalContent
						visual={
							<EmptyStateAvatar
								avatarProps={{ bgcolor: 'error.50' }}
								iconProps={{ color: 'error.500' }}
								icon={<ErrorOutlined />}
							/>
						}
						subheadline="NIPR verification can be lost"
						informativeContent="Your licensing information is currently verified by NIPR. Any changes made to your licensing information manually will make your information unverified."
					/>
				),
				actions: (
					<ModalActions>
						<ModalActionButton size="large" variant="outlined">
							Cancel
						</ModalActionButton>
						<ModalActionButton
							size="large"
							variant="contained"
							color="error"
							href={`license/usa`}>
							Continue
						</ModalActionButton>
					</ModalActions>
				),
			});
			return;
		}

		navigate(`license/usa`);
	};

	// If you don't have access to any licensing features, don't show the context menu at all.
	if (
		!hasAccess(
			UserProfileAspectType.ViewLicensingDetails,
			UserProfileAspectType.AlterLicensing,
			UserProfileAspectType.ImportLicensing
		)
	)
		return <></>;

	// Disable a comming soon feature
	// Remove this line when the feature is ready to be enabled
	const disabled = true;

	return (
		<>
			<IconButton onClick={(ev) => setAnchorEl(ev.currentTarget)}>
				<MoreHorizOutlined />
			</IconButton>
			<Menu open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
				{hasAccess(
					UserProfileAspectType.ViewLicensingDetails,
					UserProfileAspectType.AlterLicensing
				) && (
					<MenuItem onClick={editLicense}>
						<ListItemIcon>
							<AssignmentOutlined />
						</ListItemIcon>
						<ListItemText>License Details</ListItemText>
					</MenuItem>
				)}
				{hasAccess(UserProfileAspectType.ImportLicensing) && (
					<MenuItem onClick={reimportLicense} disabled={disabled}>
						<ListItemIcon>
							<GetAppOutlined />
						</ListItemIcon>
						<ListItemText>
							{props.provenance === 'Nipr'
								? 'Reimport from NIPR'
								: 'Import From NIPR'}
						</ListItemText>
						{disabled && (
							<Chip sx={{ marginLeft: 1 }} label="COMING SOON" size="small" />
						)}
					</MenuItem>
				)}
				{hasAccess(UserProfileAspectType.AlterLicensing) && (
					<MenuItem onClick={deleteLicense}>
						<ListItemIcon>
							<DeleteOutlined />
						</ListItemIcon>
						<ListItemText>Delete Country</ListItemText>
					</MenuItem>
				)}
			</Menu>
		</>
	);
};
