import { AccountCircle, MoreHorizRounded } from '@mui/icons-material';
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Permission } from 'utils/permissions';
import { useSiteUser } from 'utils/useSiteUser';

/**
 * Props for the context menu.
 *
 * @interface OrgContextMenuProps
 */
interface OrgContextMenuProps {
	id: string | undefined | null;
	refetch: () => void;
	status: Status;
	handle: string | undefined | null;
}

// This doesn't need as many keys
// but removing some causes TypeScript issues
/**
 * The org status.
 *
 * @enum {number}
 */
enum Status {
	Invited = 'Invited',
	Active = 'Active',
	Inactive = 'Inactive',
	SocialActive = 'SocialActive',
	SocialInactive = 'SocialInactive',
}

/**
 * Renders the context menu for site user rows.
 * @param props
 * @returns
 */
export const OrgContextMenu = (props: OrgContextMenuProps): React.JSX.Element => {
	const { hasPermission } = useSiteUser();
	const [anchorEl, setAnchorEl] = useState<Element | null>(null);
	const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void =>
		setAnchorEl(event.currentTarget);

	/**
	 * The actions possible for a context menu.
	 *  @type {*} */
	const tableActions = [
		{
			status: ['Active'],
			permissions: [Permission.Site_OrgProfile_R],
			link: `../../orgs/${props.handle}`,
			text: 'View Profile',
			icon: <AccountCircle />,
		},
		{
			status: ['Active'],
			permissions: [Permission.Site_OrgAcct_R],
			link: `../../orgs/${props.handle}/account`,
			text: 'View Account',
			icon: <AccountCircle />,
		},
	];

	let menu = tableActions
		.filter(
			(ta) =>
				hasPermission(ta.permissions ?? []) &&
				props.status &&
				ta.status.includes(props.status)
		)
		.map((ta, k) => {
			if (ta.link) {
				return (
					<MenuItem key={k} component={Link} to={ta.link}>
						<ListItemIcon>{ta.icon}</ListItemIcon>
						<ListItemText>{ta.text}</ListItemText>
					</MenuItem>
				);
			}

			return <></>;
		});

	return (
		<>
			{menu.length > 0 && (
				<IconButton
					aria-label="more"
					aria-controls="long-menu"
					aria-haspopup="true"
					onClick={handleClick}>
					<MoreHorizRounded />
				</IconButton>
			)}
			<Menu
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={(): void => setAnchorEl(null)}>
				{menu}
			</Menu>
		</>
	);
};
