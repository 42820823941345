import { gql, useLazyQuery, useQuery } from '@apollo/client';
import {
	EmblemEntityType,
	Query,
	QueryAccountArgs,
	QueryEmblemArgs,
	QueryGetFileInstanceDownloadUrlArgs,
	QueryOrganizationProfileArgs,
	QueryProfileArgs,
	QuerySiteUserArgs,
} from 'middleware-types';
import { EMBLEM_FIELDS } from './fragments';
import { useEffect, useState } from 'react';

/**
 * hook to get a file from blob storage
 * this is used if the file cannot be loaded from the cdnUrl
 */
const GET_FILE_INSTANCE_INFORMATION = gql`
	query GetFileInstanceInformation($fileInstanceId: String!, $sasUrlOnly: Boolean!) {
		getFileInstanceDownloadUrl(fileInstanceId: $fileInstanceId, sasUrlOnly: $sasUrlOnly) {
			downloadUrl
			expiresUtc
		}
	}
`;

const useFileInstanceInfo = () => {
	const [getFileInstanceInfo, { data, loading, called }] = useLazyQuery<
		Pick<Query, 'getFileInstanceDownloadUrl'>,
		QueryGetFileInstanceDownloadUrlArgs
	>(GET_FILE_INSTANCE_INFORMATION);

	const getFileInfo = (fileInstanceId: string) => {
		getFileInstanceInfo({ variables: { fileInstanceId, sasUrlOnly: true } });
	};

	const fileInfo = data?.getFileInstanceDownloadUrl;
	return { getFileInfo, fileInfo, loading, called };
};

/**
 * hook to get the emblem info
 */
export const GET_EMBLEM = gql`
	${EMBLEM_FIELDS}
	query GetEmblem($entityType: EmblemEntityType!, $entityId: ID!) {
		emblem(entityType: $entityType, entityId: $entityId) {
			...EmblemFields
		}
	}
`;

export const useEmblem = (entityId: string, entityType: EmblemEntityType) => {
	const [useFileInfo, setUseFileInfo] = useState(false);
	const { data, loading: emblemLoading } = useQuery<Pick<Query, 'emblem'>, QueryEmblemArgs>(
		GET_EMBLEM,
		{
			variables: { entityId, entityType },
		}
	);

	const emblem = data?.emblem;

	// if we upload a new file, stop using the url from blob storage
	const fileId = emblem?.avatarFile?.file?.currentInstance?.id;
	useEffect(() => {
		setUseFileInfo(false);
	}, [fileId]);

	const { getFileInfo, fileInfo, loading: fileInfoLoading } = useFileInstanceInfo();
	// if the cdn url does not work, fetch the url from blob storage
	const onError = () => {
		if (fileId === undefined) return;
		getFileInfo(fileId);
		setUseFileInfo(true);
	};

	const src = useFileInfo
		? fileInfo?.downloadUrl
		: emblem?.avatarFile?.file?.currentInstance?.cdnUrl;
	const loading = emblemLoading || fileInfoLoading;

	return { emblem, src, onError, loading };
};

/**
 * hook to get the banner info from an emblem
 */
const GET_EMBLEM_BANNER = gql`
	query GetEmblemBanner($entityType: EmblemEntityType!, $entityId: ID!) {
		emblem(entityType: $entityType, entityId: $entityId) {
			id
			bannerFile {
				fileId
				file {
					id
					currentInstance {
						id
						cdnUrl
						fileName
						fileSize
						virusStatus
						uploadedUtc
						fileId
					}
				}
			}
		}
	}
`;

export const useEmblemBanner = (entityId: string, entityType: EmblemEntityType) => {
	const defaultUrl = '/img/profile-banner.png';
	const [useDefault, setUseDefault] = useState(false);

	const { data } = useQuery<Pick<Query, 'emblem'>, QueryEmblemArgs>(GET_EMBLEM_BANNER, {
		variables: { entityId, entityType },
	});

	const emblem = data?.emblem;
	const fileInstance = emblem?.bannerFile?.file?.currentInstance;

	const { getFileInfo, fileInfo, called } = useFileInstanceInfo();
	// if the cdn url does not work, fetch the url from blob storage
	const onError = () => {
		if (called) {
			// if we are already using the url from blob storage and
			// we still get an error, just use the default image
			setUseDefault(true);
		} else {
			if (fileInstance?.id === undefined) return;
			getFileInfo(fileInstance.id);
		}
	};

	const src = useDefault
		? defaultUrl
		: fileInfo?.downloadUrl ?? fileInstance?.cdnUrl ?? defaultUrl;

	return { src, fileInstance, onError };
};

/**
 * hook to get the information for a user emblem dropdown
 */
const GET_USER_DROPDOWN_INFO = gql`
	query GetUserDropdownInfo($userId: ID!) {
		profile(userId: $userId) {
			id
			about
			adjusterLicenses {
				userId
				countryId
				numLicenses
			}
			companyName
			companyTitle
			displayLocation
			displayTemporaryLocation {
				displayLocation
				endDate
			}
			following
			jobFunctions
			languageIds
			workCodes
		}
	}
`;

export const useUserDropdownInfo = (userId: string) => {
	const { data, loading } = useQuery<Pick<Query, 'profile'>, QueryProfileArgs>(
		GET_USER_DROPDOWN_INFO,
		{ variables: { userId } }
	);

	const info = data?.profile;
	return { info, loading };
};

/**
 * hook to get the information for an organization emblem dropdown
 */
const GET_ORG_DROPDOWN_INFO = gql`
	query GetOrgDropdownInfo($organizationId: ID!) {
		organizationProfile(organizationId: $organizationId) {
			id
			displayLocation
		}
	}
`;

export const useOrgDropdownInfo = (organizationId: string) => {
	const { data, loading } = useQuery<
		Pick<Query, 'organizationProfile'>,
		QueryOrganizationProfileArgs
	>(GET_ORG_DROPDOWN_INFO, {
		variables: { organizationId },
	});

	const info = data?.organizationProfile;
	return { info, loading };
};

/**
 * hook to get the information for a site user
 */
const GET_SITE_USER_DROPDOWN_INFO = gql`
	query GetSiteUserDropdownInfo($id: ID!, $userId: ID!) {
		siteUser(id: $id) {
			id
			affiliation
			roles {
				id
				name
			}
		}
		account(userId: $userId) {
			id
			deactivated
		}
	}
`;

export const useSiteUserDropdownInfo = (userId: string, siteUserId: string) => {
	const { data, loading } = useQuery<
		Pick<Query, 'siteUser' | 'account'>,
		QuerySiteUserArgs & QueryAccountArgs
	>(GET_SITE_USER_DROPDOWN_INFO, {
		variables: { id: siteUserId, userId },
	});

	const info = data;
	return { info, loading };
};
