import { ApolloError, useQuery } from '@apollo/client';
import { AddOutlined, ManageSearchOutlined } from '@mui/icons-material';
import {
	Box,
	Chip,
	FormControlLabel,
	IconButton,
	List,
	ListItem,
	ListItemAvatar,
	ListItemButton,
	ListItemText,
	Paper,
	Skeleton,
	Stack,
	Switch,
	Tooltip,
	Typography,
} from '@mui/material';
import { InvitationForm } from 'components/pages/site/users/invitation';
import EmergencyContact from 'components/pages/user/account/emergency-contact/emergency-contact';
import { LocationInformation } from 'components/pages/user/account/location-info';
import { PersonalInfo } from 'components/pages/user/account/personal/personal-info';
import { SiteUserInformation } from 'components/pages/user/account/siteuser';
import { UserEmblemAvatar } from 'components/ui/emblem/emblem-avatar';
import { EmptyStateAvatar } from 'components/ui/empty-state-avatar';
import { useModal } from 'components/ui/modal';
import { PageContent, PageTitle } from 'components/ui/page';
import { Search } from 'components/ui/search';
import { useToast } from 'components/ui/toast';
import gql from 'graphql-tag';
import { Query, QuerySiteUsersOrInvitationsArgs, SortDirection } from 'middleware-types';
import React, { useState } from 'react';
import { Permission } from 'utils/permissions';
import { useSiteUser } from 'utils/useSiteUser';

enum Status {
	Active = 'Active',
	Invited = 'Invited',
	Inactive = 'Inactive',
}

/**
 *  Site user row props.
 */
export interface SiteUser {
	id: string;
	siteUserId: string;
	firstName: string | undefined;
	lastName: string | undefined;
	status: Status;
	role: string | undefined;
}

/**
 * useSiteUsersQuery() - Hook to pull all siteusers in the system.
 *
 * @param {number} pageSize
 * @param {number} page
 * @param {string} sortedBy
 * @param {('Ascending' | 'Descending')} sortDirection
 * @param {string} searchFor
 * @return {*}
 */
export const useSiteUsersQuery = (
	pageSize: number,
	page: number,
	sortedBy: string,
	sortDirection: SortDirection,
	searchFor: string
) => {
	const toast = useToast();
	const { error, data, loading } = useQuery<
		Pick<Query, 'siteUsersOrInvitations'>,
		QuerySiteUsersOrInvitationsArgs
	>(
		gql`
			query siteUsersOrInvitations(
				$pageSize: Float!
				$offset: Float!
				$sortedBy: String!
				$sortDirection: SortDirection!
				$searchFor: String!
			) {
				siteUsersOrInvitations(
					pageSize: $pageSize
					offset: $offset
					sortedBy: $sortedBy
					sortDirection: $sortDirection
					searchFor: $searchFor
				) {
					items {
						userId
						siteUser {
							id
							roles {
								id
								name
							}
						}
						userAccount {
							id
							name {
								firstName
								lastName
							}
						}
						status
					}
					totalCount
				}
			}
		`,
		{
			// checks cache and fetches (important for updating queries after inviting a user)
			fetchPolicy: 'cache-and-network',
			variables: {
				pageSize: pageSize,
				offset: page * pageSize,
				sortedBy,
				sortDirection,
				searchFor,
			},
			onError: (error: ApolloError) => {
				if (!error) return;
				toast.push(`An error occurred. Please try again later or contact Support.`, {
					variant: 'error',
				});
				console.log(error);
			},
		}
	);

	const totalCount = data?.siteUsersOrInvitations?.totalCount ?? 0;

	const siteUsers: SiteUser[] =
		data?.siteUsersOrInvitations?.items
			?.filter((p) => p.siteUser?.id && p.userId && p.status)
			.map((p) => {
				return {
					// Assertions allowed because the filter already removes
					// the items without siteuser or userid's.
					id: p.userId!,
					siteUserId: p.siteUser!.id,
					firstName: p.userAccount?.name?.firstName ?? undefined,
					lastName: p.userAccount?.name?.lastName ?? undefined,
					status: p.status as Status,
					role: p.siteUser?.roles?.map((r) => r.name)?.join(', '),
				};
			}) ?? [];

	return { siteUsers, totalCount, loading, error };
};

/**
 * SiteUsers contains the entire site users page.
 *
 * @returns
 */
export const SiteUsersPage: React.FC = () => {
	const { hasPermission } = useSiteUser();
	const [searchFor, setSearchFor] = useState('');
	const [selected, setSelectedUser] = useState<SiteUser | undefined>(undefined);
	const [showDeactivated, setShowDeactivated] = useState(false);
	const { showModal } = useModal();

	const openInviteModal = () => {
		showModal({
			title: 'Invite Site User',
			content: <InvitationForm />,
		});
	};

	const handleClick = (user: SiteUser | undefined) => {
		setSelectedUser(user);
	};

	const handleStatusFilterChange = (event) => {
		setShowDeactivated(event.target.checked ? true : false);
	};

	// TODO: Page size set very large (500) temporarily until SiteUser query is edited to change how we load users.
	const { siteUsers, loading } = useSiteUsersQuery(
		500,
		0,
		'InvitedDateTimeUtc',
		SortDirection.Ascending,
		searchFor
	);

	// Filter out inactive users if the switch is off.
	const filteredUsers = siteUsers.filter((u) => {
		if (showDeactivated) return true;
		return u.status !== Status.Inactive;
	});

	const rows: React.JSX.Element[] = filteredUsers.map((row, i) => (
		<SiteUserListPaneItem
			key={i}
			onClick={handleClick}
			selected={selected?.id === row.id}
			user={row}
		/>
	));

	return (
		<>
			<PageTitle title="Site Users" />
			<PageContent>
				<Stack direction="row" spacing={4} height="100%">
					<Stack spacing={2} width={350}>
						<Stack direction="row" justifyContent="space-between" alignItems="center">
							<Typography variant="h2">Site Users</Typography>
							<Stack direction="row">
								<FormControlLabel
									labelPlacement="start"
									control={
										<Switch
											checked={showDeactivated}
											onChange={handleStatusFilterChange}
										/>
									}
									label={<Typography variant="body1">Show Inactive</Typography>}
								/>
								{hasPermission(Permission.Site_Inv_C) && (
									<Tooltip title="Invite Site User">
										<IconButton
											edge="end"
											size="small"
											onClick={openInviteModal}
											color="primary">
											<AddOutlined />
										</IconButton>
									</Tooltip>
								)}
							</Stack>
						</Stack>
						<Search onChange={(str) => setSearchFor(str)} placeholder="Search" />
						<List component={Stack} spacing={2} overflow="auto" sx={{ px: '3px' }}>
							{!loading && rows ? (
								rows.length === 0 ? (
									<Typography variant="body1">
										No results matched the search criteria. Try again.
									</Typography>
								) : (
									rows
								)
							) : (
								<SkeletonList />
							)}
						</List>
					</Stack>
					<Box flex={1} overflow="auto">
						{!(selected && selected.id && selected.siteUserId) ? (
							<Stack
								height="100%"
								component={Paper}
								spacing={1}
								justifyContent="center"
								alignItems="center">
								<EmptyStateAvatar
									icon={<ManageSearchOutlined />}
									avatarProps={{ bgcolor: 'primary.50' }}
									iconProps={{ color: 'primary.500' }}
								/>
								<Typography variant="h5">
									Select a site user on the left to view their account
									information.
								</Typography>
							</Stack>
						) : (
							// Using key here forces the component to drop when the id changes.
							// ! Do not remove.
							<SiteUserAccountPane
								key={selected.id}
								userId={selected.id}
								siteUserId={selected.siteUserId}
							/>
						)}
					</Box>
				</Stack>
			</PageContent>
		</>
	);
};

/**
 * SiteUserAccountPane - This just lists off all the account settings for a
 * provided user Id and site user Id.
 *
 * @param {{ userId: string; siteUserId: string }} { userId, siteUserId }
 */
const SiteUserAccountPane = ({ userId, siteUserId }: { userId: string; siteUserId: string }) => (
	<Stack spacing={2}>
		<PersonalInfo userId={userId} />
		<SiteUserInformation siteUserId={siteUserId} />
		<LocationInformation userId={userId} />
		<EmergencyContact userId={userId} />
	</Stack>
);

/**
 *  Site user row props.
 */
type SiteUserRowProps = {
	selected: boolean;
	user: SiteUser;
	onClick: (user: SiteUser) => void;
};

/**
 * Returns a Site User List Pane Item.
 * @param props
 * @returns
 */
export const SiteUserListPaneItem = (props: SiteUserRowProps) => {
	if (!props.user.id) return <></>;

	const inactive = props.user.status === 'Inactive';

	return (
		<ListItem component={Paper} disablePadding>
			<ListItemButton onClick={() => props.onClick(props.user)} selected={props.selected}>
				<ListItemAvatar>
					<UserEmblemAvatar id={props.user.id} />
				</ListItemAvatar>
				<ListItemText
					primary={`${props.user.firstName} ${props.user.lastName}`}
					secondary={props.user.role}
				/>
				<Box pl={2}>
					<Chip
						size="small"
						label={inactive ? 'Inactive' : 'Active'}
						color={inactive ? 'error' : 'primary'}
					/>
				</Box>
			</ListItemButton>
		</ListItem>
	);
};

/**
 * Returns skeleton list to display while loading results.
 * @returns
 */
const SkeletonList = (): React.JSX.Element => (
	<>
		<Skeleton variant="rectangular" animation="wave" height="4rem" />
		<Skeleton variant="rectangular" animation="wave" height="4rem" />
		<Skeleton variant="rectangular" animation="wave" height="4rem" />
		<Skeleton variant="rectangular" animation="wave" height="4rem" />
	</>
);
