import { LoadingButton } from '@mui/lab';
import { Stack, Typography } from '@mui/material';
import { ContainedDrawer } from 'components/ui/containedDrawer';
import { SavedConversation } from 'middleware-types';
import { useDeleteSavedConversation } from './hooks';

interface DeletePromptDrawerProps {
	open: boolean;
	onClose: () => void;
	conversation: SavedConversation;
}

export const DeletePromptDrawer = ({ open, onClose, conversation }: DeletePromptDrawerProps) => {
	const { deleteConversation: _deleteConversation, loading } = useDeleteSavedConversation();

	const deleteConversation = () => {
		_deleteConversation(conversation.conversationId).then((success) => {
			if (success) onClose();
		});
	};

	return (
		<ContainedDrawer
			open={open}
			anchor="top"
			containerid="evox-container"
			PaperProps={{ sx: { borderRadius: 1 } }}>
			<Stack p={2} spacing={1}>
				<Typography variant="h3">
					Delete &quot;{conversation.conversationName}&quot;?
				</Typography>
				<Typography variant="body2">This action cannot be undone.</Typography>
				<Stack direction="row" spacing={1} justifyContent="flex-end">
					<LoadingButton
						loading={loading}
						variant="outlined"
						color="primary"
						onClick={onClose}>
						Cancel
					</LoadingButton>
					<LoadingButton
						loading={loading}
						variant="contained"
						color="error"
						onClick={deleteConversation}>
						Yes, delete
					</LoadingButton>
				</Stack>
			</Stack>
		</ContainedDrawer>
	);
};
