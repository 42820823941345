import { Card, CardContent, CardHeader, Link, Stack } from '@mui/material';
import LegacySystemList from 'components/pages/site/legacy-system';
import { PageContent, PageTitle } from 'components/ui/page';

/**
 * Currently this is the homepage.
 */
export const SiteUserDashboard: React.FC = () => {
	const env = import.meta.env;

	return (
		<>
			<PageTitle title="Dashboard" />
			<PageContent>
				<Stack spacing={2} direction="row">
					<Card className="w-96">
						<CardHeader title="Site User Dashboard" />
						<CardContent>
							<Stack spacing={1} gap={1}>
								<Link
									href={`https://evolution-global.sentry.io/issues/?environment=${env.VITE_SENTRY_ENVIRONMENT}`}
									target="_blank">
									Sentry
								</Link>
								<Link
									href={`https://us-east-1.console.aws.amazon.com/cognito/v2/idp/user-pools/${env.VITE_AWS_COGNITO_USERPOOLID}/users?region=${env.VITE_AWS_REGION}`}
									target="_blank">
									Cognito
								</Link>
								<Link href="/site/dev/diagnostics">System Diagnostics</Link>
								<Link href="/site/dev/theme">Theme</Link>
							</Stack>
						</CardContent>
					</Card>
					<LegacySystemList />
				</Stack>
			</PageContent>
		</>
	);
};
