import { InfoOutlined } from '@mui/icons-material';
import {
	Box,
	Collapse,
	FormHelperText,
	Stack,
	TableCell,
	TableCellProps,
	TableRow,
	Tooltip,
	Typography,
} from '@mui/material';
import { CheckboxField } from 'components/ui/fields';
import { useField } from 'formik';
import { NotificationSettingDefault } from 'middleware-types';
import { NotificationTableColumns } from './notification-settings-table';
import { SettingsFormValues } from './notifications';

interface NotificationSettingsRowProps {
	item: NotificationSettingDefault;
	columns: NotificationTableColumns;
	open: boolean;
}

export const NotificationSettingsRow = ({ item, columns, open }: NotificationSettingsRowProps) => {
	const includesMethods = columns === 'methods' || columns === 'all';
	const includesDigest = columns === 'emailDigest' || columns === 'all';

	const [, meta] = useField<SettingsFormValues>(item.notificationType);

	// only show an error if this table includes notification methods
	const errorMessage = includesMethods ? meta.error : undefined;
	const hasError = Boolean(errorMessage);
	open = open || hasError;

	return (
		<TableRow
			sx={
				hasError
					? { bgcolor: 'error.light', border: '1px solid', borderColor: 'error.main' }
					: undefined
			}>
			<CollapsableTableCell open={open}>
				<Box px={{ xs: 2, sm: 2.5 }}>
					<Stack direction="row" alignItems="center" spacing={1}>
						<Typography variant="body2">
							{item.displayName}
							{includesMethods && item.requiresAtLeastOne && ' *'}
						</Typography>
						<Tooltip title={item.description}>
							<InfoOutlined sx={{ color: 'neutral.500' }} />
						</Tooltip>
					</Stack>
					{errorMessage && <FormHelperText error>{errorMessage}</FormHelperText>}
				</Box>
			</CollapsableTableCell>
			{includesMethods && (
				<>
					<CollapsableTableCell open={open} align="center">
						<CheckboxField name={`${item.notificationType}.inApp`} />
					</CollapsableTableCell>
					<CollapsableTableCell open={open} align="center">
						<CheckboxField name={`${item.notificationType}.sms`} />
					</CollapsableTableCell>
					<CollapsableTableCell open={open} align="center">
						<CheckboxField name={`${item.notificationType}.email`} />
					</CollapsableTableCell>
				</>
			)}
			{includesDigest && (
				<CollapsableTableCell open={open} align="center">
					<Stack direction="row" spacing={1.5} justifyContent="center">
						<CheckboxField name={`${item.notificationType}.emailDigestHourly`} />
						<CheckboxField name={`${item.notificationType}.emailDigestDaily`} />
					</Stack>
				</CollapsableTableCell>
			)}
		</TableRow>
	);
};

interface CollapsableTableCellProps extends TableCellProps {
	open: boolean;
}

const CollapsableTableCell = ({ open, children, ...cellProps }: CollapsableTableCellProps) => {
	return (
		<TableCell {...cellProps} style={{ paddingTop: 0, paddingBottom: 0 }}>
			<Collapse in={open}>
				<Box py={1}>{children}</Box>
			</Collapse>
		</TableCell>
	);
};
