import { CheckOutlined, Close, MarkAsUnread, WorkOutlineRounded } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
	Avatar,
	Card,
	CardContent,
	Container,
	Link,
	List,
	ListItem,
	ListItemAvatar,
	ListItemButton,
	ListItemSecondaryAction,
	ListItemText,
	Skeleton,
	Stack,
	Typography,
} from '@mui/material';
import { EmptyStateAvatar } from 'components/ui/empty-state-avatar';
import {
	ConfirmModalContent,
	ModalActions,
	ModalLoadingButton,
	useModal,
} from 'components/ui/modal';
import { PageContent, PageTitle } from 'components/ui/page';
import { AssociateInvitation } from 'middleware-types';
import {
	useAcceptAssociateInvitation,
	useAssociateInvitations,
	useDeclineAssociateInvitation,
} from 'pages/app/legacy/old-linked-companies';
import { PageError } from 'utils/errors';
import { useSession } from 'utils/session';
import { theme } from 'utils/theme';

export const OrgInvitationsPage = () => {
	const { user } = useSession();
	if (user?.siteUserId) {
		throw new PageError('Site users cannot become organization associates.');
	}

	return (
		<>
			<PageTitle title="Organization Invitations" />
			<PageContent>
				<Container maxWidth="md" sx={{ height: '100%' }}>
					<Card sx={{ height: '100%' }}>
						<CardContent sx={{ height: '100%', padding: 0 }}>
							<Stack height="100%" display="flex" direction="column">
								<Stack
									direction="column"
									spacing={1}
									padding={2}
									width="100%"
									borderBottom={`1px solid ${theme.palette.divider}`}>
									<Typography fontWeight="medium" display="inline" variant="h2">
										Organization Invitations
									</Typography>
									<Typography variant="body2">
										Invitations to become an organization associate will appear
										here. Depending on the role assigned, organization
										associates can manage organization profiles and settings on
										Evolve.
									</Typography>
								</Stack>
								<OrgInvitationDisplay />
							</Stack>
						</CardContent>
					</Card>
				</Container>
			</PageContent>
		</>
	);
};

/**
 * Display for org invitations
 */
const OrgInvitationDisplay = () => {
	const { invitations, loading } = useAssociateInvitations();

	if (loading) return <OrgInvitationDisplayLoadingSkeleton />;

	if (!invitations || invitations.length < 1)
		return (
			<Stack display="flex" flexDirection="column" alignItems="center" height="100%">
				<Stack spacing={1} justifyContent="center" alignItems="center" height="100%">
					<EmptyStateAvatar
						icon={<MarkAsUnread />}
						avatarProps={{
							bgcolor: 'neutral.50',
						}}
						iconProps={{ color: 'neutral.500' }}
					/>
					<Typography variant="h3">No organization invitations.</Typography>
					<Typography variant="h5">
						Your organization invitations will appear here.
					</Typography>
				</Stack>
			</Stack>
		);

	return (
		<List disablePadding>
			{invitations?.map((inv) => {
				return <OrgInvitationDisplayItem inv={inv} key={inv.id} />;
			})}
		</List>
	);
};

const OrgInvitationDisplayItem = ({ inv }: { inv: AssociateInvitation }) => {
	const { showModal } = useModal();
	const acceptInvitation = useAcceptAssociateInvitation();
	const declineInvitation = useDeclineAssociateInvitation();

	return (
		<ListItem
			disableGutters
			disablePadding
			className="p-0"
			sx={{
				borderBottom: `1px solid ${theme.palette.divider}`,
			}}>
			<ListItemButton component={Link} className="w-full rounded p-4" disableRipple>
				<ListItemAvatar>
					{inv.avatarFile?.file?.currentInstance?.cdnUrl ? (
						<Avatar
							src={inv.avatarFile?.file?.currentInstance?.cdnUrl}
							variant="square"
						/>
					) : (
						<Avatar variant="square">
							<WorkOutlineRounded />
						</Avatar>
					)}
				</ListItemAvatar>
				<ListItemText
					primary={<Typography variant="h4">{inv.organizationName}</Typography>}
				/>
			</ListItemButton>
			<ListItemSecondaryAction className="right-4">
				<Stack direction="row" spacing={1.5}>
					<LoadingButton
						color="primary"
						variant="contained"
						startIcon={<CheckOutlined />}
						onClick={() => acceptInvitation(inv)}>
						Accept
					</LoadingButton>
					<LoadingButton
						variant="outlined"
						startIcon={<Close />}
						onClick={() => {
							showModal({
								title: 'Decline Invitation',
								content: (
									<ConfirmModalContent
										visual={'/img/Caution.svg'}
										subheadline="Are you sure?"
										informativeContent={`Do you really want to decline ${inv.organizationName}'s invitation?`}
									/>
								),
								actions: (
									<ModalActions>
										<ModalLoadingButton
											variant="contained"
											color="primary"
											onClick={() => declineInvitation(inv)}>
											Decline
										</ModalLoadingButton>
									</ModalActions>
								),
							});
						}}>
						Decline
					</LoadingButton>
				</Stack>
			</ListItemSecondaryAction>
		</ListItem>
	);
};

const OrgInvitationDisplayLoadingSkeleton = () => {
	return (
		<List disablePadding>
			<ListItem
				disableGutters
				disablePadding
				className="p-0"
				sx={{
					borderBottom: `1px solid ${theme.palette.divider}`,
				}}>
				<Skeleton variant="rectangular" width="100%" height="4rem" />
			</ListItem>
			<ListItem
				disableGutters
				disablePadding
				className="p-0"
				sx={{
					borderBottom: `1px solid ${theme.palette.divider}`,
				}}>
				<Skeleton variant="rectangular" width="100%" height="4rem" />
			</ListItem>
			<ListItem
				disableGutters
				disablePadding
				className="p-0"
				sx={{
					borderBottom: `1px solid ${theme.palette.divider}`,
				}}>
				<Skeleton variant="rectangular" width="100%" height="4rem" />
			</ListItem>
		</List>
	);
};
