import {
	Card,
	CardActionArea,
	CardContent,
	Grid,
	Skeleton,
	Stack,
	Typography,
} from '@mui/material';
import { LegacyLink } from 'components/ui/legacy-link';
import { useLegacyDatabasesQuery } from './legacy-system-hooks';

export default function LegacySystemList() {
	const { systems, loading } = useLegacyDatabasesQuery();

	const SystemsSkeleton = () => (
		<Grid container spacing={2}>
			{[...Array(10)].map((_, idx) => (
				<Grid item xs={12} lg={6} key={idx}>
					<Skeleton height="5rem" width="100%" />
				</Grid>
			))}
		</Grid>
	);

	return (
		<>
			<Stack spacing={0} width="100%">
				<Grid container spacing={2}>
					{loading ? (
						<SystemsSkeleton data-test="loading-skeleton" />
					) : (
						systems.map((system, idx: number) => (
							<Grid item xs={12} lg={6} key={idx}>
								<LegacyLink systemUrl={system.systemUrl} admin>
									<Card>
										<CardActionArea>
											<CardContent>
												<Typography data-test="system-name" variant="h5">
													{system.name}
												</Typography>
												<Typography
													data-test="system-url"
													color="textSecondary">
													{system.systemUrl}
												</Typography>
											</CardContent>
										</CardActionArea>
									</Card>
								</LegacyLink>
							</Grid>
						))
					)}
				</Grid>
			</Stack>
		</>
	);
}
