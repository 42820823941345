import { Close } from '@mui/icons-material';
import {
	Avatar,
	Box,
	Button,
	Checkbox,
	FormControlLabel,
	IconButton,
	Stack,
	Typography,
} from '@mui/material';
import { theme } from 'utils/theme';
import { useLocalStorage } from 'utils/useLocalStorage';

interface InfoScreenProps {
	onClose: () => void;
}

export const InfoScreen = ({ onClose }: InfoScreenProps) => {
	const [checked, setChecked] = useLocalStorage('evoxInfoDismissed', false);
	return (
		<Stack height="100%" width="100%" position="relative">
			<IconButton onClick={onClose} sx={{ position: 'absolute', top: 8, right: 8 }}>
				<Close />
			</IconButton>
			<Stack
				spacing={2}
				p={2}
				alignItems="center"
				sx={{ backgroundColor: theme.palette.neutral[100] }}>
				<Avatar src="/img/evoxLogo.svg" sx={{ height: 54, width: 54 }} />
				<Box textAlign="center">
					<Typography variant="h3">Hi, I&apos;m EVO-X.</Typography>
					<Typography>Your AI assistant, powered by OpenAI.</Typography>
				</Box>
			</Stack>
			<Stack spacing={2} px={6} py={2} alignItems="center" flex={1} overflow="auto">
				<Typography variant="body2" fontSize={12}>
					LIMITATIONS & GUIDELINES
				</Typography>
				<Box
					alignSelf="stretch"
					p={2}
					textAlign="center"
					borderRadius={2}
					sx={{ backgroundColor: theme.palette.neutral[100] }}>
					<Typography variant="h4">Always double check any output.</Typography>
					<Typography>EVO-X may not always produce accurate information.</Typography>
				</Box>
				<Box
					alignSelf="stretch"
					p={2}
					textAlign="center"
					borderRadius={2}
					sx={{ backgroundColor: theme.palette.neutral[100] }}>
					<Typography variant="h4">Keep conversations on one topic.</Typography>
					<Typography>Create new chats for different topics.</Typography>
				</Box>
			</Stack>
			<Stack p={2} spacing={0.5} alignItems="center">
				<FormControlLabel
					control={
						<Checkbox
							checked={checked}
							onChange={(_e, checked) => setChecked(checked)}
						/>
					}
					label="Don't show this again"
				/>
				<Button
					color="primary"
					variant="contained"
					sx={{ alignSelf: 'stretch' }}
					onClick={onClose}>
					Dismiss
				</Button>
			</Stack>
		</Stack>
	);
};
