import {
	ArrowBack,
	Close,
	DeleteOutlined,
	EditOutlined,
	LabelOutlined,
	MoreHoriz,
	PeopleAltOutlined,
	RestoreFromTrashOutlined,
} from '@mui/icons-material';
import {
	Box,
	Button,
	Dialog,
	Divider,
	IconButton,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	Paper,
	Stack,
	Typography,
} from '@mui/material';
import { EmblemAvatar } from 'components/ui/emblem/emblem-avatar';
import { EmblemAvatarGroup } from 'components/ui/emblem/emblem-avatar-group';
import { Conversation, LabelType, ParticipantEntityType } from 'middleware-types';
import { useRef, useState } from 'react';
import { useIsMobile } from 'utils/useScreenSize';
import { useCommunicationsContext } from '../communications-provider';
import { getCustomLabels } from '../helpers/labels';
import { useRestoreConversation } from '../hooks/conversation-labels-hooks';
import { useConversation } from '../hooks/conversations-hooks';
import { useCommunicationsModal } from '../hooks/use-communications-modal';
import { LabelsPopup } from '../modals/labels-popup';
import { ParticipantsPopup } from '../modals/participants-popup';
import { TrashConversationModal } from '../modals/trash-conversation-modal';
import { UpdateConversationModal } from '../modals/update-conversation-modal';
import { PopoverOrDrawer } from '../shared/popover-or-drawer';
import { ConversationColumnSkeleton } from './conversation-column-skeleton';
import { ConversationMessageBox } from './conversation-message-box';
import { EmptyConversationColumn } from './empty-conversation-column';
import { LabelChip } from './label-chip';
import { MessagesList } from './messages-list';

interface ConversationColumnProps {
	conversation: Conversation;
}

const _ConversationColumn = ({ conversation }: ConversationColumnProps) => {
	const isMobile = useIsMobile();
	const { showModal } = useCommunicationsModal();
	const {
		entityId,
		labels: _labels,
		setSelectedConversationId,
		readOnly,
	} = useCommunicationsContext();

	const restoreConversation = useRestoreConversation();

	// menu states
	const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);

	const labelsPopupAnchor = useRef<HTMLButtonElement | null>(null);
	const [labelsPopupOpen, setLabelsPopupOpen] = useState(false);

	const participantsPopupAnchor = useRef<HTMLDivElement | null>(null);
	const [participantsPopupOpen, setParticipantsPopupOpen] = useState(false);

	const [replyModalOpen, setReplyModalOpen] = useState(false);

	// remove inactive participants, and put the current entity last in the list
	const participants = conversation.participants
		.filter((p) => p.isCurrentParticipant && p.entityType !== ParticipantEntityType.System)
		.sort((a, b) => {
			const aValue = a.entityId === entityId ? 1 : 0;
			const bValue = b.entityId === entityId ? 1 : 0;
			return aValue - bValue;
		});

	// determine if the current entity is an active participant
	const isActiveParticipant = participants.find((p) => p.entityId === entityId) !== undefined;
	const canEditConversation = isActiveParticipant && !readOnly;

	// get labels
	const labels = getCustomLabels(_labels, true);
	const participantLabels =
		conversation.participants.find((p) => p.entityId === entityId)?.participantLabels ?? [];
	const activeLabelIds = participantLabels.map((l) => l.id);
	const activeLabels = labels.filter((label) => activeLabelIds.includes(label.label.id));

	const isInTrash = participantLabels.some((label) => label.type === LabelType.Trash);

	return (
		<>
			<Stack height="100%">
				<Paper elevation={1} sx={{ borderRadius: 0, zIndex: 100 }}>
					<Stack
						direction="row"
						px={2}
						py={{ xs: 1, sm: 1.5 }}
						pl={{ xs: 1, sm: 2 }}
						alignItems="center"
						spacing={0.5}>
						{isMobile && (
							<IconButton onClick={() => setSelectedConversationId(null)}>
								<ArrowBack />
							</IconButton>
						)}
						<Typography sx={{ wordBreak: 'break-word' }} variant="h4">
							{conversation.subject}
						</Typography>
						{!readOnly && (
							<IconButton
								ref={labelsPopupAnchor}
								onClick={() => setLabelsPopupOpen(true)}>
								<LabelOutlined />
							</IconButton>
						)}
						<Box flex={1} />
						{!isMobile && (
							<EmblemAvatarGroup
								ref={participantsPopupAnchor}
								size={32}
								max={4}
								sx={{ cursor: 'pointer' }}
								onClick={() => setParticipantsPopupOpen(true)}>
								{participants.map((p) => (
									<EmblemAvatar
										key={p.entityId}
										emblem={p.emblem}
										showBadge={false}
									/>
								))}
							</EmblemAvatarGroup>
						)}
						<IconButton onClick={(e) => setMenuAnchor(e.currentTarget)}>
							<MoreHoriz />
						</IconButton>
					</Stack>
					{activeLabels.length > 0 && (
						<>
							<Divider />
							<Stack
								direction="row"
								px={2}
								py={1.5}
								gap={1}
								overflow="auto"
								flexWrap={{ xs: 'nowrap', sm: 'wrap' }}>
								{activeLabels.map((label) => (
									<LabelChip key={label.label.id} label={label} />
								))}
							</Stack>
						</>
					)}
				</Paper>
				<Divider />
				<MessagesList messages={conversation.messages} />
				{canEditConversation && (
					<>
						<Divider />
						<Paper elevation={1} sx={{ borderRadius: 0, p: { xs: 1, sm: 1.5 } }}>
							{isMobile ? (
								<Button
									fullWidth
									variant="outlined"
									sx={{
										py: 1,
										backgroundColor: 'neutral.50',
										justifyContent: 'flex-start',
									}}
									onClick={() => setReplyModalOpen(true)}>
									<Typography variant="body2" color="neutral.500">
										Reply to this conversation...
									</Typography>
								</Button>
							) : (
								<Stack minHeight={200} maxHeight={350} overflow="hidden">
									<ConversationMessageBox />
								</Stack>
							)}
						</Paper>
					</>
				)}
			</Stack>
			{/* menu */}
			<Menu
				open={Boolean(menuAnchor)}
				anchorEl={menuAnchor}
				onClose={() => setMenuAnchor(null)}
				onClick={() => setMenuAnchor(null)}>
				{canEditConversation && (
					<MenuItem
						onClick={() =>
							showModal({
								title: 'Edit Conversation Name',
								content: <UpdateConversationModal conversation={conversation} />,
							})
						}>
						<ListItemIcon>
							<EditOutlined />
						</ListItemIcon>
						<ListItemText primary="Edit Name" />
					</MenuItem>
				)}
				<MenuItem onClick={() => setParticipantsPopupOpen(true)}>
					<ListItemIcon>
						<PeopleAltOutlined />
					</ListItemIcon>
					<ListItemText
						primary={!canEditConversation ? 'View Participants' : 'Manage Participants'}
					/>
				</MenuItem>
				{!readOnly && !isInTrash && (
					<MenuItem
						onClick={() =>
							showModal({
								title: 'Are you sure?',
								content: (
									<TrashConversationModal
										conversationName={conversation.subject}
									/>
								),
							})
						}>
						<ListItemIcon>
							<DeleteOutlined />
						</ListItemIcon>
						<ListItemText primary="Trash Conversation" />
					</MenuItem>
				)}
				{isInTrash && (
					<MenuItem onClick={restoreConversation}>
						<ListItemIcon>
							<RestoreFromTrashOutlined />
						</ListItemIcon>
						<ListItemText primary="Restore Conversation" />
					</MenuItem>
				)}
			</Menu>
			{/* labels popup */}
			<PopoverOrDrawer
				open={labelsPopupOpen}
				onClose={() => setLabelsPopupOpen(false)}
				title="Manage Labels"
				popoverProps={{
					anchorEl: labelsPopupAnchor.current,
					anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
					transformOrigin: { vertical: 'top', horizontal: 'center' },
				}}>
				<LabelsPopup labels={labels} activeLabelIds={activeLabelIds} />
			</PopoverOrDrawer>
			{/* participants popup */}
			<PopoverOrDrawer
				open={participantsPopupOpen}
				onClose={() => setParticipantsPopupOpen(false)}
				title={!canEditConversation ? 'Conversation Participants' : 'Manage Participants'}
				popoverProps={{
					anchorEl: participantsPopupAnchor.current,
					anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
					transformOrigin: { vertical: 'top', horizontal: 'center' },
				}}>
				<ParticipantsPopup
					participants={participants}
					onClose={() => setParticipantsPopupOpen(false)}
					canEdit={canEditConversation}
				/>
			</PopoverOrDrawer>
			{/* reply modal (for mobile) */}
			{isMobile && (
				<Dialog open={replyModalOpen} fullScreen>
					<Stack height="100%">
						<Stack
							px={1.5}
							py={1}
							direction="row"
							alignItems="center"
							justifyContent="space-between"
							spacing={1}>
							<Typography variant="h4">Re: {conversation.subject}</Typography>
							<IconButton onClick={() => setReplyModalOpen(false)}>
								<Close />
							</IconButton>
						</Stack>
						<Divider />
						<Stack px={1.5} py={1} flex={1} overflow="hidden">
							<ConversationMessageBox onSuccess={() => setReplyModalOpen(false)} />
						</Stack>
					</Stack>
				</Dialog>
			)}
		</>
	);
};

/**
 * wrappers for this component
 */
export const ConversationColumn = () => {
	const { selectedConversationId } = useCommunicationsContext();
	if (selectedConversationId !== null)
		return <ConversationColumnContentWrapper conversationId={selectedConversationId} />;
	return <EmptyConversationColumn />;
};

const ConversationColumnContentWrapper = ({ conversationId }: { conversationId: string }) => {
	const { conversation, loading } = useConversation(conversationId);
	if (loading) return <ConversationColumnSkeleton />;
	if (!conversation) return <EmptyConversationColumn />;
	return <_ConversationColumn key={conversation.id} conversation={conversation} />;
};
