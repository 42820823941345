import {
	CardContent,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material';
import { OrgEmblemAvatar } from 'components/ui/emblem/emblem-avatar';
import { OrgSearchResult } from 'middleware-types';
import { Status, statusColor } from 'pages/site/system-search/system-search';
import { OrgContextMenu } from './org-context-menu';
import React from 'react';

type OrgSearchRowProps = OrgSearchResult & {
	status: 'Active' | 'Inactive' | undefined;
	refetch: () => void;
};

export const OrgSystemSearchResults = ({ rows }: { rows: OrgSearchRowProps[] }) => {
	return (
		<CardContent
			sx={{
				flex: '1 1 auto',
				overflow: 'hidden',
			}}>
			<TableContainer sx={{ maxHeight: '100%' }}>
				<Table stickyHeader aria-label="simple table">
					<TableHead>{rows.length !== 0 && <OrgTableHeadings />}</TableHead>
					<TableBody>
						{rows.map((row) => (
							<OrgRow {...row} key={row.id} />
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</CardContent>
	);
};

const OrgRow = (props: OrgSearchRowProps): React.JSX.Element => {
	return (
		<TableRow>
			<TableCell>{props.id && <OrgEmblemAvatar id={props.id} />}</TableCell>
			<TableCell>
				<div>
					{props.organizationEmblem?.displayName != props.legalName && 'Display: '}
					{props.organizationEmblem?.displayName}
				</div>
				{props.organizationEmblem?.displayName != props.legalName && (
					<div>Legal: {props.legalName}</div>
				)}
			</TableCell>
			<TableCell>{props.systemEmailAddress}</TableCell>
			<TableCell>{props.displayLocation}</TableCell>
			<TableCell
				sx={{
					color: props.status ? statusColor[props.status] : 'inherit',
				}}>
				{props.status}
			</TableCell>
			<TableCell size="small">
				<OrgContextMenu
					refetch={props.refetch}
					id={props.organizationEmblem?.id}
					status={props.status === 'Active' ? Status.Active : Status.Inactive}
					handle={props.organizationEmblem?.handle}
				/>
			</TableCell>
		</TableRow>
	);
};

const OrgTableHeadings = (): React.JSX.Element => {
	return (
		<TableRow>
			<TableCell size="small" style={{ width: '4%' }}></TableCell>
			<TableCell style={{ width: '15%' }}>Name</TableCell>
			<TableCell style={{ width: '20%' }}>Email Address</TableCell>
			<TableCell style={{ width: '21%' }}>Location</TableCell>
			<TableCell style={{ width: '15%' }}>Status</TableCell>
			<TableCell size="small" style={{ width: '10%' }}></TableCell>
		</TableRow>
	);
};
