import {
	AssignmentOutlined,
	ForumOutlined,
	PeopleAltOutlined,
	ReceiptLongOutlined,
	RoofingOutlined,
	StorefrontOutlined,
} from '@mui/icons-material';
import { Box, Button, Card, Dialog, Stack, Typography } from '@mui/material';
import { LinkCard } from 'components/pages/dashboard/linkCard';
import { useUserQuickLinks } from 'components/pages/dashboard/quickLinks/userQuickLinkHooks';
import { EvoX } from 'components/pages/evoxchat/evox';
import { EvoxLogoIcon } from 'components/ui/icons';
import { FileTracIcon } from 'components/ui/icons/ft-icon';
import { PageTitle } from 'components/ui/page';
import { useEffect, useRef, useState } from 'react';
import { useAccount } from 'utils/useAccount';
import { useIsMediumOrSmallerScreen } from 'utils/useScreenSize';
import { QuickLinks } from '../components/pages/dashboard/quickLinks/quickLinks';

export const SocialUserDashboard = () => {
	const { account } = useAccount();
	const quickLinksMethods = useUserQuickLinks();

	const isMediumOrSmallerScreen = useIsMediumOrSmallerScreen();

	// keep track of the size of the header text
	// this is used to keep the links in line with the evox card
	const headerRef = useRef<HTMLDivElement>(null);
	const [headerHeight, setHeaderHeight] = useState(60);
	useEffect(() => {
		if (!headerRef.current) return;
		const resizeObserver = new ResizeObserver(() => {
			if (!headerRef.current) return;
			setHeaderHeight(headerRef.current.clientHeight);
		});
		resizeObserver.observe(headerRef.current);
		return () => resizeObserver.disconnect();
	}, []);

	const [evoxOpen, setEvoxOpen] = useState(false);

	const presetQuickLinks = [
		{
			id: 'conversation-insurance-quicklink',
			title: 'Conversation Insurance',
			url: 'https://www.conversation-insurance.com/',
			presetIcon: '/img/conversation-insurance-color-logo.svg',
			isPresetLink: true,
		},
	];

	return (
		<Box position="relative" height="100%" overflow="auto" id="main-content">
			<PageTitle title="Dashboard" />

			<Stack
				direction={{ xs: 'column', md: 'row' }}
				px={{ xs: 1.5, md: 3, lg: 6 }}
				py={{ xs: 3, md: 4 }}
				pt={{ xs: 0, md: 4 }}
				spacing={{ xs: 3, sm: 4 }}
				justifyContent={{ xs: undefined, md: 'center' }}
				height="100%"
				overflow="auto"
				position={{ xs: 'relative', md: 'static' }}>
				<Box
					position="absolute"
					height={headerHeight + 140}
					top={0}
					left={0}
					right={0}
					zIndex={-100}
					sx={{ backgroundColor: 'primary.main' }}
				/>
				<Stack
					width={{ xs: undefined, md: '640px' }}
					spacing={{ xs: 3, md: 4 }}
					position={{ xs: undefined, md: 'sticky' }}
					top={0}>
					<Box ref={headerRef}>
						<Typography variant="h1" color="white" noWrap>
							Welcome back to Evolve,{' '}
							<Typography
								variant="h1"
								color="secondary"
								display="inline"
								component="span">
								{account?.name?.firstName}
							</Typography>
							.
						</Typography>
						<Typography color="white">Where would you like to go?</Typography>
					</Box>
					{isMediumOrSmallerScreen ? (
						<Button
							variant="outlined"
							startIcon={<EvoxLogoIcon />}
							onClick={() => setEvoxOpen(true)}>
							Chat with EVOX
						</Button>
					) : (
						<Card sx={{ flex: 1 }}>
							<EvoX />
						</Card>
					)}
				</Stack>
				<Stack
					width={{ xs: undefined, md: '1100px' }}
					spacing={{ xs: 3, md: 4 }}
					alignSelf={{ xs: undefined, md: 'flex-start' }}>
					{!isMediumOrSmallerScreen && <Box height={headerHeight} />}
					<Box
						display="grid"
						gridTemplateColumns={{
							xs: 'repeat(2, 1fr)',
							lg: 'repeat(3, 1fr)',
							xl: 'repeat(4, 1fr)',
						}}
						gridAutoRows="1fr"
						gap={2}>
						<LinkCard
							Icon={FileTracIcon}
							text="FileTrac Legacy"
							link="app/legacy/linked-companies"
						/>
						<LinkCard Icon={PeopleAltOutlined} text="Network" disabled />
						<LinkCard Icon={AssignmentOutlined} text="Adjuster" disabled />
						<LinkCard Icon={RoofingOutlined} text="Inspect" disabled />
						<LinkCard Icon={StorefrontOutlined} text="Marketplace" disabled />
						<LinkCard Icon={ReceiptLongOutlined} text="Billing" disabled />
						<LinkCard Icon={ForumOutlined} text="Conversations" disabled />
					</Box>
					<QuickLinks
						methods={quickLinksMethods}
						canEdit
						presetQuickLinks={presetQuickLinks}
					/>
				</Stack>
			</Stack>
			{/* EvoX popup for mobile view */}
			{evoxOpen && (
				<Dialog open={evoxOpen} fullScreen>
					<EvoX onClose={() => setEvoxOpen(false)} />
				</Dialog>
			)}
		</Box>
	);
};
