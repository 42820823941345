import { AlternateEmail, BusinessOutlined, Star } from '@mui/icons-material';
import { Emblem } from 'middleware-types';
import { Permission } from 'utils/permissions';
import { useSiteUser } from 'utils/useSiteUser';
import { useOrgDropdownInfo } from '../hooks';
import { DropdownItem, EmblemDropdownContent } from './emblem-dropdown-content';

export const OrgEmblemDropdown = ({ emblem }: { emblem: Emblem }) => {
	const { info, loading } = !emblem.deactivated
		? useOrgDropdownInfo(emblem.id)
		: { info: null, loading: false };

	const items: DropdownItem[] = [
		{
			icon: <Star color="primary" fontSize="small" />,
			label: 'Primary Location',
			value: info?.displayLocation,
		},
		{
			icon: <AlternateEmail fontSize="small" />,
			label: 'Handle',
			value: emblem.handle,
		},
	];

	const { hasPermission } = useSiteUser();
	const checkPermissions = () => hasPermission(Permission.Site_OrgProfile_R);

	return (
		<EmblemDropdownContent
			emblem={emblem}
			items={items}
			loading={loading}
			profileUrl={`/orgs/${emblem.handle}/profile`}
			ProfileIcon={BusinessOutlined}
			checkSiteUserProfilePermissions={checkPermissions}
		/>
	);
};
