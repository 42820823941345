import { DeleteOutline, Edit, ForumOutlined, MoreHoriz } from '@mui/icons-material';
import {
	Avatar,
	IconButton,
	ListItem,
	ListItemAvatar,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
} from '@mui/material';
import { SavedConversation } from 'middleware-types';
import { useState } from 'react';
import { DeletePromptDrawer } from './deletePromptDrawer';
import { SaveDrawer } from './saveDrawer';

interface SavedPromptListItemProps {
	conversation: SavedConversation;
	selectConversation: (conversationId: string) => void;
}

export const SavedPromptListItem = ({
	conversation,
	selectConversation,
}: SavedPromptListItemProps) => {
	const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);
	const [deleteDrawerOpen, setDeleteDrawerOpen] = useState(false);
	const [editDrawerOpen, setEditDrawerOpen] = useState(false);

	return (
		<ListItem
			key={conversation.conversationId}
			disablePadding
			secondaryAction={
				<IconButton edge="end" onClick={(e) => setMenuAnchor(e.currentTarget)}>
					<MoreHoriz />
				</IconButton>
			}>
			<ListItemButton onClick={() => selectConversation(conversation.conversationId)}>
				<ListItemAvatar>
					<Avatar>
						<ForumOutlined />
					</Avatar>
				</ListItemAvatar>
				<ListItemText primary={conversation.conversationName} />
			</ListItemButton>
			{/* Menu */}
			<Menu
				open={Boolean(menuAnchor)}
				anchorEl={menuAnchor}
				onClick={() => setMenuAnchor(null)}
				onClose={() => setMenuAnchor(null)}>
				<MenuItem onClick={() => setEditDrawerOpen(true)}>
					<ListItemIcon>
						<Edit />
					</ListItemIcon>
					<ListItemText>Edit Name</ListItemText>
				</MenuItem>
				<MenuItem onClick={() => setDeleteDrawerOpen(true)}>
					<ListItemIcon>
						<DeleteOutline />
					</ListItemIcon>
					<ListItemText>Delete</ListItemText>
				</MenuItem>
			</Menu>
			{/* Delete Drawer */}
			<DeletePromptDrawer
				open={deleteDrawerOpen}
				onClose={() => setDeleteDrawerOpen(false)}
				conversation={conversation}
			/>
			{/* Edit Drawer */}
			<SaveDrawer
				open={editDrawerOpen}
				onClose={() => setEditDrawerOpen(false)}
				conversationId={conversation.conversationId}
				conversationName={conversation.conversationName}
			/>
		</ListItem>
	);
};
