import {
	CloseOutlined,
	ErrorOutlined,
	LockOutlined,
	MoreHorizOutlined,
	PowerSettingsNewOutlined,
	PublicOutlined,
} from '@mui/icons-material';
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { EmptyStateAvatar } from 'components/ui/empty-state-avatar';
import {
	ConfirmModalContent,
	ModalActionButton,
	ModalActions,
	useModal,
} from 'components/ui/modal';
import { Emblem } from 'middleware-types';
import { useState } from 'react';
import { Permission } from 'utils/permissions';
import { useSession } from 'utils/session';
import { useAssociateUser } from 'utils/useAssociateUser';
import { useSiteUser } from 'utils/useSiteUser';
import { useDeleteRelationship, useSetPrivate } from './hooks';

/**
 * The Row Action.
 *
 * @enum {number}
 */
enum RowAction {
	RemoveChild,
	MakePrivate,
	MakePublic,
}

/**
 * The org status.
 *
 * @enum {number}
 */
enum Status {
	Private = 'Private',
	Public = 'Public',
}

/**
 * Renders the context menu for child organizations.
 * @param props
 * @returns
 */
export const OrgHierarchyContextMenu = ({
	childEmblem,
	parentId,
}: {
	childEmblem: Emblem;
	parentId: string;
}): React.JSX.Element => {
	const { user } = useSession();
	const isSiteUser = !!user.siteUserId;
	const { hasPermission: hasSiteUserPermission } = useSiteUser();
	const { hasPermission: hasAssociateUserPermission } = useAssociateUser(parentId);
	const hasPermission = isSiteUser ? hasSiteUserPermission : hasAssociateUserPermission;
	const [anchorEl, setAnchorEl] = useState<Element | null>(null);
	const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void =>
		setAnchorEl(event.currentTarget);
	const { showModal } = useModal();
	const { removeChild } = useDeleteRelationship(parentId);
	const { setPrivate } = useSetPrivate(parentId);

	const onAction = (action: RowAction): void => {
		switch (action) {
			case RowAction.RemoveChild: {
				showModal({
					title: 'Delete Invitation',
					content: (
						<ConfirmModalContent
							visual={
								<EmptyStateAvatar
									avatarProps={{ bgcolor: 'error.50' }}
									iconProps={{ color: 'error.500' }}
									icon={<ErrorOutlined />}
								/>
							}
							subheadline="Are you sure?"
							informativeContent={`Do you really want to remove ${childEmblem.displayName} as a child?`}
						/>
					),
					actions: (
						<ModalActions>
							<ModalActionButton size="large" variant="outlined">
								Cancel
							</ModalActionButton>
							<ModalActionButton
								size="large"
								variant="contained"
								color="error"
								onClick={() => {
									if (!childEmblem?.id) return;
									removeChild(childEmblem.id);
								}}>
								Release Child
							</ModalActionButton>
						</ModalActions>
					),
				});
				break;
			}
			case RowAction.MakePublic:
			case RowAction.MakePrivate: {
				const pubOrPriv = action === RowAction.MakePublic ? 'Public' : 'Private';
				const btnColor = action === RowAction.MakePublic ? 'primary' : 'error';
				const modalVisual =
					pubOrPriv === 'Public' ? (
						<EmptyStateAvatar
							avatarProps={{ bgcolor: 'primary.50' }}
							iconProps={{ color: 'primary.500' }}
							icon={<PowerSettingsNewOutlined />}
						/>
					) : (
						<EmptyStateAvatar
							avatarProps={{ bgcolor: 'error.50' }}
							iconProps={{ color: 'error.500' }}
							icon={<ErrorOutlined />}
						/>
					);
				showModal({
					title: `Make Organization ${pubOrPriv}`,
					content: (
						<ConfirmModalContent
							visual={modalVisual}
							subheadline="Are you sure?"
							informativeContent={`Do you really want to make the organization ${childEmblem.displayName} ${pubOrPriv}?`}
						/>
					),
					actions: (
						<ModalActions>
							<ModalActionButton size="large" variant="outlined">
								Cancel
							</ModalActionButton>
							<ModalActionButton
								size="large"
								variant="contained"
								color={btnColor}
								onClick={() => {
									if (!childEmblem.id) return;
									return setPrivate(
										childEmblem.id,
										action === RowAction.MakePublic ? false : true
									);
								}}>
								Make {pubOrPriv}
							</ModalActionButton>
						</ModalActions>
					),
				});
				break;
			}
		}
	};

	const handleRowAction = (action: RowAction): void => {
		setAnchorEl(null);
		if (onAction) {
			onAction(action);
		}
	};

	/**
	 * The actions possible for a site user at the hierarchy context menu.
	 *  @type {*} */
	const siteUserTableActions = [
		{
			status: [Status.Private, Status.Public],
			permissions: [Permission.Site_OrgHierarchy_U],
			action: RowAction.RemoveChild,
			text: 'Release Child',
			icon: <CloseOutlined />,
			disabled: false,
		},
		{
			status: [Status.Public],
			disabled: false,
			permissions: [Permission.Site_OrgAcct_U],
			action: RowAction.MakePrivate,
			text: 'Make Private',
			icon: <LockOutlined />,
		},
		{
			status: [Status.Private],
			disabled: false,
			permissions: [Permission.Site_OrgAcct_U],
			action: RowAction.MakePublic,
			text: 'Make Public',
			icon: <PublicOutlined />,
		},
	];

	/**
	 * The actions possible for an org associate at the hierarchy context menu.
	 *  @type {*} */
	const associateTableActions = [
		{
			status: [Status.Public],
			disabled: false,
			permissions: [Permission.Org_Account_U],
			action: RowAction.MakePrivate,
			text: 'Make Private',
			icon: <LockOutlined />,
		},
		{
			status: [Status.Private],
			disabled: false,
			permissions: [Permission.Org_Account_U],
			action: RowAction.MakePublic,
			text: 'Make Public',
			icon: <PublicOutlined />,
		},
	];

	const tableActions = isSiteUser ? siteUserTableActions : associateTableActions;

	let menu = tableActions
		.filter((ta) => {
			return (
				hasPermission(ta.permissions ?? [], { all: false }) &&
				ta.status.includes(childEmblem.private ? Status.Private : Status.Public)
			);
		})
		.map((ta, k) => {
			return (
				<MenuItem
					key={k}
					onClick={
						ta.action !== undefined ? (): void => handleRowAction(ta.action) : undefined
					}
					disabled={ta.disabled}>
					<ListItemIcon>{ta.icon}</ListItemIcon>
					<ListItemText>{ta.text}</ListItemText>
				</MenuItem>
			);
		});

	return (
		<>
			{menu.length > 0 && (
				<IconButton
					aria-label="more"
					aria-controls="long-menu"
					aria-haspopup="true"
					onClick={handleClick}>
					<MoreHorizOutlined />
				</IconButton>
			)}
			<Menu
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={(): void => setAnchorEl(null)}>
				{menu}
			</Menu>
		</>
	);
};
