import { Close } from '@mui/icons-material';
import { Box, Drawer, Fade, IconButton, Paper, Popper, Stack } from '@mui/material';
import { Emblem, EmblemEntityType } from 'middleware-types';
import { ReactElement, ReactNode, cloneElement, useRef, useState } from 'react';
import { theme } from 'utils/theme';
import { useIsMobile } from 'utils/useScreenSize';
import { OrgEmblemDropdown } from './org-emblem-dropdown';
import { SiteUserEmblemDropdown } from './site-user-emblem-dropdown';
import { UserEmblemDropdown } from './user-emblem-dropdown';

interface EmblemDropdownProps {
	emblem: Emblem | undefined;
	disabled: boolean;
	children: ReactElement;
}

export const EmblemDropdown = ({ emblem, disabled, children }: EmblemDropdownProps) => {
	const isMobile = useIsMobile();

	if (!emblem || disabled) return children;

	let content: ReactNode = undefined;
	switch (emblem.entityType) {
		case EmblemEntityType.User:
			if (emblem.extraData?.siteUserId) content = <SiteUserEmblemDropdown emblem={emblem} />;
			else content = <UserEmblemDropdown emblem={emblem} />;
			break;
		case EmblemEntityType.Organization:
			content = <OrgEmblemDropdown emblem={emblem} />;
			break;
	}

	if (content === undefined) return children;
	return isMobile ? (
		<EmblemDropdownDrawer content={content}>{children}</EmblemDropdownDrawer>
	) : (
		<EmblemDropdownPopper content={content}>{children}</EmblemDropdownPopper>
	);
};

/**
 * display the dropdown in a popper
 */
interface EmblemDropdownPopperProps {
	content: ReactNode;
	children: ReactElement;
}

const EmblemDropdownPopper = ({ content, children }: EmblemDropdownPopperProps) => {
	const elementRef = useRef<HTMLElement>(null);
	const [open, _setOpen] = useState(false);

	// setting the open state like this mimics the delay of a tooltip component
	const timeoutRef = useRef<NodeJS.Timeout>();
	const setOpen = (value: boolean) => {
		if (timeoutRef.current) clearTimeout(timeoutRef.current);
		const delay = value ? 200 : 100;
		timeoutRef.current = setTimeout(() => _setOpen(value), delay);
	};

	// using 'isMain' prevents the popper from opening again if we
	// hover over the content as it's fading
	const getMouseListeners = (isMain?: boolean) => ({
		onMouseOver: () => (isMain || open) && setOpen(true),
		onMouseLeave: () => setOpen(false),
	});

	return (
		<>
			{cloneElement(children, { ref: elementRef, ...getMouseListeners(true) })}
			<Popper
				open={open}
				anchorEl={elementRef.current}
				placement="bottom-start"
				transition
				sx={{ zIndex: theme.zIndex.tooltip }}
				modifiers={[
					{
						name: 'preventOverflow',
						options: {
							altAxis: true,
						},
					},
				]}>
				{({ placement, TransitionProps }) => (
					<Fade {...TransitionProps} timeout={200}>
						{/**
						 * This wrapper makes sure the popper always uses its max height of 400px
						 * when trying to position itself. Otherwise its position would get screwed
						 * up once the profile information loads.
						 */}
						<Stack
							height={400}
							width={300}
							flexDirection={placement.includes('top') ? 'column-reverse' : 'column'}>
							<Box height={12} {...getMouseListeners()} />
							<Paper
								elevation={2}
								component={Stack}
								overflow="hidden"
								{...getMouseListeners()}>
								{content}
							</Paper>
						</Stack>
					</Fade>
				)}
			</Popper>
		</>
	);
};

/**
 * display the dropdown in a drawer
 */
interface EmblemDropdownDrawerProps {
	content: ReactNode;
	children: ReactElement;
}

const EmblemDropdownDrawer = ({ content, children }: EmblemDropdownDrawerProps) => {
	const [open, setOpen] = useState(false);
	return (
		<>
			{cloneElement(children, { onClick: () => setOpen(true) })}
			<Drawer open={open} anchor="bottom">
				{content}
				<IconButton
					onClick={() => setOpen(false)}
					sx={{ position: 'absolute', top: 5, right: 5 }}>
					<Close />
				</IconButton>
			</Drawer>
		</>
	);
};
