import { gql, useMutation } from '@apollo/client';
import { Container, Grid, Stack, Typography } from '@mui/material';
import { OrgForm } from 'components/pages/org/account/new/org-form';
import { PageContent, PageTitle } from 'components/ui/page';
import { useToast } from 'components/ui/toast';
import { Mutation, MutationOrganizationCreateArgs, OrganizationCreation } from 'middleware-types';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { handleNoResponse, responseHasErrors } from 'utils/errors';
import { refreshToken } from 'utils/session';
import { ALL_USER_ORG_PERMISSIONS } from 'utils/useAssociateUser';
import { useDefaults } from 'utils/useDefaults';
import { geocode } from 'utils/useGeocode';
import { useValidation } from 'utils/useValidation';

/**
 * The OrgScreen is a create organization page.
 */
export const OrgCreatePage: React.FC = () => {
	const { initialValues, loading, validationSchema, onSubmit, error } = useOrgForm();
	return (
		<PageContent>
			<PageTitle title="Create Organization" />
			<Container>
				<Grid container justifyContent="center">
					<Grid item xs={12} sm={10} md={8}>
						<Stack alignItems="center" justifyContent="center" mt={2} spacing={2}>
							<img width={75} src="/img/evolve-logo.svg" alt="FTEvolve" />
							<Typography variant="h1">Create an Organization</Typography>
							<Typography variant="body2" width="55%" textAlign="center">
								Register to connect with insurance professionals and find work in
								your area.
							</Typography>
							<OrgForm
								initialValues={initialValues}
								onSubmit={onSubmit}
								validationSchema={validationSchema}
								loading={loading}
								error={error}
							/>
						</Stack>
					</Grid>
				</Grid>
			</Container>
		</PageContent>
	);
};

/**
 * useOrgForm() - a custom hook to handle the data logic for organization onboarding.
 *
 * @returns
 */
const useOrgForm = () => {
	const toast = useToast();
	const validation = useValidation('OrganizationCreation');
	const [updateOrg, updateOrgStatus] = useMutation<
		Pick<Mutation, 'organizationCreate'>,
		MutationOrganizationCreateArgs
	>(
		gql`
			mutation organizationCreate($organizationCreation: OrganizationCreation!) {
				organizationCreate(organizationCreation: $organizationCreation) {
					handle
				}
			}
		`,
		{
			refetchQueries: [ALL_USER_ORG_PERMISSIONS],
		}
	);
	const defaults = useDefaults();
	const navigate = useNavigate();

	const initialValues: OrganizationCreation = {
		legalName: '',
		displayName: '',
		systemEmailAddress: '',
		mainPhoneNumber: {
			countryCode: defaults.country.phonePrefix,
			number: '',
		},
		mailingAddress: {
			countryId: defaults.country.id,
			address1: '',
			address2: '',
			municipality: '',
			adminArea1Id: '',
			adminArea2Id: '',
			postalCode: '',
			coordinate: undefined,
		},
	};

	const onSubmit = async (values: OrganizationCreation, redirect: boolean): Promise<boolean> => {
		if (!values.mailingAddress) return Promise.resolve(false);
		return await geocode(values.mailingAddress).then((location) =>
			updateOrg({
				variables: {
					organizationCreation: {
						...values,
						mailingAddress: {
							...values.mailingAddress,
							coordinate: location,
						},
					},
				},
				update: (cache) => {
					cache.evict({
						id: 'ROOT_QUERY',
						fieldName: `affiliatedOrganizations`,
					});
					cache.evict({
						id: 'ROOT_QUERY',
						fieldName: `allUserOrgPermissions`,
					});
				},
			})
				.then(async (res) => {
					await refreshToken();
					if (responseHasErrors(res.errors, { toast })) {
						return false;
					}
					toast.push('Thank you for adding an Organization to Evolve.', {
						variant: 'success',
					});
					redirect && navigate(`../${res.data?.organizationCreate.handle}`);
					return true;
				})
				.catch(() => {
					handleNoResponse({ toast });
					return false;
				})
		);
	};

	return {
		loading: validation.loading,
		validationSchema: validation.schema,
		initialValues,
		onSubmit,
		error: updateOrgStatus.error,
	};
};
