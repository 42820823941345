import { useMutation, useQuery } from '@apollo/client';
import { LocationAddressFormValues } from 'components/pages/user/account/locations/locations-form';
import { GEOADDRESS_FIELDS } from 'components/ui/fields/address';
import { useToast } from 'components/ui/toast';
import { format } from 'date-fns';
import gql from 'graphql-tag';
import {
	LocationAddressUpdate,
	Mutation,
	MutationOrganizationAccountLocationAddressAddOrUpdateArgs,
	MutationOrganizationAccountLocationAddressDeleteArgs,
	Query,
	QueryOrganizationAccountArgs,
} from 'middleware-types';
import { handleNoResponse, responseHasErrors } from 'utils/errors';
import { zoneDateOnly } from 'utils/utils';

/**
 * Delete an organization location address.
 */
export const DELETE_ORG_LOCATION_ADDRESS = gql`
	mutation organizationAccountLocationAddressDelete(
		$organizationId: ID!
		$locationAddressId: ID!
	) {
		organizationAccountLocationAddressDelete(
			organizationId: $organizationId
			locationAddressId: $locationAddressId
		)
	}
`;

/**
 * Hook to delete an organization location address.
 * @param organizationId
 * @param options
 * @returns
 */
export const useDeleteOrgLocationAddressMutation = (organizationId: string) => {
	const toast = useToast();
	const [orgAccountLocationAddressDelete, { loading }] = useMutation<
		Pick<Mutation, 'organizationAccountLocationAddressDelete'>,
		MutationOrganizationAccountLocationAddressDeleteArgs
	>(DELETE_ORG_LOCATION_ADDRESS, {
		update: (cache) => {
			cache.evict({
				id: `OrganizationAccount:${organizationId}`,
				fieldName: 'locationAddresses',
			});
		},
	});

	return {
		deleteLocation: async (locationAddressId: string) => {
			return orgAccountLocationAddressDelete({
				variables: {
					organizationId,
					locationAddressId,
				},
			})
				.then((res) => {
					if (responseHasErrors(res.errors, { toast })) {
						return false;
					}
					toast.push('Location address deleted successfully.', {
						variant: 'success',
					});
					return true;
				})
				.catch(() => {
					handleNoResponse({ toast });
					return false;
				});
		},
		loading,
	};
};

/**
 * Add or update a organization location address.
 */
export const ADD_OR_UPDATE_ORG_LOCATION_ADDRESS = gql`
	mutation organizationAccountLocationAddressAddOrUpdate(
		$organizationId: ID!
		$locationAddressId: ID
		$update: LocationAddressUpdate!
	) {
		organizationAccountLocationAddressAddOrUpdate(
			organizationId: $organizationId
			locationAddressId: $locationAddressId
			update: $update
		) {
			id
			parentId
			addressType
			fromDate
			toDate
			address {
				countryId
				address1
				address2
				municipality
				adminArea1Id
				adminArea1 {
					id
					field
					value
					displayName
					displayOrder
				}
				adminArea2Id
				adminArea2 {
					id
					field
					value
					displayName
					displayOrder
				}
				postalCode
				coordinate {
					latitude
					longitude
				}
			}
		}
	}
`;

/**
 * Hook to add or update an organization location address.
 * @param organizationId
 * @returns
 */
export const useAddOrUpdateOrgLocationAddressMutation = (organizationId: string) => {
	const toast = useToast();
	const [locationAddOrUpdate, { loading, error }] = useMutation<
		Pick<Mutation, 'organizationAccountLocationAddressAddOrUpdate'>,
		MutationOrganizationAccountLocationAddressAddOrUpdateArgs
	>(ADD_OR_UPDATE_ORG_LOCATION_ADDRESS, {
		update: (cache) => {
			cache.evict({
				id: `OrganizationAccount:${organizationId}`,
				fieldName: 'locationAddresses',
			});
		},
	});
	const addOrUpdate = async (location: LocationAddressFormValues, locationId?: string) => {
		return locationAddOrUpdate({
			variables: {
				organizationId,
				locationAddressId: locationId,
				update: {
					...(location as LocationAddressUpdate),
					fromDate: location.fromDate ? format(location.fromDate, 'yyyy-MM-dd') : null,
					toDate: location.toDate ? format(location.toDate, 'yyyy-MM-dd') : null,
				},
			},
		})
			.then(async (res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				toast.push('Location information updated successfully.', {
					variant: 'success',
				});
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	return { addOrUpdate, loading, error };
};

/**
 * Organization locations query.
 */
export const ORG_LOCATIONS = gql`
	${GEOADDRESS_FIELDS}
	query organizationAccount($organizationId: ID!) {
		organizationAccount(organizationId: $organizationId) {
			id
			locationAddresses {
				id
				parentId
				addressType
				fromDate
				toDate
				address {
					...GeoAddressFields
				}
			}
		}
	}
`;

/**
 * Hook to query the organization locations.
 * @param organizationId
 * @returns
 */
export const useOrgLocationsQuery = (organizationId: string) => {
	const { data, loading, error } = useQuery<
		Pick<Query, 'organizationAccount'>,
		QueryOrganizationAccountArgs
	>(ORG_LOCATIONS, {
		variables: {
			organizationId,
		},
	});

	return {
		locations:
			data?.organizationAccount?.locationAddresses?.map((la) => ({
				id: la.id,
				address: la.address,
				addressType: la.addressType,
				fromDate: la?.fromDate ? zoneDateOnly(la?.fromDate) : null,
				toDate: la?.toDate ? zoneDateOnly(la.toDate) : null,
			})) ?? [],
		loading,
		error,
	};
};
