import {
	AccountTreeOutlined,
	AlternateEmail,
	CircleOutlined,
	WorkOutline,
} from '@mui/icons-material';
import { Emblem } from 'middleware-types';
import { useSiteUserDropdownInfo } from '../hooks';
import { DropdownItem, EmblemDropdownContent } from './emblem-dropdown-content';

export const SiteUserEmblemDropdown = ({ emblem }: { emblem: Emblem }) => {
	const { info, loading } = useSiteUserDropdownInfo(emblem.id, emblem.extraData!.siteUserId!);

	const roleName = info?.siteUser.roles?.[0].name;

	const items: DropdownItem[] = [
		{
			icon: <CircleOutlined fontSize="small" />,
			label: 'Site User Status',
			value: info?.account.deactivated ? 'Inactive' : 'Active',
		},
		{
			icon: <AlternateEmail fontSize="small" />,
			label: 'Handle',
			value: emblem.handle,
		},
		{
			icon: <AccountTreeOutlined fontSize="small" />,
			label: 'Role',
			value: roleName,
		},
		{
			icon: <WorkOutline fontSize="small" />,
			label: 'Affiliation',
			value: info?.siteUser.affiliation,
		},
	];

	return (
		<EmblemDropdownContent
			emblem={emblem}
			subtext={roleName}
			items={items}
			loading={loading}
			hideActions
		/>
	);
};
