import { FileAccessLevel, FileInstanceInformation, SortDirection } from 'middleware-types';
import { FilesSortColumn } from '../components/sort-options-dropdown';
import { useMemo } from 'react';

const getAccessLevelOrder = (accessLevel: FileAccessLevel): number => {
	switch (accessLevel) {
		case FileAccessLevel.Public:
			return 0;
		case FileAccessLevel.Confidential:
			return 1;
		case FileAccessLevel.Sensitive:
			return 2;
	}
};

const compareFiles = (
	a: FileInstanceInformation,
	b: FileInstanceInformation,
	column: FilesSortColumn
): number => {
	switch (column) {
		case FilesSortColumn.Name:
			return a.fileName.localeCompare(b.fileName);
		case FilesSortColumn.LastUpdated: {
			const aDate = new Date(a.uploadedUtc);
			const bDate = new Date(b.uploadedUtc);
			return aDate.getTime() - bDate.getTime();
		}
		case FilesSortColumn.UpdatedBy: {
			const aName = `${a.uploadedBy?.name.firstName} ${a.uploadedBy?.name.lastName}`;
			const bName = `${b.uploadedBy?.name.firstName} ${b.uploadedBy?.name.lastName}`;
			return aName.localeCompare(bName);
		}
		case FilesSortColumn.Privacy:
			return getAccessLevelOrder(a.accessLevel) - getAccessLevelOrder(b.accessLevel);
		case FilesSortColumn.VirusStatus:
			return a.virusStatus.localeCompare(b.virusStatus);
		case FilesSortColumn.Size:
			return a.fileSize - b.fileSize;
	}
};

export const useSortFiles = (
	files: FileInstanceInformation[],
	column: FilesSortColumn,
	direction: SortDirection
) => {
	const sortedFiles = useMemo(() => {
		return [...files].sort((a, b) => {
			const result = compareFiles(a, b, column);
			return result * (direction === SortDirection.Ascending ? 1 : -1);
		});
	}, [files, column, direction]);
	return sortedFiles;
};
