import { Checkbox, FormControlLabel } from '@mui/material';
import { useFormikContext } from 'formik';
import { NotificationType } from 'middleware-types';
import { SettingFormValues, SettingsFormValues } from './notifications';

interface GroupCheckboxProps {
	label: string;
	columnName: keyof SettingFormValues;
	notificationTypes: NotificationType[];
}

export const GroupCheckbox = ({ label, columnName, notificationTypes }: GroupCheckboxProps) => {
	const { values, setFieldValue } = useFormikContext<SettingsFormValues>();
	const checkboxValues = notificationTypes.map((type) => values[type][columnName]);

	const checked = checkboxValues.some((v) => v);
	const indeterminate = checked && !checkboxValues.every((v) => v);

	const onChange = async (_e, checked: boolean) => {
		for (const type of notificationTypes) {
			await setFieldValue(`${type}.${columnName}`, checked);
		}
	};

	return (
		<FormControlLabel
			sx={{ m: 0 }}
			label={label}
			labelPlacement="top"
			slotProps={{ typography: { variant: 'caption' } }}
			control={
				<Checkbox checked={checked} indeterminate={indeterminate} onChange={onChange} />
			}
		/>
	);
};
