import { useQuery } from '@apollo/client';
import { client } from 'utils/apollo';
import { EMBLEM_FIELDS } from 'components/ui/emblem/fragments';
import { useToast } from 'components/ui/toast';
import gql from 'graphql-tag';
import {
	ConnectionPageType,
	ConnectionSortBy,
	Emblem,
	Query,
	SortDirection,
} from 'middleware-types';

export const useUserConnectionsOrRequestsQuery = (
	pageType: ConnectionPageType,
	userId?: string,
	markInboundAsSeen?: boolean,
	searchFor?: string
) => {
	const toast = useToast();
	const { data, loading } = useQuery<Pick<Query, 'userConnectionsOrRequests'>>(
		USER_CONNECTIONS_OR_REQUESTS,
		{
			variables: {
				userId,
				pageSize: 999,
				offset: 0,
				sortedBy: ConnectionSortBy.StateChangedDateTimeUtc,
				sortDirection: SortDirection.Descending,
				searchFor: searchFor || '',
				pageType,
				markInboundAsSeen,
			},
			fetchPolicy: 'cache-and-network',
			skip: !userId,
			onCompleted: () => {
				client.cache.evict({
					id: 'ROOT_QUERY',
					fieldName: 'getUserBadgeCounts',
				});

				client.cache.gc();
			},
			onError: () => {
				toast.push(
					`An error occurred while retrieving connections or connection requests. Please try again later or contact Support.`,
					{
						variant: 'error',
					}
				);
			},
		}
	);
	const totalCount = data?.userConnectionsOrRequests?.totalCount ?? 0;

	const userConnectionsOrRequests =
		data?.userConnectionsOrRequests?.items.map((e) => {
			let otherUserEmblem: Emblem | undefined;
			let otherOrgEmblem: Emblem | undefined;

			// There is a requesting user and it's not the current user
			if (e.requestingUserId && e.requestingUserId !== userId)
				otherUserEmblem = e.requestingUserEmblem!;
			// There is a requesting organization (we are not an org)
			else if (e.requestingOrganizationId) otherOrgEmblem = e.requestingOrganizationEmblem!;
			// There is a targeted user and it's not the current user
			else if (e.targetUserId && e.targetUserId !== userId)
				otherUserEmblem = e.targetUserEmblem!;
			// There is a targeted organization (we are not an org)
			else if (e.targetOrganizationId) otherOrgEmblem = e.targetOrganizationEmblem!;

			return {
				connectionId: e.id,
				otherUserEmblem: otherUserEmblem,
				otherOrganizationEmblem: otherOrgEmblem,
			};
		}) ?? [];

	return { loading, userConnectionsOrRequests, totalCount };
};

// Query for getting the user's inbound connection requests. Also marks those requests as seen.
export const USER_CONNECTIONS_OR_REQUESTS = gql`
	${EMBLEM_FIELDS}
	query userConnectionsOrRequests(
		$userId: ID!
		$pageSize: Float!
		$offset: Float!
		$sortedBy: ConnectionSortBy!
		$sortDirection: SortDirection!
		$searchFor: String!
		$pageType: ConnectionPageType!
		$markInboundAsSeen: Boolean
	) {
		userConnectionsOrRequests(
			userId: $userId
			pageSize: $pageSize
			offset: $offset
			sortedBy: $sortedBy
			sortDirection: $sortDirection
			searchFor: $searchFor
			pageType: $pageType
			markInboundAsSeen: $markInboundAsSeen
		) {
			items {
				id
				requestingUserId
				requestingUserEmblem {
					...EmblemFields
				}
				requestingOrganizationId
				requestingOrganizationEmblem {
					...EmblemFields
				}
				targetUserId
				targetUserEmblem {
					...EmblemFields
				}
				targetOrganizationId
				targetOrganizationEmblem {
					...EmblemFields
				}
			}
			totalCount
		}
	}
`;
