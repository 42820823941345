import { SvgIcon, SvgIconProps } from '@mui/material';

export const FileTracIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon {...props}>
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 37" fill="none">
				<g clipPath="url(#clip0_222_403)">
					<path
						d="M6.92366 17.3811L8.71684 16.346L10.51 15.3098L12.3032 14.2747L15.6138 12.3652H15.615L18.2051 10.8683L19.1995 10.2938H19.2007L20.718 9.41587L22.5109 8.38127H22.511L22.5119 8.38202L24.3038 7.3458L26.0971 6.31065H26.097L26.0973 6.31046L24.3041 5.27565V5.27549L22.5109 4.24091L20.718 5.27549H20.7175V5.27584L18.9257 6.31046H18.9246L15.6145 8.22298H15.6132L12.0281 10.2938L10.51 11.1692V11.1706L8.717 12.2052L8.71684 12.2051L5.13045 14.2747L3.61428 15.1496V11.3278L5.29087 10.3599L5.40532 10.2939L5.71146 10.1169L6.92365 9.41606L8.71684 8.38203L10.51 7.34697V7.34581L12.3032 6.31066L15.615 4.39895L19.2002 2.32877L19.1999 2.32868L19.2007 2.32818L16.3462 0.679919L15.615 0.257446H15.6145H15.6138H15.6132L12.0268 2.32818L10.51 3.20623L6.92359 5.27551V5.27567L5.13044 6.31048L3.6136 7.18571V7.18698L1.81903 8.223L0.0270996 9.25659V11.3285V13.398V15.1526V17.2222V19.2928V21.3625V23.1144V25.1839V27.2586L1.81903 28.2919L3.61312 29.3277V29.3281H3.61428V19.2913L5.40532 18.2568L6.92359 17.3803H6.92365L6.92366 17.3811Z"
						fill="#186597"
					/>
					<path
						opacity="0.35"
						d="M8.71672 28.1325H8.71561L6.92352 29.1677V31.2379V31.239L8.71561 32.2755H8.71672L10.5099 33.3093V33.3082V31.2368V29.1677V29.1665V27.0964L8.71788 28.1314L8.71672 28.1325Z"
						fill="#54565A"
					/>
					<path
						d="M29.4087 8.22285L27.6155 9.25797L25.8222 10.2942L24.3037 11.1709L22.5118 12.2059H22.5106L20.7174 13.2399V13.241L18.9253 14.275H18.9242L17.408 15.1518L15.6149 16.1869L13.8216 17.2219L12.0272 18.257L10.5099 19.1326L8.71561 20.1688L6.92352 21.205V23.2753V23.2763V25.3465L8.71672 24.3103L10.5099 23.2753L12.3031 22.2401L13.8216 21.3634V23.1167V25.1869V27.2571V29.3263V31.0797V33.1498V35.2212L15.6149 36.2574L17.408 35.2212V33.1498V31.0797V29.3285V27.2571V27.256V25.1869V23.1167V21.3647V19.2921L19.2 18.257L20.7174 17.3804L20.7185 17.3793L22.5106 16.3453L24.3037 15.3101L26.097 14.275L27.6155 13.3984L29.4087 12.3656L31.2019 11.3282V9.25904V9.25797L29.4087 8.22285Z"
						fill="#FF9E18"
					/>
					<path
						opacity="0.35"
						d="M27.6156 17.2239L25.8224 18.2566L24.3039 19.1344L22.512 20.1685L20.7187 21.2036V21.2046H20.7176V21.2058V23.2749V25.3462H20.7187L22.5107 24.31H22.512L24.3039 23.276V23.2749L26.0971 22.2398L27.6156 21.362L29.4089 20.328L31.2021 19.2917V17.2239V15.1525L29.4089 16.1865L27.6156 17.2239Z"
						fill="#54565A"
					/>
					<path
						opacity="0.35"
						d="M29.4089 24.1506H29.4077L27.6156 25.1856L25.8235 26.2219H25.8213L24.3039 27.0976L22.5107 28.1325V28.1338L20.7187 29.1689H20.7176V29.1711V31.2367V33.3092H20.7187L21.6158 32.7928L22.5131 32.2754L24.3039 31.2389L25.2012 30.7215L26.0971 30.2052H26.0983V30.204L27.6156 29.3283L28.1173 29.0389L28.5127 28.8109L29.4089 28.2932L29.9105 28.0038L30.306 27.7758L31.2021 27.2581V27.257V25.1856V23.1155L29.4089 24.1495L29.4089 24.1506Z"
						fill="#54565A"
					/>
				</g>
				<defs>
					<clipPath id="clip0_222_403">
						<rect
							width="31.175"
							height="36"
							fill="white"
							transform="translate(0.0270996 0.257446)"
						/>
					</clipPath>
				</defs>
			</svg>
		</SvgIcon>
	);
};
