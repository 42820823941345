import { useQuery } from '@apollo/client';
import { client } from 'utils/apollo';
import { EMBLEM_FIELDS } from 'components/ui/emblem/fragments';
import { useToast } from 'components/ui/toast';
import gql from 'graphql-tag';
import {
	ConnectionPageType,
	ConnectionSortBy,
	Emblem,
	Query,
	SortDirection,
} from 'middleware-types';

/**
 * Hook for getting org connections, inbound requests, or outbound requests
 */
export const useOrgConnectionsOrRequestsQuery = (
	pageType: ConnectionPageType,
	orgId?: string,
	markInboundAsSeen?: boolean,
	searchFor?: string
) => {
	const toast = useToast();
	const { data, loading, error } = useQuery<Pick<Query, 'organizationConnectionsOrRequests'>>(
		ORG_CONNECTIONS_OR_REQUESTS,
		{
			variables: {
				organizationId: orgId!,
				pageSize: 999,
				offset: 0,
				sortedBy: ConnectionSortBy.StateChangedDateTimeUtc,
				sortDirection: SortDirection.Ascending,
				searchFor: searchFor || '',
				pageType,
				markInboundAsSeen,
			},
			fetchPolicy: 'cache-and-network',
			onCompleted: () => {
				client.cache.evict({
					id: 'ROOT_QUERY',
					fieldName: 'getOrgBadgeCounts',
				});

				client.cache.gc();
			},
			skip: !orgId,
			onError: () => {
				toast.push(
					`An error occurred while retrieving connections or connection requests. Please try again later or contact Support.`,
					{
						variant: 'error',
					}
				);
			},
		}
	);

	const connectionsOrRequests =
		data?.organizationConnectionsOrRequests?.items.map((e) => {
			let otherUserEmblem: Emblem | undefined;
			let otherOrgEmblem: Emblem | undefined;

			// There is a requesting organization and it's not the current org
			if (e.requestingOrganizationId && e.requestingOrganizationId !== orgId)
				otherOrgEmblem = e.requestingOrganizationEmblem!;
			// There is a requesting user (we are not a user)
			else if (e.requestingUserId) otherUserEmblem = e.requestingUserEmblem!;
			// There is a targeted organization and it's not the current org
			else if (e.targetOrganizationId && e.targetOrganizationId !== orgId)
				otherOrgEmblem = e.targetOrganizationEmblem!;
			// There is a targeted user (we are not a user)
			else if (e.targetUserId) otherUserEmblem = e.targetUserEmblem!;

			return {
				orgId,
				connectionId: e.id,
				otherUserEmblem: otherUserEmblem,
				otherOrganizationEmblem: otherOrgEmblem,
			};
		}) ?? [];

	return {
		connectionsOrRequests,
		count: data?.organizationConnectionsOrRequests?.totalCount ?? 0,
		loading,
		error,
	};
};

// Query for getting the user's inbound connections or connection requests. Also marks those requests as seen.
export const ORG_CONNECTIONS_OR_REQUESTS = gql`
	${EMBLEM_FIELDS}
	query organizationConnectionsOrRequests(
		$organizationId: ID!
		$pageSize: Float!
		$offset: Float!
		$sortedBy: ConnectionSortBy!
		$sortDirection: SortDirection!
		$searchFor: String!
		$pageType: ConnectionPageType!
		$markInboundAsSeen: Boolean
	) {
		organizationConnectionsOrRequests(
			organizationId: $organizationId
			pageSize: $pageSize
			offset: $offset
			sortedBy: $sortedBy
			sortDirection: $sortDirection
			searchFor: $searchFor
			pageType: $pageType
			markInboundAsSeen: $markInboundAsSeen
		) {
			items {
				id
				state
				requestingUserId
				requestingUserEmblem {
					...EmblemFields
				}
				requestingOrganizationId
				requestingOrganizationEmblem {
					...EmblemFields
				}
				targetUserId
				targetUserEmblem {
					...EmblemFields
				}
				targetOrganizationId
				targetOrganizationEmblem {
					...EmblemFields
				}
			}
			totalCount
		}
	}
`;
