import { EmailOutlined, HomeOutlined, PhoneOutlined } from '@mui/icons-material';
import { Grid, Stack, Typography } from '@mui/material';
import { AddressText, PhoneNumberText } from 'components/ui/fields';
import { AccountEmergencyContact } from 'middleware-types';

export type EmergencyContactReadProps = { emergencyContact?: AccountEmergencyContact | null };

/**
 * EmergencyContactRead - A read only view of the emergency contact
 * It hides content that does not have any values
 *
 * @param {({ emergencyContact?: EmergencyContact | null })} props
 * @return {*}
 */
const EmergencyContactRead = (props: EmergencyContactReadProps) => {
	if (!props.emergencyContact) {
		return (
			<Stack alignItems="center" spacing={2} data-test="empty-state" pb="1rem">
				<img
					style={{ width: '5rem', height: '5rem' }}
					src="/img/emergency-contact.svg"
					alt="Emergency Contact Logo"
				/>
				<Typography variant="h3">
					You haven&apos;t added an emergency contact yet.
				</Typography>
			</Stack>
		);
	}

	return (
		<Grid container alignItems="stretch" justifyContent="center">
			<Grid item sm={12} md={4} paddingBottom={{ xs: 2, md: 'unset' }}>
				<Stack alignItems="center" spacing={0.5}>
					<img
						style={{ width: '10rem', height: '10rem' }}
						src="/img/star.svg"
						alt="Emergency Contact Logo"
					/>
					<Typography variant="h3" data-test="name" pt="1rem">
						{props.emergencyContact.firstName} {props.emergencyContact.lastName}
					</Typography>
					{props.emergencyContact.relationship !== 'Other' && (
						<span data-test="relationship">{props.emergencyContact.relationship}</span>
					)}
				</Stack>
			</Grid>
			<Grid container item sm={12} md={8} alignContent="flex-start" spacing={2}>
				<Grid container item>
					<Grid item sm={1} pr={{ xs: 1, sm: 'unset' }}>
						<PhoneOutlined fontSize="small" />
					</Grid>
					<Grid item sm={3}>
						<Typography variant="h5">Cell Phone</Typography>
					</Grid>
					<Grid item xs={12} sm={8}>
						<PhoneNumberText
							number={props.emergencyContact.cellPhone}
							data-test="cellPhone"
						/>
					</Grid>

					{props.emergencyContact.otherPhone?.number && (
						<>
							<Grid item sm={1} pr={{ xs: 1, sm: 'unset' }}></Grid>
							<Grid item sm={3} pl={{ xs: 2.5, sm: 'unset' }}>
								<Typography variant="h5">Other Phone</Typography>
							</Grid>
							<Grid item xs={12} sm={8}>
								<PhoneNumberText
									number={props.emergencyContact.otherPhone}
									data-test="otherPhone"
								/>
							</Grid>
						</>
					)}
				</Grid>

				{props.emergencyContact.email && (
					<Grid container item>
						<Grid item sm={1} pr={{ xs: 1, sm: 'unset' }}>
							<EmailOutlined fontSize="small" />
						</Grid>
						<Grid item sm={3}>
							<Typography variant="h5">E-mail</Typography>
						</Grid>
						<Grid item xs={12} sm={8} component="div">
							<span data-test="email">{props.emergencyContact.email}</span>
						</Grid>
					</Grid>
				)}

				<Grid container item>
					<Grid item sm={1} pr={{ xs: 1, sm: 'unset' }}>
						<HomeOutlined fontSize="small" />
					</Grid>
					<Grid sm={3}>
						<Typography variant="h5">Address</Typography>
					</Grid>
					<Grid item xs={12} sm={8}>
						<AddressText {...props.emergencyContact?.address} />
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default EmergencyContactRead;
