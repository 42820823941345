import { Link, MoreHoriz } from '@mui/icons-material';
import {
	Avatar,
	Box,
	Card,
	CardActionArea,
	CardContent,
	IconButton,
	ListItemText,
	Menu,
	MenuItem,
	Stack,
	Tooltip,
	Typography,
} from '@mui/material';
import { useModal } from 'components/ui/modal';
import { useState } from 'react';
import { useIsMobile } from 'utils/useScreenSize';
import { AddUpdateQuickLinkModal, DeleteQuickLinkModal } from './quickLinkModals';
import { ExtendedQuickLink, QuickLinksMethods } from './quickLinks';
import { useQuickLinkIconUrl } from './sharedHooks';

interface QuickLinkProps {
	methods: QuickLinksMethods;
	quickLink: ExtendedQuickLink;
	canEdit: boolean;
	isForSideRail?: boolean;
}

export const QuickLinkCard = ({ methods, quickLink, canEdit, isForSideRail }: QuickLinkProps) => {
	const isMobile = useIsMobile();

	const [hovered, setHovered] = useState(false);
	const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);

	const iconUrl = useQuickLinkIconUrl(quickLink);

	const { showModal } = useModal();

	const isInternalLink =
		new URL(quickLink.url).hostname.replace('www.', '') ===
		window.location.hostname.replace('www.', '');

	const src =
		quickLink.presetIcon ??
		iconUrl ??
		(isInternalLink
			? '/favicon.ico'
			: `https://www.google.com/s2/favicons?domain=${quickLink.url}&sz=64`);

	if (isForSideRail) {
		return (
			<Card sx={{ borderRadius: 0, boxShadow: 0, width: '49px', height: '49px' }}>
				<CardActionArea component="a" href={quickLink.url} target="_blank">
					<Tooltip title={quickLink.title} leaveDelay={0} placement="right">
						<Avatar
							variant="square"
							sx={{
								backgroundColor: 'transparent',
								width: '100%',
								height: '100%',
								padding: 1,
							}}
							src={src}>
							<Link color="primary" fontSize="large" />
						</Avatar>
					</Tooltip>
				</CardActionArea>
			</Card>
		);
	}

	return (
		<Card
			sx={
				quickLink.isPresetLink
					? {
							borderWidth: '0 0 0 4px',
							borderColor: 'secondary.main',
							borderStyle: 'solid',
					  }
					: undefined
			}>
			<CardActionArea
				onMouseEnter={() => setHovered(true)}
				onMouseLeave={() => setHovered(false)}
				component="a"
				href={quickLink.url}
				target="_blank"
				sx={{ height: '100%' }}>
				<CardContent
					sx={{
						height: '100%',
						paddingLeft: quickLink.isPresetLink ? '12px' : undefined,
					}}>
					<Stack direction="row" spacing={1} alignItems="center" height="100%">
						<Avatar
							variant="square"
							src={src}
							sx={{ height: 48, width: 48, backgroundColor: 'transparent' }}>
							<Link color="primary" fontSize="large" />
						</Avatar>
						<Box flex={1} overflow="hidden">
							<Typography variant="h4" noWrap>
								{quickLink.title}
							</Typography>
							<Typography variant="subtitle2" color="GrayText" noWrap>
								{quickLink.url.replace(/^https?:\/\/(www\.)?/, '')}
							</Typography>
						</Box>
						{(hovered || isMobile) && canEdit && !quickLink.isPresetLink && (
							<>
								<IconButton
									onMouseDown={(e) => e.stopPropagation()}
									onTouchStart={(e) => e.stopPropagation()}
									onClick={(e) => {
										e.preventDefault();
										e.stopPropagation();
										setMenuAnchor(e.currentTarget);
									}}>
									<MoreHoriz />
								</IconButton>
								<Menu
									open={Boolean(menuAnchor)}
									anchorEl={menuAnchor}
									onMouseDown={(e) => e.stopPropagation()}
									onTouchStart={(e) => e.stopPropagation()}
									onClick={() => setMenuAnchor(null)}
									onClose={() => {
										setMenuAnchor(null);
										setHovered(false);
									}}>
									<MenuItem
										onClick={() =>
											showModal({
												title: 'Edit Link',
												content: (
													<AddUpdateQuickLinkModal
														methods={methods}
														quickLink={quickLink}
													/>
												),
											})
										}>
										<ListItemText primary="Edit Link" />
									</MenuItem>
									<MenuItem
										onClick={() =>
											showModal({
												title: 'Are you sure?',
												content: (
													<DeleteQuickLinkModal
														methods={methods}
														quickLinkId={quickLink.id}
													/>
												),
											})
										}>
										<ListItemText primary="Delete Link" />
									</MenuItem>
								</Menu>
							</>
						)}
					</Stack>
				</CardContent>
			</CardActionArea>
		</Card>
	);
};
