import { Box, Grid, MenuItem, TextField as MuiTextField, Typography } from '@mui/material';
import { PhoneNumberField, SelectField, TextField } from 'components/ui/fields';
import { EmployeeCount, Industry, OrganizationProfile } from 'middleware-types';
import { employeeCountToString, industryToString } from './enum-display';

interface OrgAboutFormProps {
	profile: OrganizationProfile;
}

export const OrgAboutForm = ({ profile }: OrgAboutFormProps) => {
	const specialties = profile.businessLines?.filter((line) => line.specialty) ?? [];
	const specialtiesText = specialties.map((s) => s.line ?? '').join(', ');
	return (
		<Box p={2.5}>
			<Grid container rowSpacing={0.5} columnSpacing={1.5}>
				<Grid item xs={12} sm={6}>
					<TextField name="websiteUrl" label="Website" />
				</Grid>
				<Grid item xs={12} sm={6}>
					<PhoneNumberField label="Phone" name="displayPhoneNumber" />
				</Grid>
				<Grid item xs={12} sm={6}>
					<SelectField name="industry" label="Industry">
						{Object.values(Industry).map((option) => (
							<MenuItem key={option} value={option}>
								{industryToString(option)}
							</MenuItem>
						))}
					</SelectField>
				</Grid>
				<Grid item xs={12} sm={6}>
					<SelectField name="numberOfEmployees" label="Company Size">
						{Object.values(EmployeeCount).map((option) => (
							<MenuItem key={option} value={option}>
								{employeeCountToString(option)}
							</MenuItem>
						))}
					</SelectField>
				</Grid>
				<Grid item xs={12}>
					<TextField name="yearFounded" label="Founding Year" type="number" />
				</Grid>
				<Grid item xs={12}>
					<MuiTextField value={specialtiesText} label="Specialties" disabled />
					<Typography variant="caption">
						This is based on your lines of business. Edit your specialties in the Lines
						of Business tab.
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<TextField name="about" label="About" multiline />
				</Grid>
			</Grid>
		</Box>
	);
};
