import {
	AlternateEmail,
	BadgeOutlined,
	HowToRegOutlined,
	InfoOutlined,
	Star,
	WatchLater,
	WorkOutline,
} from '@mui/icons-material';
import { useWorkTypesQuery } from 'components/pages/user/profile/header/hooks';
import { AccountCancelOutlined, AccountQuestionOutlined } from 'components/ui/icons';
import { format } from 'date-fns';
import { Emblem } from 'middleware-types';
import { Permission } from 'utils/permissions';
import { useCountries } from 'utils/useCountries';
import { useSiteUser } from 'utils/useSiteUser';
import { useUserDropdownInfo } from '../hooks';
import { DropdownItem, EmblemDropdownContent } from './emblem-dropdown-content';

export const UserEmblemDropdown = ({ emblem }: { emblem: Emblem }) => {
	const { info, loading: infoLoading } = !emblem.deactivated
		? useUserDropdownInfo(emblem.id)
		: { info: null, loading: false };
	const { workTypes, loading: workTypesLoading } = useWorkTypesQuery();
	const { countriesLookupMapById, loading: countriesLoading } = useCountries();
	const loading = infoLoading || workTypesLoading || countriesLoading;

	const subtext =
		emblem.extraData?.companyName && emblem.extraData?.companyTitle
			? `${emblem.extraData.companyTitle} at ${emblem.extraData.companyName}`
			: undefined;

	const items: DropdownItem[] = [
		{
			icon: <WatchLater color="warning" fontSize="small" />,
			label: 'Temporary Location',
			value: info?.displayTemporaryLocation
				? `${info.displayTemporaryLocation.displayLocation} (until ${format(
						new Date(info.displayTemporaryLocation.endDate),
						'M/d/yyyy'
				  )})`
				: undefined,
		},
		{
			icon: <Star color="primary" fontSize="small" />,
			label: 'Primary Location',
			value: info?.displayLocation,
		},
		{
			icon: <WorkOutline fontSize="small" />,
			label: 'Lines of Work',
			value: info?.workCodes.map((code) => workTypes?.get(code)).join(', '),
		},
		{
			icon: <BadgeOutlined fontSize="small" />,
			label: 'Licensing',
			value: info?.adjusterLicenses
				?.map(
					(summary) =>
						`${countriesLookupMapById.get(summary.countryId)?.name} (${
							summary.numLicenses
						} ${summary.numLicenses === 1 ? 'license' : 'licenses'})`
				)
				.join(', '),
		},
		{
			icon: <InfoOutlined fontSize="small" />,
			label: 'About',
			value: info?.about,
		},
		{
			icon: <AlternateEmail fontSize="small" />,
			label: 'Handle',
			value: emblem.handle,
		},
	];

	const { hasPermission } = useSiteUser();
	const checkPermissions = () => hasPermission(Permission.SocialUser_Profile_R);

	return (
		<EmblemDropdownContent
			emblem={emblem}
			subtext={subtext}
			items={items}
			loading={loading}
			profileUrl={`/${emblem.handle}`}
			checkSiteUserProfilePermissions={checkPermissions}
			connectionIcons={{
				connected: HowToRegOutlined,
				notConnected: AccountCancelOutlined,
				incomingRequest: AccountQuestionOutlined,
			}}
		/>
	);
};
