import { Container, Stack } from '@mui/material';
import { OrgProfileOrgConnectionsBar } from 'components/pages/network/org/OrgConnections/orgConnectionsBarWrappers';
import { useOrgId } from 'components/pages/org/outlet';
import { Loading } from 'components/ui/loading';
import { PageContent, PageTitle } from 'components/ui/page';
import { useState } from 'react';
import { PageError } from 'utils/errors';
import { Permission } from 'utils/permissions';
import { useAssociateUser } from 'utils/useAssociateUser';
import { useSiteUser } from 'utils/useSiteUser';
import { OrgAboutCard } from './about/about-card';
import { OrgHeaderCard } from './header/header-card';
import { useOrgProfile } from './hooks';
import { OrgLinesOfBusinessCard } from './lines-of-business.tsx/lines-of-business-card';
import { TeamCard } from './team/team-card';

export const OrgProfilePage = () => {
	const orgId = useOrgId();
	const { profile, loading, error } = useOrgProfile(orgId);
	const { hasPermission: hasAssociateUserPermission } = useAssociateUser(orgId);
	const { hasPermission: hasSiteUserPermission } = useSiteUser();
	const canEdit =
		hasAssociateUserPermission(Permission.Org_Profile_U) ||
		hasSiteUserPermission(Permission.Site_OrgProfile_U);

	const [selectedTab, setSelectedTab] = useState(0);

	if (error?.message == '401: Unauthorized') throw PageError;
	if (loading || !profile) return <Loading />;

	return (
		<>
			<PageTitle title="Profile" />
			<PageContent mobileNoPadding>
				<Container maxWidth="md" disableGutters>
					<Stack spacing={{ xs: 2, sm: 3 }}>
						<OrgHeaderCard
							profile={profile}
							canEdit={canEdit}
							selectedTab={selectedTab}
							setSelectedTab={setSelectedTab}
						/>
						{<OrgProfileOrgConnectionsBar orgId={orgId} />}
						{selectedTab === 0 && <OrgAboutCard profile={profile} canEdit={canEdit} />}
						{selectedTab === 1 && <TeamCard orgId={orgId} canEdit={canEdit} />}
						{selectedTab === 2 && (
							<OrgLinesOfBusinessCard profile={profile} canEdit={canEdit} />
						)}
					</Stack>
				</Container>
			</PageContent>
		</>
	);
};
