import {
	AddCircleOutlined,
	BadgeOutlined,
	CakeOutlined,
	DeleteOutlined,
	EditOutlined,
	ErrorOutlined,
	MoreHorizOutlined,
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
	Avatar,
	Box,
	Button,
	Card,
	CardContent,
	CardHeader,
	FormControlLabel,
	Grid,
	IconButton,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	Skeleton,
	Stack,
	Tab,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Tabs,
	Typography,
} from '@mui/material';
import { Alert } from 'components/ui/alert';
// TODO: When testable, update deprecated MutationErrorText with ResponseHasErrors and HandleNoResponse pattern
import clsx from 'clsx';
import {
	useStateLicenseClassesQuery,
	useStateLinesOfAuthorityQuery,
	useUsaAdjusterLicensingManualMutation,
	useUsaLicensingQuery,
} from 'components/pages/user/profile/licensing/usa/hooks';
import { EmptyStateAvatar } from 'components/ui/empty-state-avatar';
import {
	DatePickerField,
	NumberField,
	SelectField,
	SwitchField,
	TextField,
} from 'components/ui/fields';
import { Flag } from 'components/ui/flag';
import {
	ConfirmModalContent,
	ModalActionButton,
	ModalActions,
	ModalContent,
	useModal,
} from 'components/ui/modal';
import { PageContent, PageTitle } from 'components/ui/page';
import { useToast } from 'components/ui/toast';
import { format, isValid } from 'date-fns';
import { Formik } from 'formik';
import {
	AddressFieldLookup,
	UsaAdjusterLicensingOutput,
	UsaStateAdjusterAppointmentOutput,
	UsaStateAdjusterLicenseOutput,
} from 'middleware-types';
import { useEffect, useState } from 'react';
import { useUsStates } from 'utils/useCountries';
import { UserProfileAspectType, usePrivacy } from 'utils/usePrivacy';
import { useValidation } from 'utils/useValidation';
import { zoneDateOnly } from 'utils/utils';
import * as Yup from 'yup';

/**
 * UsaLicenseInformationEdit - Edit view of the top card on USA Licenses.
 *
 * @param {({
 *     userId: string;
 *     licensing: UsaAdjusterLicensingOutput | undefined;
 *     onFinishedEdit: () => void;
 * })} props
 * @return {*}
 */
const UsaLicenseInformationEdit = (props: {
	userId: string;
	licensing: UsaAdjusterLicensingOutput | undefined;
	onFinishedEdit: () => void;
}) => {
	const validation = useValidation('UsaAdjusterLicensing');
	const mutation = useUsaAdjusterLicensingManualMutation(props.userId);
	const toast = useToast();

	type FormValues = {
		firstName: string;
		middleName: string;
		lastName: string;
		suffix: string;
		birthDate: Date | null;
		npn: number | undefined;
	};

	const validationSchema = Yup.object().shape({
		birthDate: Yup.date().nullable().typeError('Invalid date'),
	});

	const onSubmit = (values: FormValues) => {
		mutation
			.updateLicenses({
				...values,
				npn: values.npn ?? 0,
				birthDate: values?.birthDate
					? format(values?.birthDate, 'yyyy-MM-dd')
					: '0001-01-01',
				statesLicensing: props.licensing?.statesLicensing ?? [],
			})
			.then((success) => {
				if (!success) return;
				toast.push('Successfully updated licensing data.', {
					variant: 'success',
				});
				props.onFinishedEdit();
			});
	};

	return (
		<Card>
			<Formik<FormValues>
				initialValues={{
					firstName: props.licensing?.firstName ?? '',
					middleName: props.licensing?.middleName ?? '',
					lastName: props.licensing?.lastName ?? '',
					suffix: props.licensing?.suffix ?? '',
					birthDate: props.licensing?.birthDate
						? zoneDateOnly(props.licensing?.birthDate)
						: null,
					npn: props.licensing?.npn,
				}}
				validationSchema={validation.schema && validationSchema}
				onSubmit={onSubmit}>
				{(fprops) => (
					<>
						<CardHeader
							title={
								<Typography
									variant="h2"
									height="2rem"
									marginBottom={{ xs: 2, md: 'unset' }}>
									United States Licensing Information
								</Typography>
							}
							action={
								<Stack
									direction="row"
									spacing={1}
									style={{ marginRight: '0.5rem' }}>
									<Button
										variant="outlined"
										disabled={fprops.isSubmitting}
										onClick={props.onFinishedEdit}>
										Cancel
									</Button>
									<LoadingButton
										color="primary"
										variant="contained"
										loading={fprops.isSubmitting}
										disabled={
											validation.loading || !fprops.isValid || !fprops.dirty
										}
										onClick={() => fprops.submitForm()}>
										Save
									</LoadingButton>
								</Stack>
							}
						/>
						<CardContent>
							{mutation.error && <Alert error={mutation.error} />}
							<Grid container spacing={1} columnSpacing={2}>
								<Grid xs={12} md={3} item>
									{validation.loading ? (
										<Skeleton
											variant="rectangular"
											height={30}
											width={'100%'}
										/>
									) : (
										<TextField
											label="First Name"
											required
											name="firstName"
											fullWidth
										/>
									)}
								</Grid>
								<Grid xs={12} md={3} item>
									{validation.loading ? (
										<Skeleton
											variant="rectangular"
											height={30}
											width={'100%'}
										/>
									) : (
										<TextField
											label="Middle Name"
											name="middleName"
											fullWidth
										/>
									)}
								</Grid>
								<Grid xs={12} md={3} item>
									{validation.loading ? (
										<Skeleton
											variant="rectangular"
											height={30}
											width={'100%'}
										/>
									) : (
										<TextField
											label="Last Name"
											required
											name="lastName"
											fullWidth
										/>
									)}
								</Grid>
								<Grid xs={12} md={3} item>
									{validation.loading ? (
										<Skeleton
											variant="rectangular"
											height={30}
											width={'100%'}
										/>
									) : (
										<TextField label="Suffix" name="suffix" fullWidth />
									)}
								</Grid>
								<Grid xs={12} md={12} item>
									{validation.loading ? (
										<Skeleton
											variant="rectangular"
											height={30}
											width={'100%'}
										/>
									) : (
										<DatePickerField label="Date of Birth" name="birthDate" />
									)}
								</Grid>
								<Grid xs={12} md={12} item>
									{validation.loading ? (
										<Skeleton
											variant="rectangular"
											height={30}
											width={'100%'}
										/>
									) : (
										<NumberField
											required={true}
											name="npn"
											label="National Producer Number (NPN)"
											positiveNumber={true}
											integerOnly={true}
											maxLength={9}
										/>
									)}
								</Grid>
							</Grid>
						</CardContent>
					</>
				)}
			</Formik>
		</Card>
	);
};

/**
 * UsaLicenseInformationRead - Read view of the top US card.
 *
 * @param {({
 *     userId: string;
 *     licensing: UsaAdjusterLicensingOutput
 * })} props
 * @return {*}
 */
const UsaLicenseInformationRead = (props: {
	userId: string;
	licensing: UsaAdjusterLicensingOutput | undefined;
}) => {
	const [isEditing, setIsEditing] = useState<boolean>(false);
	const { hasAccess } = usePrivacy(props.userId);
	const hasAccessAlterLicensing = hasAccess(UserProfileAspectType.AlterLicensing);
	if (isEditing)
		return (
			<UsaLicenseInformationEdit
				userId={props.userId}
				licensing={props.licensing}
				onFinishedEdit={() => setIsEditing(false)}
			/>
		);

	return (
		<Card>
			<CardHeader
				title={
					<Typography variant="h2" height="2rem" marginBottom={{ xs: 2, md: 'unset' }}>
						United States Licensing Information
					</Typography>
				}
				action={
					hasAccessAlterLicensing && (
						<IconButton onClick={() => setIsEditing(true)}>
							<EditOutlined />
						</IconButton>
					)
				}
			/>
			<CardContent>
				<Grid
					container
					alignItems="center"
					justifyContent={{ xs: 'center', md: 'unset' }}
					spacing={1}>
					<Grid item sm={12} md={3}>
						<Stack alignItems="center" spacing={2}>
							<Avatar
								variant="rounded"
								style={{ width: '100px', height: '100px', fontSize: '4rem' }}>
								<Flag code="US" className="h-full w-full bg-cover" />
							</Avatar>
							<Typography variant="h2">
								{props.licensing?.firstName} {props.licensing?.lastName}{' '}
								{props.licensing?.suffix}
							</Typography>
						</Stack>
					</Grid>
					<Grid container item sm={12} md={9} alignContent="flex-start" spacing={2}>
						<Grid container item>
							<Grid item xs={12} md={4}>
								<Stack direction="row" spacing={2}>
									<BadgeOutlined fontSize="small" color="primary" />
									<strong>NPN</strong>
								</Stack>
							</Grid>
							<Grid item xs={12} md={8}>
								{props.licensing?.npn}
							</Grid>
						</Grid>
						<Grid container item>
							<Grid item xs={12} md={4}>
								<Stack direction="row" spacing={2}>
									<CakeOutlined fontSize="small" color="primary" />
									<strong>Birthday</strong>
								</Stack>
							</Grid>
							<Grid item xs={12} md={8}>
								{props.licensing?.birthDate &&
								isValid(new Date(props.licensing?.birthDate))
									? format(zoneDateOnly(props.licensing?.birthDate), 'MM/dd/yyyy')
									: ''}
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
};

/**
 * Gets and formats the output for the license class
 *
 * @param {{ id: string; stateName: string }} props
 * @return {*}
 */
const LicenseClass = (props: { id: string; stateName: string }) => {
	const { licenseClasses } = useStateLicenseClassesQuery(props.stateName);
	const lc = licenseClasses.find((s) => s.id === props.id);
	return lc ? <>{`${lc.name} (${lc.code})`}</> : <Skeleton width="50%" />;
};

/**
 * Gets and formats the output for the line of authority
 *
 * @param {{ id: string; stateName: string }} props
 * @return {*}
 */
const LineOfAuthority = (props: { id: string; stateName: string }) => {
	const { linesOfAuthority } = useStateLinesOfAuthorityQuery(props.stateName);
	const loa = linesOfAuthority.find((loa) => loa.id === props.id);
	return loa ? <>{`${loa.name} (${loa.code})`}</> : <Skeleton width="50%" />;
};

/**
 * AddOrUpdateAppointmentModal - Adds or updates an adjuster appointment for a given state.
 *
 * @param {{
 * 	userId: string;
 * 	licensing: UsaAdjusterLicensing;
 * 	state: AddressFieldLookup;
 * 	appointmentIndex?: number;
 * }} props
 * @return {*}
 */
const AddOrUpdateAppointmentModal = (props: {
	userId: string;
	licensing: UsaAdjusterLicensingOutput;
	state: AddressFieldLookup;
	appointmentIndex?: number;
}) => {
	const toast = useToast();
	const { closeModal } = useModal();
	const { error, updateLicenses } = useUsaAdjusterLicensingManualMutation(props.userId);
	const { linesOfAuthority } = useStateLinesOfAuthorityQuery(props.state.value as string);
	const selectedAppointment = props.licensing.statesLicensing
		?.find((sl) => sl.stateAddressFieldLookupId === props.state.id)
		?.stateAppointments?.find((l, i) => i === props.appointmentIndex);

	type FormValues = {
		companyName: string;
		lineOfAuthorityId?: string | null | undefined;
		renewalDate: Date | null;
		status: 'Terminated' | 'Appointed' | undefined;
	};

	const validationSchema = Yup.object().shape({
		renewalDate: Yup.date().nullable().typeError('Invalid date'),
	});

	const onSubmit = async (values: FormValues) => {
		await updateLicenses({
			...props.licensing,
			firstName: props.licensing.firstName ?? '',
			lastName: props.licensing.lastName ?? '',
			statesLicensing: props.licensing.statesLicensing.map((sl) => {
				if (sl.stateAddressFieldLookupId !== props.state.id) return sl;
				const sappts = sl.stateAppointments.filter((_, i) => i !== props.appointmentIndex);
				const stateAppointments = [
					// part of the array before the specified index
					...sappts.slice(0, props.appointmentIndex ?? 0),
					{
						...values,
						lineOfAuthorityId: values.lineOfAuthorityId ?? undefined,
						status: values.status ?? 'Appointed',
						renewalDate: values.renewalDate
							? format(values.renewalDate, 'yyyy-MM-dd')
							: null,
					},
					// part of the array after the specified index
					...sappts.slice(props.appointmentIndex ?? 0),
				];
				return {
					...sl,
					stateAppointments,
				};
			}),
			// birthDate that is saved in the licensing is actually a string.
			birthDate: props.licensing?.birthDate,
		}).then((success) => {
			if (!success) return;
			toast.push('Successfully updated licensing data.', {
				variant: 'success',
			});
			closeModal();
		});
	};

	return (
		<Formik<FormValues>
			initialValues={{
				companyName: selectedAppointment?.companyName ?? '',
				lineOfAuthorityId: selectedAppointment?.lineOfAuthorityId ?? '',
				renewalDate: selectedAppointment?.renewalDate
					? zoneDateOnly(selectedAppointment.renewalDate)
					: null,
				status: (selectedAppointment?.status ?? undefined) as
					| 'Terminated'
					| 'Appointed'
					| undefined,
			}}
			validationSchema={validationSchema}
			onSubmit={onSubmit}>
			{(formik) => (
				<>
					<ModalContent>
						{error && <Alert error={error} />}
						<Grid container spacing={3}>
							<Grid xs={12} md={6} item>
								<TextField
									label="Company Name"
									required
									type="text"
									name="companyName"
								/>
							</Grid>
							<Grid xs={12} md={6} item>
								<SelectField name="lineOfAuthorityId" label="Line Of Authority">
									{linesOfAuthority &&
										linesOfAuthority.map((item) => (
											<MenuItem key={item.id} value={item.id}>
												{item.name} ({item.code})
											</MenuItem>
										))}
								</SelectField>
							</Grid>
							<Grid xs={12} md={6} item>
								<SelectField name="status" label="Status" required>
									<MenuItem value="Appointed">Appointed</MenuItem>
									<MenuItem value="Terminated">Terminated</MenuItem>
								</SelectField>
							</Grid>
							<Grid xs={12} md={6} item>
								<DatePickerField label="Renewal Date" name="renewalDate" />
							</Grid>
						</Grid>
					</ModalContent>
					<ModalActions>
						<ModalActionButton variant="outlined">Cancel</ModalActionButton>
						<LoadingButton
							loading={formik.isSubmitting}
							disabled={!formik.dirty || !formik.isValid}
							variant="contained"
							onClick={formik.submitForm}
							color="primary">
							{props.appointmentIndex !== undefined
								? 'Update Appointment'
								: 'Add Appointment'}
						</LoadingButton>
					</ModalActions>
				</>
			)}
		</Formik>
	);
};

/**
 * AddOrUpdateLicenseModal - Update License modal
 *
 * @param {{ userId: string; licensing: UsaAdjusterLicensing; state: AddressFieldLookup; licenseIndex?: number }} props
 * @return {*}
 */
const AddOrUpdateLicenseModal = (props: {
	userId: string;
	licensing: UsaAdjusterLicensingOutput;
	state: AddressFieldLookup;
	licenseIndex?: number;
}) => {
	const toast = useToast();
	const { closeModal } = useModal();
	const { licenseClasses } = useStateLicenseClassesQuery(props.state.value as string);
	const { error, updateLicenses } = useUsaAdjusterLicensingManualMutation(props.userId);

	const selectedLicense = props.licensing.statesLicensing
		?.find((sl) => sl.stateAddressFieldLookupId === props.state.id)
		?.stateLicenses?.find((l, i) => i === props.licenseIndex);

	type FormValues = {
		number: string;
		licenseClassId: string;
		originalIssueDate: Date | null;
		expirationDate: Date | null;
		active: boolean;
	};

	const validationSchema = Yup.object().shape({
		originalIssueDate: Yup.date()
			.nullable()
			.typeError('Please enter a valid date in MM/DD/YYYY format')
			.test('expirationDate', 'Date is after expiration', (val, context) => {
				if (val && context.parent.expirationDate && val > context.parent.expirationDate) {
					return false;
				}
				return true;
			}),
		expirationDate: Yup.date()
			.nullable()
			.typeError('Please enter a valid date in MM/DD/YYYY format')
			.test('originalIssueDate', 'Date is before issue date', (val, context) => {
				if (
					val &&
					context.parent.originalIssueDate &&
					val < context.parent.originalIssueDate
				) {
					return false;
				}
				return true;
			}),
		number: Yup.string()
			.nullable()
			.test('duplicateLicenseNumber', 'Duplicate license number', (val) => {
				if (
					!selectedLicense &&
					props.licensing?.statesLicensing
						?.find((sl) => sl.stateAddressFieldLookupId === props.state.id)
						?.stateLicenses?.find((sl) => sl.number === val)
				)
					return false;
				return true;
			}),
		active: Yup.boolean(),
	});

	const onSubmit = async (values: FormValues) => {
		await updateLicenses({
			...props.licensing,
			firstName: props.licensing.firstName ?? '',
			lastName: props.licensing.lastName ?? '',
			statesLicensing: props.licensing.statesLicensing.map((sl) => {
				if (sl.stateAddressFieldLookupId !== props.state.id) return sl;
				const slicenses = sl.stateLicenses.filter((_, i) => i !== props.licenseIndex);
				const stateLicenses = [
					// part of the array before the specified index
					...slicenses.slice(0, props.licenseIndex ?? 0),
					{
						...values,
						expirationDate: values.expirationDate
							? format(values.expirationDate, 'yyyy-MM-dd')
							: null,
						originalIssueDate: values.originalIssueDate
							? format(values.originalIssueDate, 'yyyy-MM-dd')
							: null,
					},
					// part of the array after the specified index
					...slicenses.slice(props.licenseIndex ?? 0),
				];
				return {
					...sl,
					stateLicenses,
				};
			}),
			// birthDate that is saved in the licensing is actually a string.
			birthDate: props.licensing?.birthDate,
		}).then((success) => {
			if (!success) return;
			toast.push('Successfully updated licensing data.', {
				variant: 'success',
			});
			closeModal();
		});
	};

	return (
		<Formik<FormValues>
			initialValues={{
				number: selectedLicense?.number ?? '',
				licenseClassId: selectedLicense?.licenseClassId ?? '',
				originalIssueDate: selectedLicense?.originalIssueDate
					? zoneDateOnly(selectedLicense.originalIssueDate)
					: null,
				expirationDate: selectedLicense?.expirationDate
					? zoneDateOnly(selectedLicense.expirationDate)
					: null,
				active: selectedLicense?.active ?? false,
			}}
			validationSchema={validationSchema}
			onSubmit={onSubmit}>
			{(formik) => (
				<>
					<ModalContent>
						{error && <Alert error={error} />}
						<Grid container spacing={3}>
							<Grid xs={12} md={6} item>
								<TextField
									label="License Number"
									required
									name="number"
									fullWidth
								/>
							</Grid>
							<Grid xs={12} md={6} item>
								{licenseClasses && (
									<SelectField
										name="licenseClassId"
										label="License Class"
										fullWidth
										required
										SelectProps={{ displayEmpty: true }}>
										{licenseClasses.map((licenseClass) => (
											<MenuItem key={licenseClass.id} value={licenseClass.id}>
												{licenseClass.name} ({licenseClass.code})
											</MenuItem>
										))}
									</SelectField>
								)}
							</Grid>
							<Grid xs={12} md={6} item>
								<DatePickerField
									label="Original Issue Date"
									name="originalIssueDate"
								/>
							</Grid>
							<Grid xs={12} md={6} item>
								<DatePickerField label="Expiration Date" name="expirationDate" />
							</Grid>
							<Grid xs={12} md={6} item>
								<FormControlLabel
									label="Active"
									control={<SwitchField name="active" />}
								/>
							</Grid>
						</Grid>
					</ModalContent>
					<ModalActions>
						<ModalActionButton variant="outlined">Cancel</ModalActionButton>
						<LoadingButton
							loading={formik.isSubmitting}
							disabled={!formik.dirty || !formik.isValid}
							variant="contained"
							onClick={formik.submitForm}
							color="primary">
							{props.licenseIndex !== undefined ? 'Update License' : 'Add License'}
						</LoadingButton>
					</ModalActions>
				</>
			)}
		</Formik>
	);
};

/**
 * AddOrUpdateStateModal - Modal to add a US State or update one.
 *
 * @param {({
 *     userId: string;
 *     licensing: UsaAdjusterLicensing;
 *     stateId?: string | undefined;
 * })} props
 * @return {*}
 */
const AddOrUpdateStateModal = (props: {
	userId: string;
	licensing: UsaAdjusterLicensingOutput;
	stateId?: string | undefined;
}) => {
	const { licensing, userId } = props;
	// These territories do not have license classes and thus need to be removed from the State dropdown.
	const statesToRemove = [
		'American Samoa',
		'Federated States of Micronesia',
		'Northern Mariana Islands',
		'Armed Forces - AA',
		'Armed Forces - AE',
		'Armed Forces - AP',
		'Marshall Islands',
		'Palau',
	];
	let { states } = useUsStates();
	states = states?.filter(
		(s) => s.displayName !== undefined && !statesToRemove.includes(s.displayName)
	);
	const toast = useToast();
	const { closeModal } = useModal();
	const { error, updateLicenses } = useUsaAdjusterLicensingManualMutation(userId);
	let selectedStateLicensing = licensing.statesLicensing.find(
		(sl) => sl.stateAddressFieldLookupId === props.stateId
	);

	type FormValues = {
		stateAddressFieldLookupId: string;
		fullNameAlias: string;
	};

	const onSubmit = async (values: FormValues) => {
		let statesLicensing = licensing.statesLicensing.filter(
			(sl) => sl.stateAddressFieldLookupId !== props.stateId
		);
		let index = selectedStateLicensing
			? licensing.statesLicensing.indexOf(selectedStateLicensing)
			: 0;
		statesLicensing = [
			// part of the array before the specified index
			...statesLicensing.slice(0, index),
			{
				stateAppointments: [],
				stateLicenses: [],
				stateRegulatoryActions: [],
				...selectedStateLicensing,
				...values,
			},
			// part of the array after the specified index
			...statesLicensing.slice(index),
		];
		await updateLicenses({
			...licensing,
			firstName: props.licensing.firstName ?? '',
			lastName: props.licensing.lastName ?? '',
			statesLicensing,
			// birthDate that is saved in the licensing is actually a string.
			birthDate: licensing?.birthDate,
		}).then((success) => {
			if (!success) return;
			toast.push('Successfully updated licensing data.', {
				variant: 'success',
			});
			closeModal();
		});
	};

	return (
		<Formik<FormValues>
			initialValues={{
				stateAddressFieldLookupId: selectedStateLicensing?.stateAddressFieldLookupId ?? '',
				fullNameAlias: selectedStateLicensing?.fullNameAlias ?? '',
			}}
			onSubmit={onSubmit}>
			{(formik) => (
				<>
					<ModalContent>
						<Stack spacing={2}>
							{error && <Alert error={error} />}
							<SelectField
								name="stateAddressFieldLookupId"
								label="State"
								required
								disabled={Boolean(selectedStateLicensing)}>
								{states.map((state) => (
									<MenuItem
										key={state.id}
										value={state.id}
										hidden={Boolean(
											licensing.statesLicensing.find(
												(sl) => sl?.stateAddressFieldLookupId === state.id
											)
										)}>
										{state.displayName}
									</MenuItem>
								))}
							</SelectField>
							<TextField name="fullNameAlias" label="Alias" />
						</Stack>
					</ModalContent>
					<ModalActions>
						<ModalActionButton variant="outlined">Cancel</ModalActionButton>
						<LoadingButton
							loading={formik.isSubmitting}
							disabled={!formik.dirty || !formik.isValid}
							variant="contained"
							onClick={formik.submitForm}
							color="primary">
							{props.stateId !== undefined ? 'Update State' : 'Add State'}
						</LoadingButton>
					</ModalActions>
				</>
			)}
		</Formik>
	);
};

/**
 * StateLicenseRow - A single row in the Licenses table
 *
 * @param {{ ...props }}
 * @return {*}
 */
const StateLicenseRow = ({
	index,
	license,
	state,
	hasAccessAlterLicensing,
	addOrUpdateLicense,
	deleteLicense,
}: {
	index: number;
	license: UsaStateAdjusterLicenseOutput;
	state: AddressFieldLookup;
	hasAccessAlterLicensing: boolean;
	addOrUpdateLicense: (index?: number) => void;
	deleteLicense: (index: number) => void;
}) => {
	const [licenseContextEl, setLicenseContextEl] = useState<HTMLElement | null>(null);
	return (
		<TableRow>
			<TableCell>{license.number}</TableCell>
			<TableCell>
				<LicenseClass id={license.licenseClassId} stateName={state.value ?? ''} />
			</TableCell>
			<TableCell>
				{license.originalIssueDate
					? format(zoneDateOnly(license.originalIssueDate), 'MM/dd/yyyy')
					: ''}
			</TableCell>
			<TableCell>
				{license.expirationDate
					? format(zoneDateOnly(license.expirationDate), 'MM/dd/yyyy')
					: ''}
			</TableCell>
			<TableCell className={clsx('text-neutral-700', license.active && 'text-green-500')}>
				{license.active ? 'Active' : 'Inactive'}
			</TableCell>
			<TableCell size="small" padding="none">
				{hasAccessAlterLicensing && (
					<IconButton onClick={(ev) => setLicenseContextEl(ev.currentTarget)}>
						<MoreHorizOutlined />
					</IconButton>
				)}
				<Menu
					open={Boolean(licenseContextEl)}
					anchorEl={licenseContextEl}
					onClose={() => setLicenseContextEl(null)}>
					<MenuItem onClick={() => addOrUpdateLicense(index)}>
						<ListItemIcon>
							<EditOutlined />
						</ListItemIcon>
						<ListItemText>Update License</ListItemText>
					</MenuItem>
					<MenuItem onClick={() => deleteLicense(index)}>
						<ListItemIcon>
							<DeleteOutlined />
						</ListItemIcon>
						<ListItemText>Delete License</ListItemText>
					</MenuItem>
				</Menu>
			</TableCell>
		</TableRow>
	);
};

/**
 * StateAppointmentRow - A single row in the Appointments table
 *
 * @param {{ ...props }}
 * @return {*}
 */
const StateAppointmentRow = ({
	index,
	appointment,
	state,
	hasAccessAlterLicensing,
	addOrUpdateAppointment,
	deleteAppointment,
}: {
	index: number;
	appointment: UsaStateAdjusterAppointmentOutput;
	state: AddressFieldLookup;
	hasAccessAlterLicensing: boolean;
	addOrUpdateAppointment: (index?: number) => void;
	deleteAppointment: (index: number) => void;
}) => {
	const [appointmentContextEl, setAppointmentContextEl] = useState<HTMLElement | null>(null);
	return (
		<TableRow>
			<TableCell>{appointment.companyName}</TableCell>
			<TableCell>
				{appointment.lineOfAuthorityId && (
					<LineOfAuthority
						id={appointment.lineOfAuthorityId}
						stateName={state.value ?? ''}
					/>
				)}
			</TableCell>
			<TableCell>{appointment.status}</TableCell>
			<TableCell>
				{appointment.renewalDate
					? format(zoneDateOnly(appointment.renewalDate), 'MM/dd/yyyy')
					: ''}
			</TableCell>
			<TableCell size="small" padding="none">
				{hasAccessAlterLicensing && (
					<IconButton onClick={(ev) => setAppointmentContextEl(ev.currentTarget)}>
						<MoreHorizOutlined />
					</IconButton>
				)}
				<Menu
					open={Boolean(appointmentContextEl)}
					anchorEl={appointmentContextEl}
					onClose={() => setAppointmentContextEl(null)}>
					<MenuItem onClick={() => addOrUpdateAppointment(index)}>
						<ListItemIcon>
							<EditOutlined />
						</ListItemIcon>
						<ListItemText>Update Appointment</ListItemText>
					</MenuItem>
					<MenuItem onClick={() => deleteAppointment(index)}>
						<ListItemIcon>
							<DeleteOutlined />
						</ListItemIcon>
						<ListItemText>Delete Appointment</ListItemText>
					</MenuItem>
				</Menu>
			</TableCell>
		</TableRow>
	);
};

/**
 * StateLicense - A single state license tab.
 *
 * @param {{ userId: string; licensing: UsaAdjusterLicensing; state: AddressFieldLookup }} props
 * @return {*}
 */
const StateLicense = (props: {
	userId: string;
	licensing: UsaAdjusterLicensingOutput;
	state: AddressFieldLookup;
}) => {
	const { state, licensing, userId } = props;
	const { showModal, closeModal } = useModal();
	const [stateContextEl, setStateContextEl] = useState<HTMLElement | null>(null);
	const licenses = licensing.statesLicensing.find(
		(sl) => sl.stateAddressFieldLookupId === state.id
	);
	if (!licenses) return <></>;
	const { hasAccess } = usePrivacy(props.userId);
	const hasAccessAlterLicensing = hasAccess(UserProfileAspectType.AlterLicensing);

	const addOrUpdateLicense = (index?: number) => {
		showModal({
			title: `${index !== undefined ? 'Update' : 'Add'} License for ${
				props.state.displayName
			}`,
			maxWidth: 'md',
			content: (
				<AddOrUpdateLicenseModal
					userId={userId}
					licensing={licensing}
					state={state}
					licenseIndex={index}
				/>
			),
		});
	};

	const deleteLicense = (index: number) => {
		const DeleteActions = () => {
			const toast = useToast();
			const { loading, updateLicenses } = useUsaAdjusterLicensingManualMutation(userId);
			return (
				<ModalActions>
					<ModalActionButton size="large" variant="outlined">
						Cancel
					</ModalActionButton>
					<LoadingButton
						size="large"
						variant="contained"
						color="error"
						loading={loading}
						onClick={async () => {
							await updateLicenses({
								...licensing,
								firstName: props.licensing.firstName ?? '',
								lastName: props.licensing.lastName ?? '',
								// birthDate that is saved in the licensing is actually a string.
								birthDate: licensing.birthDate,
								statesLicensing: licensing.statesLicensing.map((sl) => {
									if (sl.stateAddressFieldLookupId !== state.id) return sl;
									return {
										...sl,
										stateLicenses: sl.stateLicenses.filter(
											(v, i) => i !== index
										),
									};
								}),
							}).then((success) => {
								if (!success) return;
								toast.push('Successfully updated licensing data.', {
									variant: 'success',
								});
								closeModal();
							});
						}}>
						Delete
					</LoadingButton>
				</ModalActions>
			);
		};

		showModal({
			title: 'Are you sure?',
			content: (
				<ConfirmModalContent
					visual={
						<EmptyStateAvatar
							avatarProps={{ bgcolor: 'error.50' }}
							iconProps={{ color: 'error.500' }}
							icon={<ErrorOutlined />}
						/>
					}
					subheadline={`Delete license for ${state.displayName}`}
					informativeContent="Do you really want to delete the license? This process cannot be undone."
				/>
			),
			actions: <DeleteActions />,
		});
	};

	const addOrUpdateAppointment = (index?: number) => {
		showModal({
			title: `${index !== undefined ? 'Update' : 'Add'} Appointment for ${
				props.state.displayName
			}`,
			content: (
				<AddOrUpdateAppointmentModal
					userId={userId}
					licensing={licensing}
					state={state}
					appointmentIndex={index}
				/>
			),
		});
	};

	const deleteAppointment = (index: number) => {
		const DeleteActions = () => {
			const toast = useToast();
			const { loading, updateLicenses } = useUsaAdjusterLicensingManualMutation(userId);
			return (
				<ModalActions>
					<ModalActionButton size="large" variant="outlined">
						Cancel
					</ModalActionButton>
					<LoadingButton
						size="large"
						variant="contained"
						color="error"
						loading={loading}
						onClick={async () => {
							await updateLicenses({
								...licensing,
								firstName: props.licensing.firstName ?? '',
								lastName: props.licensing.lastName ?? '',
								// birthDate that is saved in the licensing is actually a string.
								birthDate: licensing.birthDate,
								statesLicensing: licensing.statesLicensing.map((sl) => {
									if (sl.stateAddressFieldLookupId !== state.id) return sl;
									return {
										...sl,
										stateAppointments: sl.stateAppointments.filter(
											(v, i) => i !== index
										),
									};
								}),
							}).then((success) => {
								if (!success) return;
								toast.push('Successfully updated licensing data.', {
									variant: 'success',
								});
								closeModal();
							});
						}}>
						Delete
					</LoadingButton>
				</ModalActions>
			);
		};

		showModal({
			title: 'Are you sure?',
			content: (
				<ConfirmModalContent
					visual={
						<EmptyStateAvatar
							avatarProps={{ bgcolor: 'error.50' }}
							iconProps={{ color: 'error.500' }}
							icon={<ErrorOutlined />}
						/>
					}
					subheadline={`Delete appointment for ${state.displayName}`}
					informativeContent="Do you really want to delete the appointment? This process cannot be undone."
				/>
			),
			actions: <DeleteActions />,
		});
	};

	const updateAlias = () => {
		showModal({
			title: 'Update State',
			content: (
				<AddOrUpdateStateModal
					userId={userId}
					licensing={licensing}
					stateId={props.state.id}
				/>
			),
		});
	};

	const deleteState = () => {
		setStateContextEl(null);
		const DeleteActions = () => {
			const toast = useToast();
			const { loading, updateLicenses } = useUsaAdjusterLicensingManualMutation(userId);
			return (
				<ModalActions>
					<ModalActionButton size="large" variant="outlined">
						Cancel
					</ModalActionButton>
					<LoadingButton
						size="large"
						variant="contained"
						color="error"
						loading={loading}
						onClick={async () => {
							await updateLicenses({
								...licensing,
								firstName: props.licensing.firstName ?? '',
								lastName: props.licensing.lastName ?? '',
								// birthDate that is saved in the licensing is actually a string.
								birthDate: licensing.birthDate,
								statesLicensing: licensing.statesLicensing.filter(
									(sl) => sl.stateAddressFieldLookupId !== state.id
								),
							}).then((success) => {
								if (!success) return;
								toast.push('Successfully updated licensing data.', {
									variant: 'success',
								});
								closeModal();
							});
						}}>
						Delete
					</LoadingButton>
				</ModalActions>
			);
		};

		showModal({
			title: 'Are you sure?',
			content: (
				<ConfirmModalContent
					visual={
						<EmptyStateAvatar
							avatarProps={{ bgcolor: 'error.50' }}
							iconProps={{ color: 'error.500' }}
							icon={<ErrorOutlined />}
						/>
					}
					subheadline={`Delete licenses for ${state.displayName}`}
					informativeContent="Do you really want to delete the licensing data for this state? This process cannot be undone."
				/>
			),
			actions: <DeleteActions />,
		});
	};

	return (
		<Stack spacing={2}>
			<Stack
				direction="row"
				justifyContent="space-between"
				alignItems="center"
				style={{ marginRight: '-0.5rem' }}>
				<Stack>
					<Typography variant="h1">{state?.displayName ?? ''}</Typography>
					{licenses.fullNameAlias && (
						<Typography variant="subtitle2">
							Alias:&nbsp;{licenses.fullNameAlias}
						</Typography>
					)}
				</Stack>
				{hasAccessAlterLicensing && (
					<IconButton onClick={(ev) => setStateContextEl(ev.currentTarget)}>
						<MoreHorizOutlined />
					</IconButton>
				)}
				<Menu
					open={Boolean(stateContextEl)}
					anchorEl={stateContextEl}
					onClose={() => setStateContextEl(null)}>
					<MenuItem onClick={() => updateAlias()}>
						<ListItemIcon>
							<EditOutlined />
						</ListItemIcon>
						<ListItemText>Change Alias</ListItemText>
					</MenuItem>
					<MenuItem onClick={() => deleteState()}>
						<ListItemIcon>
							<DeleteOutlined />
						</ListItemIcon>
						<ListItemText>Delete State</ListItemText>
					</MenuItem>
				</Menu>
			</Stack>
			<Stack direction="row" alignItems="center">
				<Typography variant="h2">Licenses</Typography>
				{hasAccessAlterLicensing && (
					<IconButton onClick={() => addOrUpdateLicense(undefined)}>
						<AddCircleOutlined color="primary" />
					</IconButton>
				)}
			</Stack>
			{licenses.stateLicenses.length > 0 ? (
				<TableContainer>
					<Table stickyHeader>
						<TableHead>
							<TableRow>
								<TableCell style={{ width: '16%' }}>License Number</TableCell>
								<TableCell style={{ width: '16%' }}>License Class</TableCell>
								<TableCell style={{ width: '16%' }}>Original Issue Date</TableCell>
								<TableCell style={{ width: '16%' }}>Expiration Date</TableCell>
								<TableCell style={{ width: '16%' }}>Status</TableCell>
								<TableCell size="small" style={{ width: '7%' }}></TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{licenses.stateLicenses.map((license, i) => (
								<StateLicenseRow
									key={i}
									index={i}
									license={license}
									state={state}
									hasAccessAlterLicensing={hasAccessAlterLicensing}
									addOrUpdateLicense={addOrUpdateLicense}
									deleteLicense={deleteLicense}
								/>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			) : (
				<Box
					width="100%"
					bgcolor="#f2f2f2"
					padding="35px"
					borderRadius="0.5rem"
					textAlign="center">
					<span>No licenses added</span>
				</Box>
			)}
			<Stack direction="row" alignItems="center">
				<Typography variant="h2">Appointments</Typography>
				{hasAccessAlterLicensing && (
					<IconButton onClick={() => addOrUpdateAppointment(undefined)}>
						<AddCircleOutlined color="primary" />
					</IconButton>
				)}
			</Stack>
			{licenses.stateAppointments.length > 0 ? (
				<TableContainer>
					<Table stickyHeader>
						<TableHead>
							<TableRow>
								<TableCell style={{ width: '20%' }}>Company</TableCell>
								<TableCell style={{ width: '20%' }}>Line of Authority</TableCell>
								<TableCell style={{ width: '20%' }}>Status</TableCell>
								<TableCell style={{ width: '20%' }}>Renewal Date</TableCell>
								<TableCell size="small" style={{ width: '7%' }}></TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{licenses.stateAppointments.map((appointment, i) => (
								<StateAppointmentRow
									key={i}
									index={i}
									appointment={appointment}
									state={state}
									hasAccessAlterLicensing={hasAccessAlterLicensing}
									addOrUpdateAppointment={addOrUpdateAppointment}
									deleteAppointment={deleteAppointment}
								/>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			) : (
				<Box
					width="100%"
					bgcolor="#f2f2f2"
					padding="35px"
					borderRadius="0.5rem"
					textAlign="center">
					<span>No appointments added</span>
				</Box>
			)}
		</Stack>
	);
};

/**
 * ManageUSALicenseScreen - Manage US Licensing screen.  Lets you read and edit licensing
 * for the United States.
 *
 * @return {*}
 */
export const ManageUsaLicensePage = ({ userId }: { userId: string }) => {
	const { showModal } = useModal();
	const { loading: loadingStates, states } = useUsStates();
	const { licensing, loading } = useUsaLicensingQuery(userId);
	const [selectedTab, setSelectedTab] = useState<number>(0);
	const { hasAccess } = usePrivacy(userId);
	const hasAccessAlterLicensing = hasAccess(UserProfileAspectType.AlterLicensing);
	useEffect(() => {
		// When we get a new set of licenses and the length is less than the selected state
		// we know that a state is deleted and therefore the selected tab should just be removed.
		setSelectedTab(
			Math.max(0, Math.min(selectedTab, (licensing?.statesLicensing?.length ?? 1) - 1))
		);
	}, [licensing?.statesLicensing?.length]);

	// TODO: Page Skeleton
	if (!licensing) return <></>;

	const showAddState = () => {
		showModal({
			title: 'Add State',
			content: <AddOrUpdateStateModal userId={userId} licensing={licensing} />,
		});
	};

	return (
		<>
			<PageTitle title="United States Licensing" />
			<PageContent>
				<Stack spacing={2} margin={{ xs: '-12px', md: 'unset' }}>
					<UsaLicenseInformationRead userId={userId} licensing={licensing} />
					{!loading && licensing && !loadingStates && (
						<Card>
							<CardContent>
								<Stack
									direction="row"
									justifyContent="space-between"
									alignItems="center"
									sx={{ mt: -1.5, mb: 2 }}>
									{licensing?.statesLicensing && (
										<Tabs
											variant="scrollable"
											allowScrollButtonsMobile
											value={Math.max(
												0,
												Math.min(
													selectedTab,
													licensing.statesLicensing.length - 1
												)
											)}
											onChange={(_e, v) => setSelectedTab(v)}>
											{licensing?.statesLicensing?.map((sl) => (
												<Tab
													key={sl.stateAddressFieldLookupId}
													label={
														states?.find(
															(s) =>
																s.id ===
																sl.stateAddressFieldLookupId
														)?.value ?? ''
													}
												/>
											))}
										</Tabs>
									)}
									{hasAccessAlterLicensing && (
										<IconButton onClick={() => showAddState()}>
											<AddCircleOutlined color="primary" />
										</IconButton>
									)}
								</Stack>
								{licensing.statesLicensing?.length === 0 ? (
									<Box
										width="100%"
										bgcolor="#f2f2f2"
										padding="35px"
										borderRadius="0.5rem"
										textAlign="center">
										<span>No states added</span>
									</Box>
								) : (
									licensing.statesLicensing?.map((state, i) => {
										const stateLookup = states?.find(
											(s) => s.id === state.stateAddressFieldLookupId
										);
										if (!stateLookup) return <></>;
										return (
											<div key={i} hidden={i !== selectedTab}>
												<StateLicense
													userId={userId}
													state={stateLookup}
													licensing={licensing}
												/>
											</div>
										);
									})
								)}
							</CardContent>
						</Card>
					)}
				</Stack>
			</PageContent>
		</>
	);
};
