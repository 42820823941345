import { ArrowBack, ForumOutlined } from '@mui/icons-material';
import { Avatar, IconButton, List, Skeleton, Stack, Typography } from '@mui/material';
import { theme } from 'utils/theme';
import { useSavedConversations } from './hooks';
import { SavedPromptListItem } from './savedPromptListItem';

interface SavedPromptsProps {
	replayConversation: (conversationId: string) => void;
	onClose: () => void;
}

export const SavedPrompts = ({ replayConversation, onClose }: SavedPromptsProps) => {
	const { savedConversations, loading } = useSavedConversations();

	const selectConversation = (conversationId: string) => {
		replayConversation(conversationId);
		onClose();
	};

	return (
		<Stack height="100%" width="100%" position="relative" id="evox-container">
			<Stack
				px={1}
				py={0.5}
				direction="row"
				alignItems="center"
				spacing={1}
				borderBottom={`1px solid ${theme.palette.divider}`}>
				<IconButton onClick={onClose}>
					<ArrowBack />
				</IconButton>
				<Typography variant="h4">Saved Prompts</Typography>
			</Stack>
			{loading ? (
				<SavedPromptsSkeleton />
			) : savedConversations.length === 0 ? (
				<NoPromptsMessage />
			) : (
				<List>
					{savedConversations.map((conversation) => (
						<SavedPromptListItem
							key={conversation.conversationId}
							conversation={conversation}
							selectConversation={selectConversation}
						/>
					))}
				</List>
			)}
		</Stack>
	);
};

const SavedPromptsSkeleton = () => {
	return (
		<Stack p={2} spacing={1}>
			{[...Array(3)].map((_, index) => (
				<Stack key={index} direction="row" spacing={2} alignItems="center">
					<Skeleton variant="circular" height={48} width={48} />
					<Skeleton variant="text" width={200} />
				</Stack>
			))}
		</Stack>
	);
};

const NoPromptsMessage = () => {
	return (
		<Stack
			flex={1}
			justifyContent="center"
			alignItems="center"
			px={8}
			py={2}
			spacing={2}
			overflow="auto">
			<Avatar sx={{ height: 64, width: 64 }}>
				<ForumOutlined fontSize="large" />
			</Avatar>
			<Typography variant="h4" textAlign="center">
				No saved prompts.
			</Typography>
			<Typography variant="body2" textAlign="center">
				Save a series of prompts from any EVO-X conversation as a consolidated prompt to
				replay in the future.
			</Typography>
			<Typography variant="body2" textAlign="center">
				EVO-X&apos;s responses will not be saved and its answers may change next time you
				replay your saved prompt.
			</Typography>
		</Stack>
	);
};
