import {
	AccountCircleOutlined,
	ForumOutlined,
	HowToRegOutlined,
	SendOutlined,
} from '@mui/icons-material';
import { Box, Button, Card, Divider, Stack, Typography } from '@mui/material';
import { UserEmblemAvatar } from 'components/ui/emblem/emblem-avatar';
import { useEmblemBanner } from 'components/ui/emblem/hooks';
import { AccountCancelOutlined, AccountQuestionOutlined } from 'components/ui/icons';
import { ConnectionState, Emblem, EmblemEntityType } from 'middleware-types';
import { Permission } from 'utils/permissions';
import { useSession } from 'utils/session';
import { useCanMessage } from 'utils/useCanMessage';
import { useSiteUser } from 'utils/useSiteUser';
import { isDemo } from 'utils/utils';

export const TeamMemberCard = ({ member }: { member: Emblem }) => {
	const userId = member.id;
	const { user } = useSession();
	const { src, onError } = useEmblemBanner(userId, EmblemEntityType.User);
	const { hasPermission } = useSiteUser();

	const isSelf = userId === user.userId;
	const isSiteUser = Boolean(user.siteUserId);
	const canViewProfile = !isSiteUser || hasPermission(Permission.SocialUser_Profile_R);

	const subtitle =
		member.extraData?.companyName && member.extraData.companyTitle
			? `${member.extraData.companyTitle} at ${member.extraData.companyName}`
			: '';

	const connectState = member.connection?.state;
	let connectMessage = 'Not Connected';
	let ConnectIcon = AccountCancelOutlined;
	if (connectState === ConnectionState.Connected) {
		connectMessage = 'Connected';
		ConnectIcon = HowToRegOutlined;
	} else if (connectState === ConnectionState.Pending) {
		if (member.connection!.targetUserId === user.userId) {
			connectMessage = 'Req Pending';
			ConnectIcon = AccountQuestionOutlined;
		} else {
			connectMessage = 'Req. Sent';
			ConnectIcon = SendOutlined;
		}
	}

	const messageButtonEnabled = useCanMessage(member.handle);

	return (
		<Card variant="outlined" sx={{ position: 'relative' }}>
			<Box
				component="img"
				src={src}
				onError={onError}
				position="absolute"
				top={0}
				width="100%"
				height={68}
				sx={{ objectFit: 'cover' }}
			/>
			<Stack height="100%">
				<Stack
					flexGrow={1}
					position="relative"
					alignItems="center"
					spacing={1.5}
					pt="30px"
					pb={1.5}
					px={2}>
					<UserEmblemAvatar id={userId} size={80} />
					<Box textAlign="center">
						<Typography variant="h4">{member.displayName}</Typography>
						{subtitle && <Typography variant="body1">{subtitle}</Typography>}
					</Box>
				</Stack>
				<Divider />
				<Stack direction="row">
					{isDemo() && !isSelf && !isSiteUser && (
						<>
							<Button
								sx={{
									flexDirection: 'column',
									borderRadius: 0,
									flexGrow: 1,
									flexShrink: 1,
									flexBasis: 0,
									pt: 1.5,
									textAlign: 'center',
									pointerEvents: 'none',
								}}>
								<ConnectIcon />
								{connectMessage}
							</Button>
							<Divider orientation="vertical" />
						</>
					)}
					{isDemo() && !isSelf && !isSiteUser && (
						<>
							<Button
								disabled={!messageButtonEnabled}
								href={`/app/conversation-emblem-redirect/${EmblemEntityType.User}/${userId}`}
								sx={{
									flexDirection: 'column',
									borderRadius: 0,
									flexGrow: 1,
									flexShrink: 1,
									flexBasis: 0,
									pt: 1.5,
									textAlign: 'center',
								}}>
								<ForumOutlined />
								Message
							</Button>
							<Divider orientation="vertical" />
						</>
					)}
					<>
						<Button
							disabled={!canViewProfile}
							href={`/${member.handle}`}
							sx={{
								flexDirection: 'column',
								borderRadius: 0,
								flexGrow: 1,
								flexShrink: 1,
								flexBasis: 0,
								pt: 1.5,
								textAlign: 'center',
							}}>
							<AccountCircleOutlined />
							View Profile
						</Button>
					</>
				</Stack>
			</Stack>
		</Card>
	);
};
