import { theme } from 'utils/theme';
import { EvoxFileChip } from './evoxFileChip';
import { UserChatFile } from 'middleware-types';
import { Box, Stack, Typography } from '@mui/material';
import { UserEmblemAvatar } from 'components/ui/emblem/emblem-avatar';

interface ChatRequestProps {
	text: string | undefined;
	userId: string;
	files?: UserChatFile[] | undefined;
}

export const ChatRequest = ({ text, userId, files }: ChatRequestProps) => {
	return (
		<>
			<Stack direction="row" spacing={1} alignItems="flex-start" justifyContent="flex-end">
				<Stack alignItems="flex-end">
					{
						// A sent message's files will always be an array, but might be empty.
						// A pending chat message's files might be undefined, in keeping with
						files &&
							files.length > 0 &&
							files.map((file) => (
								<EvoxFileChip key={file.chatFileId} name={file.name} />
							))
					}
					<Box
						borderRadius={1}
						p={1}
						sx={{
							backgroundColor: theme.palette.primary.main,
							overflowWrap: 'break-word',
							overflowX: 'auto',
							width: 'fit-content',
						}}>
						<Typography variant="body2" color="white">
							{text}
						</Typography>
					</Box>
				</Stack>
				<UserEmblemAvatar id={userId} />
			</Stack>
		</>
	);
};
