import { gql } from '@apollo/client';

export const EMBLEM_FIELDS = gql`
	fragment EmblemFields on Emblem {
		id
		entityType
		displayName
		handle
		avatarFile {
			fileId
			file {
				id
				currentInstance {
					id
					cdnUrl
					fileName
					fileSize
					virusStatus
					uploadedUtc
					fileId
				}
			}
		}
		extraData {
			companyName
			companyTitle
			initials
			fullName
			siteUserId
		}
		connection {
			id
			state
			targetUserId
		}
		deactivated
		private
	}
`;
