import { useEffect, useRef, useState } from 'react';
import Globe from 'react-globe.gl';
import * as THREE from 'three';
import { countriesData } from '../datasets/ne_110m_admin_0_countries.js';

// custom globe material
const globeMaterial = new THREE.MeshPhongMaterial();
globeMaterial.bumpScale = 10;
globeMaterial.color = new THREE.Color('#e8f0f5');

const MAX_GLOBE_WIDTH = 896;
const MAX_GLOBE_HEIGHT = 1200;

const NUMBER_OF_ARCS = 20;

export const GlobeComponent = () => {
	const [globeWidth, setGlobeWidth] = useState(window.innerWidth * 0.5);
	const [globeHeight, setGlobeHeight] = useState(
		Math.min(window.innerHeight * 1.5, window.innerWidth * 0.7)
	);
	const [globeOpacity, setGlobeOpacity] = useState(0);
	const [logoOpacity, setLogoOpacity] = useState(0);
	const [arcsData, setArcsData] = useState<any[]>([]);
	const globeEl = useRef<any>();

	useEffect(() => {
		const generateArcs = () => {
			const arcs: any = [];
			[...Array(NUMBER_OF_ARCS).keys()].forEach(() => {
				const animationTime = Math.random() * 4500 + 4500; // Random animation time

				const startCountry =
					countriesData.features[
						Math.floor(Math.random() * countriesData.features.length)
					];
				const endCountry =
					countriesData.features[
						Math.floor(Math.random() * countriesData.features.length)
					];

				const [startLng, startLat] = startCountry.geometry.coordinates[0][0];
				const [endLng, endLat] = endCountry.geometry.coordinates[0][0];

				const arc = {
					startLat,
					startLng,
					endLat,
					endLng,
					color: ['rgba(60,213,234, .1)', 'rgba(60,213,234, 1)'],
					animationTime,
				};
				arcs.push(arc);
			});
			setArcsData(arcs);
		};

		generateArcs();
	}, []);

	// Update globe width on window resize
	useEffect(() => {
		const handleResize = () => {
			// Update globe width to 60% of window width
			setGlobeWidth(window.innerWidth * 0.5);

			// Update globe height based on window dimensions
			const newHeight = Math.min(window.innerHeight * 1.5, window.innerWidth * 0.7);
			setGlobeHeight(newHeight);
		};

		// Add event listener to window resize
		window.addEventListener('resize', handleResize);

		// Disable globe controls
		globeEl.current.controls().enabled = false;

		// Auto rotate globe
		globeEl.current.controls().autoRotate = true;
		globeEl.current.controls().autoRotateSpeed = 3;

		// Start globe at a different point (e.g., somewhere around Africa and Europe)
		globeEl.current.pointOfView({ lat: 10, lng: 90, altitude: 3 }, 0);

		// Stop after 7 seconds
		const timer = setTimeout(() => {
			// Auto rotates the globe
			globeEl.current.controls().autoRotate = false;
			setLogoOpacity(1);
		}, 7000);

		// Fade in the globe after .5 second
		const fadeTimer = setTimeout(() => {
			setGlobeOpacity(1);
		}, 500);

		// Cleanup
		return () => {
			window.removeEventListener('resize', handleResize);
			clearTimeout(timer);
			clearTimeout(fadeTimer);
		};
	}, []);

	// Set logo width to be 40% of globe height, but no more than 400px
	const logoWidth = Math.min(Math.min(globeHeight) * 0.25, 300);

	return (
		<div
			className="relative mx-auto max-w-[70rem]"
			style={{ opacity: globeOpacity, transition: 'opacity 3s ease-in-out' }}>
			<img
				style={{
					width: `${logoWidth}px`,
					opacity: logoOpacity,
					transition: 'opacity 3s ease-in-out',
				}}
				className="absolute left-1/2 top-1/2 z-10 mx-auto -translate-x-1/2 -translate-y-1/2 transform"
				src="/img/evolve-logo.svg"
				alt="FTEvolve"
			/>
			<div className="relative">
				<Globe
					ref={globeEl}
					width={Math.min(globeWidth, MAX_GLOBE_WIDTH)}
					height={Math.min(globeHeight, MAX_GLOBE_HEIGHT)}
					globeMaterial={globeMaterial}
					atmosphereColor="#186597"
					atmosphereAltitude={0.1}
					backgroundColor="rgba(0,0,0,0)"
					hexPolygonsData={countriesData.features}
					hexPolygonResolution={3}
					hexPolygonMargin={0.4}
					hexPolygonColor={() => {
						return '#186597';
					}}
					arcsData={arcsData}
					arcColor={'color'}
					arcDashLength={0.5}
					arcDashGap={1}
					arcDashAnimateTime={(d: any) => d.animationTime}
					arcStroke={0.5}
					arcDashInitialGap={1.5}
				/>
			</div>
		</div>
	);
};
