import {
	Box,
	Button,
	CardContent,
	CardHeader,
	Grid,
	MenuItem,
	Paper,
	Skeleton,
	Stack,
} from '@mui/material';
import { useOrgId } from 'components/pages/org/outlet';
import {
	useOrgRoleCategoriesQuery,
	useOrgRoleMutation,
	useOrgRoleQuery,
} from 'components/pages/org/roles/[id]';
import { PermissionRowsSkeleton, PermissionsTable } from 'components/pages/roles/permissions';
import { RoleUser, UserList } from 'components/pages/roles/user-list';
import { SelectField, TextField } from 'components/ui/fields';
import { PageContent, PageTitle } from 'components/ui/page';
import { useToast } from 'components/ui/toast';
import { Formik, FormikProps } from 'formik';
import { OrganizationRoleUpdate } from 'middleware-types';
import { useNavigate, useParams } from 'react-router-dom';
import { handleNoResponse, responseHasErrors } from 'utils/errors';
import { Permission } from 'utils/permissions';
import { useAssociateUser } from 'utils/useAssociateUser';
import { useSiteUser } from 'utils/useSiteUser';
import { useValidation } from 'utils/useValidation';
/**
 * useOrgRole(roleId) - This hook defines all the save and loading logic of the organization role form.
 * @param roleId role id if we are editing a role.
 * @returns
 */
const useOrgRole = (orgId: string, roleId: string | undefined) => {
	const toast = useToast();
	const orgRoleCategories = useOrgRoleCategoriesQuery();
	const orgRoleQuery = useOrgRoleQuery(orgId, roleId);

	const validation = useValidation('OrganizationRoleUpdate');
	const { upsertRole } = useOrgRoleMutation(orgId, roleId);

	const initialValues: OrganizationRoleUpdate = {
		name: orgRoleQuery.role?.name ?? '',
		categoryId: orgRoleQuery.role?.category?.id ?? '',
		permissionKeys: orgRoleQuery.role?.permissionKeys ?? [],
	};

	const isAdminRole = orgRoleQuery.role?.isAdminRole ?? false;
	// If isAdminRole, automatically set all permissions
	if (isAdminRole) {
		initialValues.permissionKeys = orgRoleCategories.permissionCategories.flatMap(
			(category) => {
				return category.permissionGroups.flatMap((permissionGroup) => {
					return permissionGroup.permissions.map((permission) => {
						return permission.permissionKey;
					});
				});
			}
		);
	}

	const users: RoleUser[] =
		orgRoleQuery.role?.associateUserEmblems?.map((aue) => ({
			id: aue.id,
			name: aue.displayName,
			avatar: aue.avatarFile?.file?.currentInstance?.cdnUrl,
		})) ?? [];

	/**
	 * onSubmit switches on update and add to have a single "save" function.
	 * Results are passed throhugh a promise.
	 *
	 * @returns
	 */
	const onSubmit = async (values: OrganizationRoleUpdate): Promise<boolean> => {
		return upsertRole(values)
			.then(async (res) => {
				if (res.errors) {
					for (const e of res.errors as any) {
						// Conflict means it already exists.
						if (e?.extensions?.response?.status === 409) {
							toast.push(`The ${values.name} already exists as a Role.`, {
								variant: 'error',
							});
							return false;
						}
					}
					console.log(res.errors);
					responseHasErrors(res.errors, { toast });
					return false;
				}
				toast.push(
					`The ${values.name} role was successfully ${!roleId ? 'created' : 'updated'}.`,
					{ variant: 'success' }
				);
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	return {
		initialValues,
		users,
		loading: validation.loading || orgRoleQuery.loading || orgRoleCategories.loading,
		categories: orgRoleCategories.roleCategories ?? [],
		permissions: orgRoleCategories.permissionCategories ?? [],
		validationSchema: validation.schema,
		onSubmit,
		isAdminRole,
	};
};

export const OrgRolePage = () => {
	const orgId = useOrgId();
	const { roleId } = useParams<{ roleId: string }>();
	const navigate = useNavigate();
	const { hasPermission: hasAssociateUserPermission } = useAssociateUser(orgId);
	const { hasPermission: hasSiteUserPermission } = useSiteUser();
	const {
		initialValues,
		loading,
		categories,
		validationSchema,
		permissions,
		onSubmit,
		users,
		isAdminRole,
	} = useOrgRole(orgId, roleId);

	const pageTitle = !roleId
		? 'Create Contact Role'
		: initialValues?.name
		? 'Update ' + initialValues.name + ' Role'
		: '';

	const readOnly =
		isAdminRole ||
		(roleId === undefined
			? !hasAssociateUserPermission(Permission.Org_Assoc_Roles_C) &&
			  !hasSiteUserPermission(Permission.Site_OrgRoles_C)
			: !hasAssociateUserPermission(Permission.Org_Assoc_Roles_U) &&
			  !hasSiteUserPermission(Permission.Site_OrgRoles_U));

	return (
		<PageContent>
			<PageTitle title={pageTitle} />
			<Paper sx={{ height: '100%' }}>
				<Formik<OrganizationRoleUpdate>
					enableReinitialize
					initialValues={initialValues}
					validationSchema={validationSchema}
					onSubmit={async (values) => {
						const res = await onSubmit(values);
						if (res) navigate('..');
					}}>
					{(props: FormikProps<OrganizationRoleUpdate>) => (
						<Grid container alignItems="stretch" sx={{ height: '100%' }}>
							<Grid item xs={4} lg={3} xl={2} sx={{ height: '100%' }}>
								<Box display="flex" height="100%" flexDirection="column">
									<CardHeader title="Associate Role" />
									<CardContent
										sx={{
											flex: '1 1 auto',
											overflow: 'hidden',
										}}>
										{loading ? (
											<Skeleton animation="wave" height="4rem" />
										) : (
											<Stack spacing={2} pt={1} height="100%">
												<TextField
													label="Role Name"
													name="name"
													required
													fullWidth
													disabled={readOnly}
												/>
												<SelectField
													label="Category"
													value={props.values.categoryId}
													name="categoryId"
													required
													fullWidth
													disabled={readOnly}>
													{categories.map((src, i) => (
														<MenuItem key={i} value={src.id}>
															{src.name !== '' ? src.name : 'None'}
														</MenuItem>
													))}
												</SelectField>
												{!roleId && (
													<UserList
														users={users}
														disabled={props.isSubmitting}
													/>
												)}
											</Stack>
										)}
									</CardContent>
								</Box>
							</Grid>
							<Grid item xs={8} lg={9} xl={10} sx={{ height: '100%' }}>
								<Box display="flex" height="100%" flexDirection="column">
									<CardHeader
										title="Permissions"
										action={
											!loading && (
												<Stack direction="row" spacing={1}>
													<Button
														variant="outlined"
														onClick={() => navigate('..')}>
														Cancel
													</Button>
													{!readOnly && (
														<Button
															type="submit"
															color="primary"
															disabled={
																!props.isValid ||
																!props.dirty ||
																props.isSubmitting
															}
															onClick={() => props.submitForm()}
															variant="contained">
															{roleId ? 'Save Role' : 'Create Role'}
														</Button>
													)}
												</Stack>
											)
										}
									/>
									<CardContent
										sx={{
											flex: '1 1 auto',
											overflow: 'auto',
										}}>
										{loading ? (
											<PermissionRowsSkeleton />
										) : (
											<PermissionsTable
												name="permissionKeys"
												permissions={permissions}
												disabled={readOnly || props.isSubmitting}
											/>
										)}
									</CardContent>
								</Box>
							</Grid>
						</Grid>
					)}
				</Formik>
			</Paper>
		</PageContent>
	);
};
