import { gql, useMutation, useQuery } from '@apollo/client';
import {
	LocationAddress,
	LocationAddressUpdate,
	Mutation,
	MutationUserAccountLocationAddressAddOrUpdateArgs,
	MutationUserAccountLocationAddressDeleteArgs,
	Query,
	QueryAccountArgs,
} from 'middleware-types';
import { AddOutlined, ErrorOutlined } from '@mui/icons-material';
import { Box, Card, CardContent, CardHeader, Divider, IconButton, Skeleton } from '@mui/material';
import {
	AddressType,
	LocationAddressFormValues,
	useAddressInputPage,
} from 'components/pages/user/account/locations/locations-form';
import { LocationInformationItem } from 'components/pages/user/account/locations/locations-item';
import { Alert } from 'components/ui/alert';
import { GEOADDRESS_FIELDS } from 'components/ui/fields/address';
import {
	ConfirmModalContent,
	ModalActionButton,
	ModalActions,
	ModalLoadingButton,
	useModal,
} from 'components/ui/modal';
import { useToast } from 'components/ui/toast';
import { format } from 'date-fns';
import { handleNoResponse, responseHasErrors } from 'utils/errors';
import { Permission } from 'utils/permissions';
import { useSession } from 'utils/session';
import { useSiteUser } from 'utils/useSiteUser';
import { zoneDateOnly } from 'utils/utils';
import { EmptyStateAvatar } from 'components/ui/empty-state-avatar';

export const DELETE_LOCATION_ADDRESS = gql`
	mutation userAccountLocationAddressDelete($userId: ID!, $locationAddressId: ID!) {
		userAccountLocationAddressDelete(userId: $userId, locationAddressId: $locationAddressId)
	}
`;

/**
 * Hook to delete a user's location address.
 * @param userId
 * @returns
 */
export const useDeleteLocationAddressMutation = (userId: string) => {
	const toast = useToast();
	const [userAccountLocationAddressDelete, { error, loading }] = useMutation<
		Pick<Mutation, 'userAccountLocationAddressDelete'>,
		MutationUserAccountLocationAddressDeleteArgs
	>(DELETE_LOCATION_ADDRESS, {
		refetchQueries: [{ query: LOCATIONADDRESSES, variables: { userId } }],
		awaitRefetchQueries: true,
	});

	return {
		deleteLocation: (locationAddressId: string) => {
			return userAccountLocationAddressDelete({
				variables: {
					userId,
					locationAddressId,
				},
			})
				.then((res) => {
					if (responseHasErrors(res.errors, { toast })) {
						return false;
					}
					toast.push('Location address deleted successfully.', {
						variant: 'success',
					});
					return true;
				})
				.catch(() => {
					handleNoResponse({ toast });
					return false;
				});
		},
		error,
		loading,
	};
};

export const ADD_OR_UPDATE_LOCATION_ADDRESS = gql`
	mutation userAccountLocationAddressAddOrUpdate(
		$userId: ID!
		$locationAddressId: String
		$update: LocationAddressUpdate!
	) {
		userAccountLocationAddressAddOrUpdate(
			userId: $userId
			locationAddressId: $locationAddressId
			update: $update
		) {
			id
			parentId
			addressType
			fromDate
			toDate
			address {
				countryId
				address1
				address2
				municipality
				adminArea1Id
				adminArea1 {
					id
					field
					value
					displayName
					displayOrder
				}
				adminArea2Id
				adminArea2 {
					id
					field
					value
					displayName
					displayOrder
				}
				postalCode
				coordinate {
					latitude
					longitude
				}
			}
		}
	}
`;

/**
 * Hook to add or update a user's location address.
 * @param userId
 * @returns
 */
export const useAddOrUpdateLocationAddressMutation = (userId: string) => {
	const toast = useToast();
	const [locationAddOrUpdate, { loading, error }] = useMutation<
		Pick<Mutation, 'userAccountLocationAddressAddOrUpdate'>,
		MutationUserAccountLocationAddressAddOrUpdateArgs
	>(ADD_OR_UPDATE_LOCATION_ADDRESS, {
		refetchQueries: [{ query: LOCATIONADDRESSES, variables: { userId } }],
		update: (cache) => {
			cache.evict({
				id: `UserAccount:${userId}`,
				fieldName: 'locationAddresses',
			});
		},
	});
	const addOrUpdate = async (location: LocationAddressFormValues, locationId?: string) => {
		return locationAddOrUpdate({
			variables: {
				userId,
				locationAddressId: locationId,
				update: {
					...(location as LocationAddressUpdate),
					fromDate: location.fromDate ? format(location.fromDate, 'yyyy-MM-dd') : null,
					toDate: location.toDate ? format(location.toDate, 'yyyy-MM-dd') : null,
				},
			},
		})
			.then(async (res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				toast.push('Location information updated successfully.', {
					variant: 'success',
				});
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	return { addOrUpdate, loading, error };
};

export const LOCATIONADDRESSES = gql`
	${GEOADDRESS_FIELDS}
	query account($userId: ID!) {
		account(userId: $userId) {
			id
			siteUserId
			locationAddresses {
				id
				parentId
				addressType
				fromDate
				toDate
				address {
					...GeoAddressFields
				}
			}
		}
	}
`;

/**
 * Hook to get the user's location addresses.
 * @param userId
 * @returns
 */
export const useLocationAddressesQuery = (userId: string) => {
	const { data, loading, error } = useQuery<Pick<Query, 'account'>, QueryAccountArgs>(
		LOCATIONADDRESSES,
		{
			fetchPolicy: 'cache-first',
			variables: {
				userId,
			},
		}
	);

	return {
		siteUserId: data?.account?.siteUserId,
		locations:
			data?.account?.locationAddresses?.map((la) => ({
				id: la.id,
				address: la.address,
				addressType: la.addressType,
				fromDate: la?.fromDate ? zoneDateOnly(la?.fromDate) : null,
				toDate: la?.toDate ? zoneDateOnly(la.toDate) : null,
			})) ?? [],
		loading,
		error,
	};
};

/**
 * useLocationEditModal(userId) - Opens an edit/add location modal.
 *
 * @param {string} userId
 * @return {*}
 */
const useLocationEditModal = (userId: string) => {
	const { addOrUpdate } = useAddOrUpdateLocationAddressMutation(userId);
	const { showAddressInputPage } = useAddressInputPage();

	return {
		addOrEditLocation: (locationAddress?: Omit<LocationAddress, 'parentId'>) => {
			const title = locationAddress ? `Edit Address` : 'Add New Address';
			showAddressInputPage(addOrUpdate, title, locationAddress);
		},
	};
};

/**
 * useDeleteLocationModal(userId) - Confirmation modal to delete a location.
 *
 * @param {string} userId
 * @return {*}
 */
const useDeleteLocationModal = (userId: string) => {
	const { showModal } = useModal();
	const { deleteLocation } = useDeleteLocationAddressMutation(userId);
	return {
		deleteLocation: (locationAddressId: string) => {
			showModal({
				title: 'Are you sure?',
				content: (
					<ConfirmModalContent
						visual={
							<EmptyStateAvatar
								avatarProps={{ bgcolor: 'error.50' }}
								iconProps={{ color: 'error.500' }}
								icon={<ErrorOutlined />}
							/>
						}
						subheadline="Delete Address?"
						informativeContent="Do you really want to delete the address? This process cannot be undone."
					/>
				),
				actions: (
					<ModalActions>
						<ModalActionButton size="large" variant="outlined">
							Cancel
						</ModalActionButton>
						<ModalLoadingButton
							size="large"
							variant="contained"
							color="error"
							onClick={async () => await deleteLocation(locationAddressId)}>
							Delete
						</ModalLoadingButton>
					</ModalActions>
				),
			});
		},
	};
};

/**
 * useMakeLocationPrimaryModal(userId) - Confirmation Modal to make a location primary
 *
 * @param {string} userId
 * @return {*}
 */
const useMakeLocationPrimaryModal = (userId: string) => {
	const { showModal } = useModal();
	const { addOrUpdate } = useAddOrUpdateLocationAddressMutation(userId);

	return {
		makePrimary: (locationAddress: Omit<LocationAddress, 'parentId'>) => {
			const { id = undefined, ...update } = {
				...locationAddress,
				addressType: AddressType.Primary,
			};
			showModal({
				title: 'Are you sure?',
				content: (
					<ConfirmModalContent
						visual="/img/star-primary-large.svg"
						subheadline="Make Primary?"
						informativeContent="Do you really want to make this the primary address?"
					/>
				),
				actions: (
					<ModalActions>
						<ModalActionButton size="large" variant="outlined">
							Cancel
						</ModalActionButton>
						<ModalLoadingButton
							size="large"
							variant="contained"
							color="primary"
							onClick={async () => addOrUpdate(update, id)}>
							Make Primary
						</ModalLoadingButton>
					</ModalActions>
				),
			});
		},
	};
};

/**
 * Hook for loading the LocationInformation component
 *
 * @param {string} id
 * @return {*}
 */
const useLocationInformation = (userId: string) => {
	const {
		siteUserId,
		locations,
		loading: queryLoading,
		error: queryError,
	} = useLocationAddressesQuery(userId);
	const { hasPermission } = useSiteUser();
	const { user } = useSession();
	const canEdit =
		userId === user.userId ||
		(siteUserId && hasPermission(Permission.Site_User_U)) ||
		(!siteUserId && hasPermission(Permission.SocialUser_Account_U));

	return {
		locations,
		loading: queryLoading,
		error: queryError,
		canEdit: canEdit,
	};
};

/**
 * Displays the user's location addresses in Account Settings
 *
 * @param {{ userId: string }} { userId }
 * @return {*}  {React.JSX.Element}
 */
export const LocationInformation = ({ userId }: { userId: string }): React.JSX.Element => {
	const { locations, loading, error, canEdit } = useLocationInformation(userId);
	const { deleteLocation } = useDeleteLocationModal(userId);
	const { makePrimary } = useMakeLocationPrimaryModal(userId);
	const { addOrEditLocation } = useLocationEditModal(userId);

	return (
		<>
			<Card>
				<CardHeader
					title="Location Information"
					action={
						canEdit && (
							<IconButton edge="end" onClick={() => addOrEditLocation(undefined)}>
								<AddOutlined />
							</IconButton>
						)
					}
				/>
				<Divider />
				<CardContent>
					{error && <Alert error={error} />}
					{loading ? (
						<LocationInformationSkeleton />
					) : (
						locations && (
							<Box
								pt={1}
								display="grid"
								gap={2}
								gridTemplateColumns={{
									xs: 'repeat(auto-fill)',
									sm: 'repeat(auto-fill, 15rem)',
								}}>
								{[...locations]
									.sort((a) => (a.addressType === AddressType.Primary ? -1 : 0))
									.map((locationAddress) => (
										<LocationInformationItem
											key={locationAddress.id}
											disabled={canEdit}
											locationAddress={locationAddress}
											onEdit={addOrEditLocation}
											onDelete={deleteLocation}
											onMakePrimary={makePrimary}
										/>
									))}
							</Box>
						)
					)}
				</CardContent>
			</Card>
		</>
	);
};

const LocationInformationSkeleton = () => (
	<Box display="flex" flexWrap="wrap">
		{[...Array(3)].map((e, i) => (
			<Box key={i} mr={1} mb={1}>
				<Skeleton height="14rem" width="14rem" variant="rectangular" />
			</Box>
		))}
	</Box>
);
