import { EvolutionConnectLogoIcon } from 'components/ui/icons';
import { Loading } from 'components/ui/loading';
import { SecondaryNav, SecondaryNavItem } from 'components/ui/secondary-nav';
import { EvoxReviewPageType } from 'pages/site/evox/review';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { PageError } from 'utils/errors';
import { Permission } from 'utils/permissions';
import { useSession } from 'utils/session';
import { useSiteUser } from 'utils/useSiteUser';

/**
 * Routes for the roles page and its sub-pages
 *
 * @return {*}  {React.JSX.Element}
 */
export const SiteUserOutlet = (): React.JSX.Element => {
	const { user } = useSession();
	const { hasPermission, loading } = useSiteUser();
	if (!user.siteUserId) {
		throw new PageError('Restricted access');
	}

	if (loading) {
		return <Loading />;
	}
	return (
		<>
			<SecondaryNav
				title="Site User Tools"
				hasPermission={hasPermission}
				icon={<EvolutionConnectLogoIcon className="fill-neutral-500" />}
				navigationItems={SiteUserNavigation}
			/>
			<Outlet />
		</>
	);
};

/**
 * SiteUserNavigation - the full list of items for a site user's navigation.
 * @type {SecondaryNavItem[]} */
export const SiteUserNavigation: SecondaryNavItem[] = [
	{
		link: '/site',
		text: 'Dashboard',
		end: true,
	},
	{
		link: './search',
		text: 'Search',
		access: [
			Permission.Site_User_R,
			Permission.Site_Inv_R,
			Permission.SocialUser_Account_R,
			Permission.SocialUser_Profile_R,
			Permission.Site_OrgAcct_R,
			Permission.Site_OrgProfile_R,
		],
	},
	{
		link: 'users',
		text: 'Site Users',
		access: [Permission.Site_Inv_R, Permission.Site_User_R],
	},
	{
		link: 'roles',
		text: 'Site Roles',
		access: [Permission.Site_Role_R],
	},
	{
		link: `evox/review/${EvoxReviewPageType.GeneralChats}`,
		text: 'EVO-X Review',
		access: [Permission.SocialUser_EvoX_R],
	},
];
