import { gql, useQuery } from '@apollo/client';
import { useToast } from 'components/ui/toast';
import {
	ClaimSummaryRoleType,
	ClaimSummarySubset,
	LegacyUserAdjustmentCompanyWithMetrics,
	Query,
	QueryLegacyClaimSummariesArgs,
} from 'middleware-types';

export const useLinkedCompanies = () => {
	const { data, error, loading } = useQuery<
		Pick<Query, 'userLegacyAdjustmentCompaniesWithMetrics'>
	>(
		gql`
			query userLegacyAdjustmentCompaniesWithMetrics {
				userLegacyAdjustmentCompaniesWithMetrics {
					adjustmentCompaniesWithMetrics {
						adjCoName
						adjCoLogo
						systemUrl
						dbid
						userId
						userRole
						clientCompany {
							companyName
							companyCity
							companyState
						}
						userClaimCount
						userOverdueClaimCount
						userImpendingClaimCount
						userRecentlyReceivedClaimCount
						companyClaimCount
						companyOverdueClaimCount
						companyImpendingClaimCount
						companyRecentlyReceivedClaimCount
					}
				}
			}
		`,
		{
			// checks cache and fetches (important for updating queries after updating a role)
			fetchPolicy: 'cache-and-network',
			onError: () => {
				console.log(error);
			},
		}
	);

	return {
		companies:
			data?.userLegacyAdjustmentCompaniesWithMetrics.adjustmentCompaniesWithMetrics || [],
		error,
		loading,
	};
};

export const useClaimSummaries = (
	company: LegacyUserAdjustmentCompanyWithMetrics,
	roleType: ClaimSummaryRoleType,
	subset: ClaimSummarySubset
) => {
	const toast = useToast();

	const { data, loading } = useQuery<
		Pick<Query, 'legacyClaimSummaries'>,
		QueryLegacyClaimSummariesArgs
	>(
		gql`
			query LegacyClaimSummaries(
				$legacySystemId: Int!
				$legacyUserId: Int!
				$roleType: ClaimSummaryRoleType!
				$subset: ClaimSummarySubset!
				$count: Int!
			) {
				legacyClaimSummaries(
					legacySystemId: $legacySystemId
					legacyUserId: $legacyUserId
					roleType: $roleType
					subset: $subset
					count: $count
				) {
					claimSummaries {
						claimId
						claimFileId
						receivedDate
						dueDate
						insuredCompany
						insuredFirstName
						insuredLastName
						lossCity
						lossState
						company
						companyFileId
					}
				}
			}
		`,
		{
			variables: {
				count: 25,
				legacySystemId: company.dbid,
				legacyUserId: company.userId,
				roleType,
				subset,
			},
			onError: () => {
				toast.push('Unable to load jobs', {
					variant: 'error',
				});
			},
		}
	);

	return {
		claims: data?.legacyClaimSummaries.claimSummaries ?? [],
		loading,
	};
};
