import { useQuery } from '@apollo/client';
import {
	Query,
	QueryConversationsWithParticipantsArgs,
	ConversationApiEntityType,
} from 'middleware-types';
import { CONVERSATIONS_WITH_PARTICIPANTS } from 'pages/app/conversation-emblem-redirect';

export const useQueryConversations = (entityId: string, orgId: string) => {
	const { data } = useQuery<
		Pick<Query, 'conversationsWithParticipants'>,
		QueryConversationsWithParticipantsArgs
	>(CONVERSATIONS_WITH_PARTICIPANTS, {
		fetchPolicy: 'no-cache',
		variables: {
			entityType: ConversationApiEntityType.Organizations,
			entityId: orgId,
			entityIds: [entityId],
		},
	});

	return {
		conversations: data?.conversationsWithParticipants.items ?? [],
	};
};
