import { LoadingButton } from '@mui/lab';
import { Stack } from '@mui/material';
import { TextField } from 'components/ui/fields';
import { ColorField, ColorOption, defaultColorFieldOptions } from 'components/ui/fields/colorField';
import { ModalActionButton, ModalActions, ModalContent, useModal } from 'components/ui/modal';
import { Formik, FormikProps } from 'formik';
import { OrgDashboardConfigurationPatch } from 'middleware-types';
import { theme } from 'utils/theme';
import { useValidation } from 'utils/useValidation';
import { useOrgDashboardSettings, usePatchOrgDashboardSettings } from './orgDashboardSettingsHooks';

const colorOptions: ColorOption[] = [
	{
		name: 'Charcoal',
		hex: theme.palette.neutral[700],
	},
	{
		name: 'Navy',
		hex: theme.palette.primary.main,
	},
	...defaultColorFieldOptions,
];

export const OrgDashboardSettingsModal = ({ orgId }: { orgId: string }) => {
	const { settings } = useOrgDashboardSettings(orgId);
	const { patchOrgDashboardSettings } = usePatchOrgDashboardSettings();
	const { closeModal } = useModal();

	const onSubmit = async (values: OrgDashboardConfigurationPatch) => {
		patchOrgDashboardSettings(orgId, values).then((success) => {
			if (success) closeModal();
		});
	};

	const { schema } = useValidation('OrganizationDashboardConfigurationPatch');

	const initialValues: OrgDashboardConfigurationPatch = {
		primaryColor: settings?.primaryColor ?? '',
		secondaryColor: settings?.secondaryColor ?? '',
		title: settings?.title ?? '',
		subTitle: settings?.subTitle ?? '',
	};

	return (
		<Formik<OrgDashboardConfigurationPatch>
			onSubmit={onSubmit}
			validationSchema={schema}
			initialValues={initialValues}
			enableReinitialize>
			{(props: FormikProps<OrgDashboardConfigurationPatch>) => (
				<>
					<ModalContent>
						<Stack spacing={3} pb={1}>
							<TextField label="Title" required name="title" fullWidth />
							<TextField label="Subtitle" required name="subTitle" fullWidth />
							<ColorField
								label="Primary Color"
								name="primaryColor"
								required
								fullWidth
								helperText="This will be the color of the banner at the top of the page."
								options={colorOptions}
								defaultColorName="Charcoal"
							/>
							<ColorField
								select
								label="Secondary Color"
								name="secondaryColor"
								required
								fullWidth
								helperText="This will be the color of the card underlines."
								options={colorOptions}
								defaultColorName="Navy"
							/>
						</Stack>
					</ModalContent>
					<ModalActions>
						<ModalActionButton variant="outlined">Cancel</ModalActionButton>
						<LoadingButton
							type="submit"
							color="primary"
							variant="contained"
							loading={props.isSubmitting}
							disabled={!props.isValid || !props.dirty || props.isSubmitting}
							onClick={() => {
								props.submitForm();
							}}>
							Save
						</LoadingButton>
					</ModalActions>
				</>
			)}
		</Formik>
	);
};

/* const ColorMenuItem = ({ option, isDefault }: { option: ColorOption; isDefault: boolean }) => {
	return (
		<Stack direction="row" spacing={1.5} alignItems="center">
			<Box height={25} width={25} borderRadius="50%" sx={{ backgroundColor: option.hex }} />
			<ListItemText primary={option.name + (isDefault ? ' (Default)' : '')} />
		</Stack>
	);
};
*/
