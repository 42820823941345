import { OrgEmblemAvatar } from 'components/ui/emblem/emblem-avatar';
import { useEmblem } from 'components/ui/emblem/hooks';
import { SecondaryNav, SecondaryNavItem } from 'components/ui/secondary-nav';
import { EmblemEntityType } from 'middleware-types';
import { Permission } from 'utils/permissions';
import { useSession } from 'utils/session';
import { useAssociateUser } from 'utils/useAssociateUser';
import { useOrgBadgeCounts } from 'utils/useBadgeCounts';
import { useSiteUser } from 'utils/useSiteUser';

/**
 * useAssociateUserNavigationItems - hook that returns the full list of items for a standard user's organization navigation.
 *  @type {SecondaryNavItem[]} */
export const useAssociateUserNavigationItems = (orgId: string, isPrivate?: boolean) => {
	const { inboundRequestsBadgeCount, conversationsBadgeCount } = useOrgBadgeCounts(orgId);

	const associateUserNavigationItems: SecondaryNavItem[] = [
		{
			link: 'dashboard',
			text: 'Dashboard',
			access: Permission.Org_Member,
		},
		{
			link: 'profile',
			text: 'Profile',
			access: isPrivate ? Permission.Org_Profile_U : undefined,
			all: isPrivate,
		},
		{
			link: 'network/connections',
			text: 'Network',
			access: [Permission.Org_Social_Network_R],
			badgeCount: inboundRequestsBadgeCount,
		},
		{
			link: 'conversations',
			text: 'Conversations',
			access: [Permission.Org_Messages_R],
			badgeCount: conversationsBadgeCount,
		},
		{
			link: 'associates',
			text: 'Associates',
			access: [Permission.Org_Assoc_R, Permission.Org_Assoc_Inv_R],
		},
		{
			link: 'roles',
			text: 'Organization Roles',
			access: [Permission.Org_Assoc_Roles_R],
		},
		{
			link: 'documents',
			text: 'Documents',
			access: Permission.Org_Member,
		},
		{
			link: 'account/',
			text: 'Account Settings',
			access: [Permission.Org_Account_R],
		},
	];

	return associateUserNavigationItems;
};

/**
 * useSiteUserNavigationItems - hook that returns the full list of items for a site user's organization navigation.
 *  @type {SecondaryNavItem[]} */
export const useSiteUserNavigationItems = (isPrivate?: boolean) => {
	const siteUserNavigationItems: SecondaryNavItem[] = [
		{
			link: 'dashboard',
			text: 'Dashboard',
			access: [Permission.Site_OrgDashboard_R],
		},
		{
			link: 'profile',
			text: 'Profile',
			access: isPrivate
				? [Permission.Site_OrgProfile_R, Permission.Site_OrgProfile_U]
				: [Permission.Site_OrgProfile_R],
			all: isPrivate,
		},
		{
			link: 'conversations',
			text: 'Conversations',
			access: [Permission.Site_OrgMsgs_R],
		},
		{
			link: 'associates',
			text: 'Associates',
			access: [Permission.Site_OrgAssoc_R, Permission.Site_OrgAssocInv_R],
		},
		{
			link: 'roles',
			text: 'Organization Roles',
			access: [Permission.Site_OrgRoles_R],
		},
		{
			link: 'documents',
			text: 'Documents',
			access: [Permission.Site_OrgFile_R, Permission.Site_OrgFile_Sens_R],
		},
		{
			link: 'account',
			text: 'Account Settings',
			access: [Permission.Site_OrgAcct_R],
		},
	];

	return siteUserNavigationItems;
};

export const OrgNavigation = ({ orgId }: { orgId: string }) => {
	const { user } = useSession();
	const { hasPermission: hasAssociateUserPermission, loading: associateUserLoading } =
		useAssociateUser(orgId);
	const { hasPermission: hasSiteUserPermission, loading: siteUserLoading } = useSiteUser();
	const { emblem, loading: emblemLoading } = useEmblem(orgId, EmblemEntityType.Organization);
	const associateUserNavigationItems = useAssociateUserNavigationItems(orgId, emblem?.private);
	const siteUserNavigationItems = useSiteUserNavigationItems(emblem?.private);

	const isSiteUser = !!user.siteUserId;
	const userLoading = (isSiteUser && siteUserLoading) || (!isSiteUser && associateUserLoading);

	if (userLoading || emblemLoading) return <></>;

	return (
		<SecondaryNav
			title={emblem?.displayName}
			hasPermission={isSiteUser ? hasSiteUserPermission : hasAssociateUserPermission}
			icon={<OrgEmblemAvatar id={orgId} size={32} />}
			navigationItems={isSiteUser ? siteUserNavigationItems : associateUserNavigationItems}
		/>
	);
};
