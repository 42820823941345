import { Close, RadioButtonChecked, RadioButtonUnchecked } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
	Box,
	Button,
	ButtonBase,
	Divider,
	FormControl,
	FormControlLabel,
	IconButton,
	Radio,
	RadioGroup,
	Stack,
	Typography,
} from '@mui/material';
import { Formik, FormikProps } from 'formik';
import { FileAccessLevelChangeRequest, FileInstanceInformation } from 'middleware-types';
import { useState } from 'react';
import {
	AccessLevelDescription,
	AccessLevelIcon,
	getAccessLevelOptions,
	useDefaultAccessLevel,
} from '../components/access-level';
import { useAccessLevelChange } from '../hooks/files/use-access-level-change';
import { useInstanceAccessLevelChange } from '../hooks/files/use-instance-access-level-change';
import { useVersionHistoryContext } from '../components/version-history-provider';

interface AccessLevelModalProps {
	file: FileInstanceInformation;
	onClose: () => void;
}

export const AccessLevelModal = ({ file, onClose }: AccessLevelModalProps) => {
	const { isVersionHistory } = useVersionHistoryContext();
	const defaultLevel = useDefaultAccessLevel();
	const accessLevelOptions = getAccessLevelOptions(file.allowedAccessLevelChangesList);
	const [applyMethod, setApplyMethod] = useState<'all' | 'current'>('current');

	const changeAccessLevel = useAccessLevelChange();
	const changeInstanceAccessLevel = useInstanceAccessLevelChange();

	const initialValues: FileAccessLevelChangeRequest = {
		newAccessLevel: file.accessLevel,
	};

	const onSubmit = async (values: FileAccessLevelChangeRequest) => {
		let success = false;
		if (applyMethod === 'all') success = await changeAccessLevel(file.fileId, values);
		else success = await changeInstanceAccessLevel(file.id, values);
		if (success) onClose();
	};

	return (
		<Formik<FileAccessLevelChangeRequest> initialValues={initialValues} onSubmit={onSubmit}>
			{({
				values,
				setFieldValue,
				isSubmitting,
				isValid,
				dirty,
				submitForm,
			}: FormikProps<FileAccessLevelChangeRequest>) => (
				<Stack>
					<Stack
						px={2}
						py={1}
						direction="row"
						alignItems="center"
						justifyContent="space-between"
						spacing={1}>
						<Typography variant="h3" noWrap>
							{file.fileName}
							{isVersionHistory && ` (v${file.versionNumber})`} Privacy Settings
						</Typography>
						<IconButton onClick={onClose}>
							<Close />
						</IconButton>
					</Stack>
					<Divider />
					<Stack p={2.5} spacing={3}>
						<Stack spacing={2}>
							<Typography variant="h5">
								What level of privacy should this document have? *
							</Typography>
							{accessLevelOptions.map(({ option, disabled }) => {
								const selected = values.newAccessLevel === option;
								const isDefault = defaultLevel === option;
								return (
									<ButtonBase
										disabled={disabled}
										key={option}
										onClick={() => setFieldValue('newAccessLevel', option)}
										sx={{
											borderRadius: 2,
											border: '1px solid',
											bgcolor: selected ? 'primary.light' : undefined,
											borderColor: selected
												? 'primary.main'
												: disabled
												? 'neutral.100'
												: 'neutral.200',
										}}>
										<Stack
											width="100%"
											direction="row"
											alignItems="center"
											p={2}
											spacing={2}>
											{selected ? (
												<RadioButtonChecked color="primary" />
											) : (
												<RadioButtonUnchecked
													sx={{
														color: disabled
															? 'neutral.300'
															: 'neutral.500',
													}}
												/>
											)}
											<AccessLevelIcon
												level={option}
												sx={{
													fontSize: 32,
													color: disabled
														? 'neutral.300'
														: 'primary.main',
												}}
											/>
											<Box flex={1} textAlign="left">
												<Typography
													variant="h5"
													mb={0.5}
													color={disabled ? 'neutral.300' : undefined}>
													{option} {isDefault && '(Default)'}
												</Typography>
												<Typography
													variant="subtitle1"
													lineHeight={1.25}
													color={disabled ? 'neutral.300' : undefined}>
													<AccessLevelDescription level={option} />
												</Typography>
											</Box>
										</Stack>
									</ButtonBase>
								);
							})}
						</Stack>
						<Stack spacing={0.5}>
							<Typography variant="h5">
								How should this change be applied? *
							</Typography>
							<FormControl>
								<RadioGroup
									value={applyMethod}
									onChange={(_e, value) =>
										setApplyMethod(value as 'all' | 'current')
									}>
									<FormControlLabel
										value="current"
										control={<Radio />}
										label={`${
											isVersionHistory ? 'This' : 'Current'
										} file version only`}
									/>
									<FormControlLabel
										value="all"
										control={<Radio />}
										label="All file versions"
									/>
								</RadioGroup>
							</FormControl>
						</Stack>
					</Stack>
					<Divider />
					<Stack direction="row" justifyContent="flex-end" px={2} py={1.5} spacing={1.5}>
						<Button size="large" variant="outlined" onClick={onClose}>
							Cancel
						</Button>
						<LoadingButton
							size="large"
							variant="contained"
							color="primary"
							loading={isSubmitting}
							disabled={!isValid || !dirty}
							onClick={submitForm}>
							Save
						</LoadingButton>
					</Stack>
				</Stack>
			)}
		</Formik>
	);
};
