import { Box, BoxProps } from '@mui/material';
import { Helmet } from 'react-helmet-async';

interface PageContentProps extends BoxProps {
	mobileNoPadding?: boolean;
}

export const PageContent = ({ mobileNoPadding = false, sx, ...props }: PageContentProps) => {
	return (
		<Box
			id="main-content"
			component="main"
			display="flex"
			height="100%"
			flex="1 1 auto"
			flexDirection="column"
			overflow="auto"
			px={{ xs: mobileNoPadding ? 0 : 1.5, sm: 3 }}
			py={{ xs: 1.5, sm: 3 }}
			sx={{ scrollbarGutter: 'stable', ...sx }}
			{...props}
		/>
	);
};

/**
 * Sets the page title for the browser.
 */
export const PageTitle = ({ title }: { title?: string }) => {
	return (
		<Helmet>
			<title>Evolve {title && `- ${title}`}</title>
		</Helmet>
	);
};
