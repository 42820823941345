import {
	Avatar,
	Box,
	Card,
	CardHeader,
	Chip,
	Divider,
	IconButton,
	List,
	ListItem,
	ListItemAvatar,
	ListItemButton,
	ListItemText,
	Menu,
	MenuItem,
	Skeleton,
	Typography,
} from '@mui/material';
import { AddOutlined, CheckCircleOutlined } from '@mui/icons-material';
import { createElement, useState } from 'react';
import { Flag } from 'components/ui/flag';
import { ModalActions, ModalActionButton, useModal, ModalContent } from 'components/ui/modal';
import { useUsaLicensing } from './usa/modal';
import { LicensingProvenance, useAdjusterLicenseSummariesQuery } from './hooks';
import { useCountries } from 'utils/useCountries';
import { UserProfileAspectType, usePrivacy } from 'utils/usePrivacy';
import { useCanLicensing } from './can/modal';

export type CountryAction = {
	Add: () => void;
	ContextMenu?: (props: { userId: string; provenance: LicensingProvenance }) => React.JSX.Element;
};

/**
 * useCountryActions(userId) - this hook merges all supported countries individual API's into
 * a common api that can be called from the summary components
 *
 * @param {string} userId
 * @return {*}
 */
const useCountryActions = (userId: string): { [key: string]: CountryAction } => {
	const USA = useUsaLicensing(userId);
	const CAN = useCanLicensing(userId);
	return {
		USA: USA,
		CAN: CAN,
	};
};

/**
 * LicensingInformation is the licensing card in profile
 *
 * @param {{ userId: string }} props
 * @return {*}
 */
export const LicensingInformation = (props: { userId: string }) => {
	const [addCountryAnchorEl, setAddCountryAnchorEl] = useState<null | HTMLElement>(null);
	const { countries, countriesLookupMapById } = useCountries();
	const { summaries } = useAdjusterLicenseSummariesQuery(props.userId);
	const { showModal } = useModal();
	const countryActions = useCountryActions(props.userId);
	const { hasAccess } = usePrivacy(props.userId);

	const handleCountryLicenseClick = (licenseText: string | undefined) => {
		showModal({
			title: 'Quick View: Licensing',
			maxWidth: 'lg',
			content: (
				<ModalContent style={{ maxHeight: '60vh' }}>
					<Typography component="pre" fontFamily="monospace">
						{licenseText}
					</Typography>
				</ModalContent>
			),
			actions: (
				<ModalActions>
					<ModalActionButton size="large" variant="contained" color="primary">
						Close
					</ModalActionButton>
				</ModalActions>
			),
		});
	};

	/* Filter out countries user has already added. */
	const availableCountryLicensesToAdd = countries.filter((c) =>
		summaries?.every((countryLicense) => countryLicense.countryId !== c.id)
	);

	if (
		!hasAccess(UserProfileAspectType.AlterLicensing, UserProfileAspectType.ImportLicensing) &&
		summaries.length === 0
	) {
		return <></>;
	}

	return (
		<Card>
			<CardHeader
				title="Licenses"
				action={
					hasAccess(
						UserProfileAspectType.AlterLicensing,
						UserProfileAspectType.ImportLicensing
					) &&
					availableCountryLicensesToAdd.length > 0 && (
						<>
							<IconButton
								edge="end"
								onClick={(event) => {
									setAddCountryAnchorEl(null);
									setAddCountryAnchorEl(event.currentTarget);
								}}>
								<AddOutlined />
							</IconButton>
							<Menu
								anchorEl={addCountryAnchorEl}
								open={Boolean(addCountryAnchorEl)}
								onClose={() => setAddCountryAnchorEl(null)}>
								{availableCountryLicensesToAdd.map((c) => (
									<MenuItem
										key={c.id}
										onClick={() => {
											setAddCountryAnchorEl(null);
											countryActions[c.iso3CountryCode].Add();
										}}>
										<Flag code={c.iso2CountryCode} />
										&nbsp;&nbsp;{c.name}
									</MenuItem>
								))}
							</Menu>
						</>
					)
				}
			/>
			<Divider />
			<List disablePadding>
				{summaries.map((s) => {
					const country = countriesLookupMapById.get(s.countryId);
					if (!country) return <Skeleton key={s.countryId} height="5rem" width="100%" />;
					const provChip =
						s.licensingProvenance != 'ManuallyEntered' ? (
							<>
								&nbsp;&nbsp;
								<Chip
									color="primary"
									size="small"
									label={s.licensingProvenance.toUpperCase()}
									icon={<CheckCircleOutlined />}
								/>
							</>
						) : undefined;
					// color: rgba(0, 0, 0, 0.6);
					const licenseeText = s.licenseHoldersName ? (
						<Typography
							sx={{ color: 'neutral.400' }}
							variant="body2"
							display="inline"
							fontStyle="italic">
							&nbsp;&nbsp;{s.licenseHoldersName}
						</Typography>
					) : undefined;
					const contextMenu = countryActions[country.iso3CountryCode]?.ContextMenu;
					return (
						<ListItem
							key={s.countryId}
							disablePadding
							disableGutters
							secondaryAction={
								contextMenu && (
									<Box marginRight="0.5rem">
										{createElement(contextMenu, {
											userId: props.userId,
											provenance:
												s.licensingProvenance as LicensingProvenance,
										})}
									</Box>
								)
							}>
							<ListItemButton
								style={{ opacity: 1 }}
								disabled={!hasAccess(UserProfileAspectType.ViewLicensingDetails)}
								onClick={() => handleCountryLicenseClick(s.adjusterLicensingText)}>
								<ListItemAvatar>
									<Avatar
										sx={{
											'.flag': {
												width: '64px',
												height: '48px',
											},
										}}>
										{country && <Flag code={country.iso2CountryCode} />}
									</Avatar>
								</ListItemAvatar>
								<ListItemText
									primary={
										<>
											{country?.name}
											{provChip}
											{licenseeText}
										</>
									}
									secondary={`${s.numLicenses} Licenses • ${s.numActiveLicenses} Active • ${s.numExpiredLicenses} Expired • ${s.numExpiredWithin60DaysLicenses} Expiring Within Sixty Days`}
								/>
							</ListItemButton>
						</ListItem>
					);
				})}
			</List>
		</Card>
	);
};
