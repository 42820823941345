import { TabContext, TabPanel } from '@mui/lab';
import { Card, CardContent, CardHeader, Tab, Tabs, Stack, Typography, Chip } from '@mui/material';
import { useOrgId } from 'components/pages/org/outlet';
import { PageTitle } from 'components/ui/page';
import { useState } from 'react';
import { OrgConnectionsOrRequestsList } from './OrgConnectionsOrRequestsList';
import { ConnectionPageType } from 'middleware-types';
import { useOrgConnectionsOrRequestsQuery } from './OrgConnectionsOrRequests.graphql';
import { Search } from 'components/ui/search';
import { useIsMediumOrSmallerScreen, useIsMobile } from 'utils/useScreenSize';

/**
 * Displays a tabbed panel for displaying organization connections.
 */
export const OrgConnectionsScreen = () => {
	const orgId = useOrgId();
	const [tabPage, setTabPage] = useState<ConnectionPageType>(ConnectionPageType.Requests);
	const [searchFor, setSearchFor] = useState('');
	const { count: orgRequestsCount } = useOrgConnectionsOrRequestsQuery(
		ConnectionPageType.Requests,
		orgId
	);
	const { count: orgConnectionsCount } = useOrgConnectionsOrRequestsQuery(
		ConnectionPageType.MyConnections,
		orgId
	);
	const { count: orgSentRequestsCount } = useOrgConnectionsOrRequestsQuery(
		ConnectionPageType.RequestsISent,
		orgId
	);

	const isMediumOrSmallerScreen = useIsMediumOrSmallerScreen();
	const isMobile = useIsMobile();

	const tabsConfig = [
		{
			label: 'Requests',
			count: orgRequestsCount ? orgRequestsCount : undefined,
			value: ConnectionPageType.Requests,
		},
		{
			label: 'My Connections',
			count: orgConnectionsCount ? orgConnectionsCount : undefined,
			value: ConnectionPageType.MyConnections,
		},
		{
			label: 'Sent Requests',
			count: orgSentRequestsCount ? orgSentRequestsCount : undefined,
			value: ConnectionPageType.RequestsISent,
		},
	];

	// Handler for search query change
	const handleSearch = (str: string): void => {
		setSearchFor(str);
	};

	// Handler for tab change and search query reset
	const handleTabChange = (_, newValue) => {
		setTabPage(newValue);
		setSearchFor('');
	};

	return (
		<>
			<PageTitle title="Connections" />
			<Card
				sx={{
					height: '100%',
					display: 'flex',
					flexDirection: 'column',
					margin: isMediumOrSmallerScreen ? '-12px -28px' : undefined,
				}}>
				<CardHeader title="Connections" />
				<CardContent
					sx={{
						flex: 1,
						display: 'flex',
						flexDirection: 'column',
						padding: '0',
					}}>
					<TabContext value={tabPage}>
						<Tabs value={tabPage} onChange={handleTabChange} sx={{ paddingX: '16px' }}>
							{tabsConfig.map((tab) => (
								<Tab
									key={tab.value}
									label={
										<Stack flexDirection="row" alignItems="center" gap={1}>
											<Typography
												color={
													tabPage !== tab.value
														? 'neutral.500'
														: undefined
												}>
												{tab.label}
											</Typography>
											{(!isMobile || tab.label === 'Requests') &&
												tab.count && (
													<Chip
														variant="filled"
														label={tab.count}
														size="small"
														sx={{
															bgcolor:
																tabPage !== tab.value
																	? 'neutral.50'
																	: undefined,
															color:
																tabPage !== tab.value
																	? 'neutral.500'
																	: undefined,
														}}
													/>
												)}
										</Stack>
									}
									value={tab.value}
								/>
							))}
						</Tabs>
						{tabPage !== ConnectionPageType.Requests && (
							<Stack width="100%" flexDirection="column" paddingTop={2} px={2}>
								<Search onChange={handleSearch} placeholder="Search" />
							</Stack>
						)}
						{tabsConfig.map((tab) => (
							<TabPanel key={tab.value} value={tab.value} sx={{ flex: 1 }}>
								<OrgConnectionsOrRequestsList
									orgId={orgId}
									pageType={tab.value}
									searchFor={searchFor}
								/>
							</TabPanel>
						))}
					</TabContext>
				</CardContent>
			</Card>
		</>
	);
};
