import { QuickLink } from 'middleware-types';
import { useDownloadUrl } from 'utils/fileUtils';

export const useQuickLinkIconUrl = (quickLink: QuickLink) => {
	const fileId = quickLink.iconFile?.currentInstance?.id;
	const { data } = useDownloadUrl({
		skip: !fileId,
		variables: { fileInstanceId: fileId!, sasUrlOnly: true },
	});
	return data?.getFileInstanceDownloadUrl.downloadUrl;
};
