import { gql, useLazyQuery } from '@apollo/client';
import { ActivationStatus, OrgSearchResult } from 'middleware-types';
import { EntitySearchFormValues } from 'pages/site/system-search/system-search';

/* Query for getting a list of users from a peopleSearch. */
export const ORGSYSTEMSEARCH = gql`
	query orgSystemSearch(
		$searchText: String!
		$activationStatus: ActivationStatus
		$location: String
	) {
		orgSystemSearch(
			searchText: $searchText
			activationStatus: $activationStatus
			location: $location
		) {
			id
			organizationEmblem {
				id
				displayName
				handle
			}
			legalName
			displayLocation
			systemEmailAddress
			deactivated
		}
	}
`;

/**
 *  Org search row props.
 */
type OrgSearchRowProps = OrgSearchResult & {
	status: 'Active' | 'Inactive' | undefined;
	refetch: () => void;
	userType?: string;
};

/**
 * useOrgSearch() is a custom hook to handle all the data-loading
 * and processing for the org system search.
 *
 * @return {*}  {{
 * 	submit: (values: PeopleSearchFormValues) => Promise<void>;
 * 	initialValues: PeopleSearchFormValues;
 * }}
 */
export const useOrgSystemSearch = () => {
	const [getOrgSearchResults, { loading, data, error, refetch }] = useLazyQuery(ORGSYSTEMSEARCH, {
		fetchPolicy: 'cache-and-network',
	});

	// Performs the search when the Search button, magnifying icon, or enter is pressed.
	const search = async (values: EntitySearchFormValues) => {
		// Prevent searching if the search text is empty.
		if (!values.searchText) {
			return;
		}

		const activationStatus = values.activationStatus.filter((v) => v) as ActivationStatus[];

		await getOrgSearchResults({
			variables: {
				...values,
				activationStatus: activationStatus.length === 1 ? activationStatus[0] : undefined,
			},
		});
	};

	const rows: OrgSearchRowProps[] | undefined = data?.orgSystemSearch.map((result) => ({
		...result,
		status: result?.deactivated ? 'Inactive' : 'Active',
		refetch: () => (refetch ? refetch() : undefined),
	}));

	return { search, loading, error, rows };
};
