import { Divider, Grid, Stack, Typography } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import { LegacyUserAdjustmentCompanyWithMetrics } from 'middleware-types';
import {
	CompanyRoleCategory,
	getCompanyJobsText,
	getCompanyRoleCategory,
	getCompanyRolePermissions,
	getMyJobsText,
} from 'utils/companyRoles';
import { theme } from 'utils/theme';

const InfoRow = ({
	variant,
	label,
	value,
	divider = true,
}: {
	variant?: 'header' | 'error';
	label: string;
	value: number | null | undefined;
	divider?: boolean;
}) => {
	let textVariant: undefined | Variant = undefined;
	let textColor: undefined | string = undefined;
	let backgroundColor: undefined | string = undefined;
	switch (variant) {
		case 'header':
			textVariant = 'h5';
			textColor = 'white';
			backgroundColor = theme.palette.neutral[500];
			break;
		case 'error':
			textVariant = 'body1';
			textColor = theme.palette.error.dark;
			backgroundColor = theme.palette.error.light;
			break;
	}
	return (
		<>
			<Stack
				direction="row"
				justifyContent="space-between"
				sx={{ px: 1, py: 0.5, borderRadius: '2px', background: backgroundColor }}>
				<Typography color={textColor} variant={textVariant}>
					{label}
				</Typography>
				{value !== null && (
					<Typography color={textColor} variant={textVariant}>
						{value === undefined ? '--' : value}
					</Typography>
				)}
			</Stack>
			{variant !== 'header' && divider === true && <Divider />}
		</>
	);
};

const CompanyInfo = ({ company }: { company: LegacyUserAdjustmentCompanyWithMetrics }) => {
	const role = company.userRole;
	const roleCategory = getCompanyRoleCategory(role);

	const myJobsText = getMyJobsText(role);
	const companyJobsText = getCompanyJobsText(role);
	const { hasUserPermission, hasGroupPermission } = getCompanyRolePermissions(role);

	if (
		roleCategory === CompanyRoleCategory.ADMIN ||
		roleCategory === CompanyRoleCategory.CLERICAL
	) {
		return (
			<Grid container spacing={1}>
				<Grid item xs={6}>
					<InfoRow
						variant="header"
						label={myJobsText}
						value={hasUserPermission ? company.userClaimCount : undefined}
					/>
					<InfoRow
						variant={company.userOverdueClaimCount ? 'error' : undefined}
						label="Overdue"
						value={hasUserPermission ? company.userOverdueClaimCount : undefined}
					/>
					<InfoRow
						label="Due Next 3 Days"
						value={hasUserPermission ? company.userImpendingClaimCount : undefined}
					/>
					<InfoRow
						divider={false}
						label="Received Last 3 Days"
						value={
							hasUserPermission ? company.userRecentlyReceivedClaimCount : undefined
						}
					/>
				</Grid>
				<Grid item xs={6}>
					<InfoRow
						variant="header"
						label={companyJobsText}
						value={company.companyClaimCount}
					/>
					<InfoRow
						variant={company.companyOverdueClaimCount ? 'error' : undefined}
						label="Overdue"
						value={company.companyOverdueClaimCount}
					/>
					<InfoRow label="Due Next 3 Days" value={company.companyImpendingClaimCount} />
					<InfoRow
						divider={false}
						label="Received Last 3 Days"
						value={company.companyRecentlyReceivedClaimCount}
					/>
				</Grid>
			</Grid>
		);
	}

	if (roleCategory === CompanyRoleCategory.ADJUSTER) {
		return (
			<Grid container spacing={1}>
				<Grid item xs={12}>
					<InfoRow variant="header" label={myJobsText} value={company.userClaimCount} />
					<InfoRow
						variant={company.userOverdueClaimCount ? 'error' : undefined}
						label="Overdue"
						value={company.userOverdueClaimCount}
					/>
					<InfoRow label="Due Next 3 Days" value={company.userImpendingClaimCount} />
					<InfoRow
						divider={false}
						label="Received Last 3 Days"
						value={company.userRecentlyReceivedClaimCount}
					/>
				</Grid>
			</Grid>
		);
	}

	if (roleCategory === CompanyRoleCategory.CLAIMS_REP) {
		return (
			<Grid container spacing={1}>
				<Grid item xs={6}>
					<InfoRow variant="header" label={myJobsText} value={company.userClaimCount} />
					<InfoRow
						variant={company.userOverdueClaimCount ? 'error' : undefined}
						label="Overdue"
						value={company.userOverdueClaimCount}
					/>
					<InfoRow label="Due Next 3 Days" value={company.userImpendingClaimCount} />
					<InfoRow
						divider={false}
						label="Received Last 3 Days"
						value={company.userRecentlyReceivedClaimCount}
					/>
				</Grid>
				<Grid item xs={6}>
					<InfoRow
						variant="header"
						label={companyJobsText}
						value={hasGroupPermission ? company.companyClaimCount : undefined}
					/>
					<InfoRow
						variant={company.companyOverdueClaimCount ? 'error' : undefined}
						label="Overdue"
						value={hasGroupPermission ? company.companyOverdueClaimCount : undefined}
					/>
					<InfoRow
						label="Due Next 3 Days"
						value={hasGroupPermission ? company.companyImpendingClaimCount : undefined}
					/>
					<InfoRow
						divider={false}
						label="Received Last 3 Days"
						value={
							hasGroupPermission
								? company.companyRecentlyReceivedClaimCount
								: undefined
						}
					/>
				</Grid>
			</Grid>
		);
	}

	return null;
};

export default CompanyInfo;
