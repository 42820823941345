import {
	AutoAwesomeOutlined,
	Forum,
	ForumOutlined,
	HelpOutline,
	Home,
	HomeOutlined,
	Notifications,
	NotificationsOutlined,
	PeopleAlt,
	PeopleAltOutlined,
	SvgIconComponent,
} from '@mui/icons-material';
import { useSession } from 'utils/session';
import { useUserBadgeCounts } from 'utils/useBadgeCounts';
import { isDemo } from 'utils/utils';
import { EvolutionConnectLogoIcon } from '../icons';

// generate the navigation link data
export interface PrimaryNavLinkInfo {
	title: string;
	path?: string;
	onClick?: React.MouseEventHandler<HTMLElement>;
	icon: SvgIconComponent;
	activeIcon?: SvgIconComponent;
	isOpen?: boolean;
	badgeCount?: number;
	isExternal?: boolean;
	demoOnly?: true;
	socialUserOnly?: boolean;
	siteUserOnly?: boolean;
}

export const usePrimaryNavLinks = (
	notificationsOpen: boolean,
	onNotificationsClick: React.MouseEventHandler<HTMLElement>
) => {
	const { user } = useSession();
	const isSiteUser = Boolean(user.siteUserId);

	const { userBadgeCounts } = useUserBadgeCounts();

	const links: PrimaryNavLinkInfo[] = [
		{
			title: 'Home',
			path: '/',
			icon: HomeOutlined,
			activeIcon: Home,
			socialUserOnly: true,
		},
		{
			title: 'Network',
			path: '/app/network/connections',
			icon: PeopleAltOutlined,
			activeIcon: PeopleAlt,
			badgeCount: userBadgeCounts.inboundRequestsBadgeCount ?? 0,
			demoOnly: true,
			socialUserOnly: true,
		},
		{
			title: 'Conversations',
			path: '/app/conversations',
			icon: ForumOutlined,
			activeIcon: Forum,
			demoOnly: true,
			socialUserOnly: true,
			badgeCount: userBadgeCounts.conversationsBadgeCount ?? 0,
		},
		{
			title: 'Notifications',
			onClick: onNotificationsClick,
			icon: NotificationsOutlined,
			activeIcon: Notifications,
			isOpen: notificationsOpen,
			badgeCount: userBadgeCounts.notificationsBadgeCount ?? 0,
			socialUserOnly: true,
		},
		{
			title: 'Releases',
			path: 'https://www.evolution.global/product-updates',
			icon: AutoAwesomeOutlined,
			isExternal: true,
		},
		{
			title: 'Help',
			path: 'https://filetrac.freshdesk.com/support/home',
			icon: HelpOutline,
		},
	];

	return links.filter((link) => {
		if (link.demoOnly && !isDemo()) return false;
		if (link.socialUserOnly && isSiteUser) return false;
		if (link.siteUserOnly && !isSiteUser) return false;
		return true;
	});
};

// the link info for the site admin link
export const siteAdminLinkInfo: PrimaryNavLinkInfo = {
	title: 'Site Administration',
	path: '/site',
	icon: EvolutionConnectLogoIcon as SvgIconComponent,
};
