import { Logout, PersonAddAlt, RemoveCircleOutline } from '@mui/icons-material';
import {
	Box,
	Button,
	Divider,
	IconButton,
	List,
	ListItem,
	ListItemButton,
	Stack,
	Typography,
} from '@mui/material';
import { ConversationParticipant } from 'middleware-types';
import { useState } from 'react';
import { useIsMobile } from 'utils/useScreenSize';
import { useCommunicationsContext } from '../communications-provider';
import { useCommunicationsModal } from '../hooks/use-communications-modal';
import { AddParticipantsModal } from './add-participants-modal';
import { RemoveParticipantModal } from './remove-participant-modal';
import { EmblemAvatar } from 'components/ui/emblem/emblem-avatar';

interface ParticipantsPopupProps {
	participants: ConversationParticipant[];
	onClose: () => void;
	canEdit: boolean;
}

export const ParticipantsPopup = ({ participants, onClose, canEdit }: ParticipantsPopupProps) => {
	const isMobile = useIsMobile();
	const { showModal } = useCommunicationsModal();

	return (
		<Box>
			<List disablePadding>
				{participants.map((p) => (
					<ParticipantListItem
						key={p.entityId}
						participant={p}
						onClose={onClose}
						canEdit={canEdit}
					/>
				))}
			</List>
			{canEdit && (
				<>
					<Divider />
					<Box px={2} py={{ xs: 2, sm: 1 }}>
						<Button
							fullWidth
							startIcon={<PersonAddAlt />}
							variant={isMobile ? 'contained' : undefined}
							color={isMobile ? 'primary' : undefined}
							onClick={() => {
								onClose();
								showModal({
									title: 'Add Participants',
									content: (
										<AddParticipantsModal existingParticipants={participants} />
									),
								});
							}}>
							Add Participants
						</Button>
					</Box>
				</>
			)}
		</Box>
	);
};

interface ParticipantListItemProps {
	participant: ConversationParticipant;
	onClose: () => void;
	canEdit: boolean;
}

const ParticipantListItem = ({ participant, onClose, canEdit }: ParticipantListItemProps) => {
	const isMobile = useIsMobile();
	const { showModal } = useCommunicationsModal();
	const { entityId } = useCommunicationsContext();

	const [hovered, setHovered] = useState(false);

	return (
		<ListItem
			disableGutters
			disablePadding
			onMouseEnter={() => setHovered(true)}
			onMouseLeave={() => setHovered(false)}>
			<ListItemButton disableGutters>
				<Stack direction="row" alignItems="center" width="100%" spacing={1.5} pl={2} pr={1}>
					<EmblemAvatar emblem={participant.emblem} size={32} />
					<Typography variant="body2">{participant.emblem?.displayName}</Typography>
					<Box flex={1} />
					{canEdit &&
						(hovered || isMobile ? (
							<IconButton
								onClick={() => {
									onClose();
									showModal({
										title: 'Are you sure?',
										content: (
											<RemoveParticipantModal participant={participant} />
										),
									});
								}}>
								{entityId === participant.entityId ? (
									<Logout />
								) : (
									<RemoveCircleOutline />
								)}
							</IconButton>
						) : (
							<Box width={40} height={40} />
						))}
				</Stack>
			</ListItemButton>
		</ListItem>
	);
};
