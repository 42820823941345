import { useMutation, useQuery } from '@apollo/client';
import {
	AlternateEmail,
	ApartmentOutlined,
	EditOutlined,
	EmailOutlined,
	ErrorOutlined,
	PhoneOutlined,
	PowerSettingsNewOutlined,
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
	Box,
	Button,
	Card,
	CardContent,
	CardHeader,
	Chip,
	Divider,
	Grid,
	IconButton,
	Skeleton,
	Stack,
	Typography,
} from '@mui/material';
import { Alert } from 'components/ui/alert';
import { TextField } from 'components/ui/fields';
import { PhoneNumberField, PhoneNumberText } from 'components/ui/fields/phone';
import {
	ConfirmModalContent,
	ModalActionButton,
	ModalActions,
	ModalContent,
	ModalLoadingButton,
	useModal,
} from 'components/ui/modal';
import { PageTitle } from 'components/ui/page';
import { useToast } from 'components/ui/toast';
import { Formik, connect } from 'formik';
import gql from 'graphql-tag';
import { ActivationOperation, OrganizationAccount } from 'middleware-types';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { PageError, handleNoResponse, responseHasErrors } from 'utils/errors';
import { Permission } from 'utils/permissions';
import { useAssociateUser } from 'utils/useAssociateUser';
import { useSiteUser } from 'utils/useSiteUser';
import { useValidation } from 'utils/useValidation';
import { useCancellablePromise } from 'utils/utils';
// import { ORGPROFILE } from 'deprecated/organization/queries';
import { OrgAvatarUploadButton } from 'components/ui/emblem/avatar-upload-button';
import {
	Mutation,
	MutationOrganizationAccountUpdateArgs,
	OrganizationAccountUpdate,
	Query,
	QueryOrganizationAccountArgs,
} from 'middleware-types';
import { useUpdateOrganizationActivationMutation } from './basic-info-hooks';
import { EmptyStateAvatar } from 'components/ui/empty-state-avatar';

/**
 * useOrgBasicInformationQuery(orgId) - Hook for getting the organization's basic information
 *
 * @param {string} orgId
 * @return {*}
 */
export const useOrgBasicInformationQuery = (orgId: string) => {
	const { data, loading, error } = useQuery<
		Pick<Query, 'organizationAccount'>,
		QueryOrganizationAccountArgs
	>(
		gql`
			query OrgBasicInformation($organizationId: ID!) {
				organizationAccount(organizationId: $organizationId) {
					id
					legalName
					displayName
					handle
					deactivated
					private
					mainPhoneNumber {
						countryCode
						number
					}
					systemEmailAddress
					avatarFile {
						fileId
						file {
							id
							currentInstance {
								id
								cdnUrl
								fileName
								fileSize
								virusStatus
								uploadedUtc
							}
						}
					}
				}
			}
		`,
		{
			variables: { organizationId: orgId },
		}
	);

	return { account: data?.organizationAccount, loading, error };
};

/**
 * useOrgBasicInformationMutation(orgId) - Hook for updating the organization's basic information
 *
 * @param {string} orgId
 * @return {*}
 */
export const useOrgBasicInformationMutation = (orgId: string) => {
	const [orgAccountUpdate, { loading, error, reset }] = useMutation<
		Pick<Mutation, 'organizationAccountUpdate'>,
		MutationOrganizationAccountUpdateArgs
	>(ORG_ACCOUNT_UPDATE, {
		// The secondary nav bar uses the profile to fetch the org display name.
		// TODO: Change the secondary nav to use the org account to get the display name, then remove this refetch.
		// refetchQueries: [{ query: ORGPROFILE, variables: { organizationId: orgId } }],
		awaitRefetchQueries: true,
	});

	const updateOrgBasicInformation = (values: OrganizationAccountUpdate) => {
		return orgAccountUpdate({
			variables: {
				organizationId: orgId,
				organizationAccount: {
					...values,
				},
			},
		});
	};

	return { updateOrgBasicInformation, loading, error, reset };
};

const ORG_ACCOUNT_UPDATE = gql`
	mutation OrgAccountUpdate(
		$organizationId: ID!
		$organizationAccount: OrganizationAccountUpdate!
	) {
		organizationAccountUpdate(
			organizationId: $organizationId
			organizationAccount: $organizationAccount
		) {
			id
			legalName
			displayName
			handle
			mainPhoneNumber {
				countryCode
				number
			}
			systemEmailAddress
			avatarFile {
				fileId
				file {
					id
					currentInstance {
						id
						fileId
						cdnUrl
					}
				}
			}
			bannerFile {
				fileId
				file {
					id
					currentInstance {
						id
						fileId
						cdnUrl
					}
				}
			}
		}
	}
`;

/**
 * Hook for loading and editing OrgBasicInfo form
 *
 * @param {string} orgId
 * @return {*}
 */
const useOrgBasicInfo = (orgId: string) => {
	const {
		account,
		loading: queryLoading,
		error: queryError,
	} = useOrgBasicInformationQuery(orgId);
	const {
		updateOrgBasicInformation: updateOrgBasicInformationMutation,
		error: mutationError,
		reset,
	} = useOrgBasicInformationMutation(orgId);
	const { hasPermission: hasAssociateUserPermission } = useAssociateUser(orgId);
	const { hasPermission: hasSiteUserPermission } = useSiteUser();
	const { showModal, closeModal } = useModal();
	const location = useLocation();
	const navigate = useNavigate();
	const [isEditing, setIsEditing] = useState<boolean>(false);
	const toast = useToast();
	const validation = useValidation('OrganizationAccount');

	const onEdit = () => {
		setIsEditing(true);
		reset();
	};

	const onCancel = () => setIsEditing(false);

	const initialValues = {
		displayName: account?.displayName ?? '',
		legalName: account?.legalName ?? '',
		handle: account?.handle ?? '',
		mainPhoneNumber: account?.mainPhoneNumber ?? {
			countryCode: '',
			number: '',
		},
		systemEmailAddress: account?.systemEmailAddress ?? '',
	};

	const onSubmit = (values: OrganizationAccountUpdate) => {
		const handleChanged = values.handle !== initialValues.handle;

		const updateOrgBasicInformation = (values: OrganizationAccountUpdate) => {
			return updateOrgBasicInformationMutation(values)
				.then((res) => {
					if (responseHasErrors(res.errors, { toast })) {
						return true;
					}
					setIsEditing(false);

					// Update url if handle changes
					if (handleChanged) {
						const fullPath = location.pathname;
						// Get substring of path after handle (eg '/oldhandle/account' -> '/account')
						let pathEnd = fullPath.substring(
							fullPath.indexOf(initialValues.handle) + initialValues.handle.length
						);

						// Replace the current url with the updated handle without reloading the page
						navigate('/' + values.handle + pathEnd, { replace: true });
					}

					toast.push('Information updated successfully.', {
						variant: 'success',
					});
					return true;
				})
				.catch(() => {
					handleNoResponse({ toast });
					return true;
				});
		};

		if (handleChanged) {
			showModal({
				title: 'Are you sure?',
				content: (
					<ModalContent>
						<ConfirmModalContent
							visual={
								<EmptyStateAvatar
									avatarProps={{ bgcolor: 'error.50' }}
									iconProps={{ color: 'error.500' }}
									icon={<ErrorOutlined />}
								/>
							}
							subheadline="Change Handle?"
							informativeContent="Links to this handle on external sites will not automatically update."
						/>
					</ModalContent>
				),
				actions: (
					<ModalActions>
						<ModalActionButton size="large" variant="outlined" onClick={closeModal}>
							Cancel
						</ModalActionButton>
						<ModalLoadingButton
							size="large"
							variant="contained"
							color="error"
							onClick={() => updateOrgBasicInformation(values)}>
							Continue Anyways
						</ModalLoadingButton>
					</ModalActions>
				),
			});

			return Promise.resolve(true);
		}

		return updateOrgBasicInformation(values);
	};

	return {
		account,
		loading: queryLoading,
		error: queryError || mutationError,
		isEditing,
		onEdit,
		canEdit:
			hasAssociateUserPermission(Permission.Org_Account_U) ||
			hasSiteUserPermission(Permission.Site_OrgAcct_U),
		onCancel,
		initialValues,
		onSubmit,
		validationSchema: validation.schema,
	};
};

/**
 * The Organization Basic Information card
 *
 * @param {{ orgId: string }} props
 * @return {*}
 */
export const OrgBasicInfo = (props: { orgId: string }) => {
	const {
		account,
		loading,
		error,
		isEditing,
		onEdit,
		onCancel,
		initialValues,
		onSubmit,
		validationSchema,
		canEdit,
	} = useOrgBasicInfo(props.orgId);
	if (error) throw PageError;
	const { cancellablePromise } = useCancellablePromise();
	const handleSubmit = (values: OrganizationAccountUpdate) => {
		return cancellablePromise(onSubmit(values)).promise;
	};

	return (
		<>
			<PageTitle title="Account Settings" />
			<Formik<OrganizationAccountUpdate>
				initialValues={initialValues}
				onSubmit={handleSubmit}
				validationSchema={validationSchema}
				enableReinitialize>
				{(fProps) => (
					<Card>
						<CardHeader
							title={
								<Typography variant="h2" height="2rem">
									Basic Information
								</Typography>
							}
							action={
								isEditing ? (
									<Stack direction="row" spacing={1}>
										<Button
											variant="outlined"
											onClick={() => {
												fProps.resetForm();
												onCancel();
											}}>
											Cancel
										</Button>
										<LoadingButton
											disabled={
												!fProps.isValid ||
												!fProps.dirty ||
												fProps.isSubmitting
											}
											color="primary"
											onClick={fProps.submitForm}
											loading={fProps.isSubmitting}
											variant="contained">
											Save
										</LoadingButton>
									</Stack>
								) : (
									<>
										{canEdit && (
											<IconButton onClick={onEdit} edge="end">
												<EditOutlined />
											</IconButton>
										)}
									</>
								)
							}
						/>
						<Divider />
						<CardContent>
							<Stack spacing={1.5} pt={1}>
								{isEditing && <Alert error={error} />}
								{loading || !account ? (
									<OrgBasicInfoSkeleton />
								) : isEditing ? (
									<OrgBasicInfoEdit account={account} />
								) : (
									<OrgBasicInfoRead account={account} canEdit={canEdit} />
								)}
							</Stack>
						</CardContent>
					</Card>
				)}
			</Formik>
		</>
	);
};

/**
 * A read only view of the organization's basic information
 *
 * @param {{ account: OrgAccount }} { account }
 * @return {*}
 */
const OrgBasicInfoRead = ({
	account,
	canEdit,
}: {
	account: OrganizationAccount;
	canEdit: boolean;
}) => {
	const { hasPermission } = useSiteUser();
	const { showModal } = useModal();
	const isSiteUser = hasPermission(Permission.Site_OrgAcct_U);
	const { updateOrganizationActivation } = useUpdateOrganizationActivationMutation(account.id);

	const onUpdateOrgActivation = () => {
		const deactivated = account?.deactivated;
		const verb = deactivated ? 'Activate' : 'Deactivate';

		showModal({
			title: `${verb} User?`,
			content: (
				<ConfirmModalContent
					visual={
						deactivated ? (
							<EmptyStateAvatar
								avatarProps={{ bgcolor: 'primary.50' }}
								iconProps={{ color: 'primary.500' }}
								icon={<PowerSettingsNewOutlined />}
							/>
						) : (
							<EmptyStateAvatar
								avatarProps={{ bgcolor: 'error.50' }}
								iconProps={{ color: 'error.500' }}
								icon={<ErrorOutlined />}
							/>
						)
					}
					subheadline="Are you sure?"
					informativeContent={`Do you really want to ${verb} ${account?.legalName}?`}
				/>
			),
			actions: (
				<ModalActions>
					<ModalActionButton size="large" variant="outlined">
						Cancel
					</ModalActionButton>
					<ModalLoadingButton
						size="large"
						variant="contained"
						color={deactivated ? 'primary' : 'error'}
						onClick={async () =>
							await updateOrganizationActivation(
								deactivated
									? ActivationOperation.Activate
									: ActivationOperation.Deactivate
							)
						}>
						{`${verb} Organization`}
					</ModalLoadingButton>
				</ModalActions>
			),
		});
	};

	return (
		<Grid container alignItems="stretch" justifyContent="center">
			<Grid item sm={12} md={4} paddingBottom={{ xs: 2, md: 'unset' }}>
				<Stack alignItems="center" spacing={1.5}>
					<OrgAvatarUploadButton id={account.id} disabled={!canEdit} />
					<Typography variant="h3">{account.displayName}</Typography>
					{isSiteUser && (
						<Chip
							size="small"
							label={
								account.deactivated
									? 'Inactive Organization'
									: 'Active Organization'
							}
							color={account.deactivated ? 'error' : 'primary'}
							clickable={isSiteUser}
							onClick={isSiteUser && onUpdateOrgActivation}
							data-test="user-status-chip"
						/>
					)}
				</Stack>
			</Grid>
			<Grid container item sm={12} md={8} alignContent="flex-start" spacing={2}>
				<Grid container item>
					<Grid item sm={1} pr={{ xs: 1, sm: 'unset' }}>
						<ApartmentOutlined fontSize="small" />
					</Grid>
					<Grid item sm={3}>
						<Typography variant="h5">Legal Name</Typography>
					</Grid>
					<Grid item xs={12} sm={8}>
						{account.legalName}
					</Grid>
				</Grid>
				<Grid container item>
					<Grid item sm={1} pr={{ xs: 1, sm: 'unset' }}>
						<AlternateEmail fontSize="small" />
					</Grid>
					<Grid item sm={3}>
						<Typography variant="h5">Handle</Typography>
					</Grid>
					<Grid item xs={12} sm={8}>
						{account.handle}
					</Grid>
				</Grid>
				<Grid container item>
					<Grid item sm={1} pr={{ xs: 1, sm: 'unset' }}>
						<PhoneOutlined fontSize="small" />
					</Grid>
					<Grid item sm={3}>
						<Typography variant="h5">Phone Number</Typography>
					</Grid>
					<Grid item xs={12} sm={8}>
						<PhoneNumberText number={account.mainPhoneNumber} />
					</Grid>
				</Grid>
				<Grid container item>
					<Grid item sm={1} pr={{ xs: 1, sm: 'unset' }}>
						<EmailOutlined fontSize="small" />
					</Grid>
					<Grid item sm={3}>
						<Typography variant="h5">Primary E-mail</Typography>
					</Grid>
					<Grid item xs={12} sm={8}>
						{account.systemEmailAddress}
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

/**
 * Renders the form for editing the organization's basic information
 *
 * @param {{ account: OrgAccount }} { account }
 * @return {*}
 */
const OrgBasicInfoEdit = connect<{ account: OrganizationAccount }, OrganizationAccountUpdate>(
	(props) => {
		return (
			<Stack spacing={2}>
				<Stack direction="row" spacing={1} alignItems="flex-start">
					<Box>
						<ApartmentOutlined sx={{ marginTop: 2.5 }} fontSize="small" />
					</Box>
					<Grid container columnSpacing={2}>
						<Grid xs={12} md={6} item>
							<TextField
								label="Display Name"
								type="text"
								name="displayName"
								disabled={props.formik.isSubmitting}
								required
							/>
						</Grid>
						<Grid xs={12} md={6} item>
							<TextField label="Legal Name" type="text" name="legalName" required />
						</Grid>
					</Grid>
				</Stack>
				<Stack direction="row" spacing={1} alignItems="flex-start">
					<Box>
						<AlternateEmail sx={{ marginTop: 2.5 }} fontSize="small" />
					</Box>
					<Grid container columnSpacing={2}>
						<Grid item xs={12} md={6}>
							<TextField label="Handle" type="text" name="handle" required />
						</Grid>
					</Grid>
				</Stack>
				<Stack direction="row" spacing={1} alignItems="flex-start">
					<Box>
						<EmailOutlined sx={{ marginTop: 2.5 }} fontSize="small" />
					</Box>
					<Grid container columnSpacing={2}>
						<Grid item xs={12} md={6}>
							<TextField
								label="Primary Email"
								type="text"
								name="systemEmailAddress"
								disabled={props.formik.isSubmitting}
								required
							/>
						</Grid>
					</Grid>
				</Stack>
				<Stack direction="row" spacing={1} alignItems="flex-start">
					<Box>
						<PhoneOutlined sx={{ marginTop: 2.5 }} fontSize="small" />
					</Box>
					<Grid container columnSpacing={2}>
						<Grid item xs={12} md={6}>
							<PhoneNumberField
								label="Phone Number"
								name="mainPhoneNumber"
								required
								disabled={props.formik.isSubmitting}
							/>
						</Grid>
					</Grid>
				</Stack>
			</Stack>
		);
	}
);

const OrgBasicInfoSkeleton = () => (
	<Grid container alignItems="stretch">
		<Grid item sm={4} md={4}>
			<Skeleton height="10rem" width="10rem" sx={{ margin: 'auto' }} variant="rectangular" />
		</Grid>
		<Grid item sm={8} md={8}>
			<Stack>
				<Skeleton height="2.5rem" width="50%" />
				<Skeleton height="2.5rem" width="50%" />
				<Skeleton height="2.5rem" width="50%" />
				<Skeleton height="2.5rem" width="50%" />
			</Stack>
		</Grid>
	</Grid>
);
