import { useMutation, useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import {
	Mutation,
	MutationUserProfileUsaAdjusterLicensingDeleteArgs,
	MutationUserProfileUsaAdjusterLicensingSetManualArgs,
	MutationUserProfileUsaAdjusterLicensingSetNiprArgs,
	Query,
	QueryAccountArgs,
	QueryUsaAdjusterLicensingArgs,
	QueryUsaLicenseClassesArgs,
	QueryUsaLinesOfAuthorityArgs,
	QueryUsaNiprAdjusterLookupArgs,
	UsaAdjusterLicensingInput,
} from 'middleware-types';
import { PageError, handleNoResponse, responseHasErrors } from 'utils/errors';
import { useUsStates } from 'utils/useCountries';
import { ValidationSchema, useValidation } from 'utils/useValidation';
import * as yup from 'yup';
import { ADJUSTERLICENSESUMMARIES } from '../hooks';
import { useToast } from 'components/ui/toast';

/**
 * NIPRLOOKUP - Query to lookup NIPR information based on provided inputs.
 * @type {*}
 */
export const NIPRLOOKUP = gql`
	query niprLookup(
		$npnNumber: Int
		$firstName: String
		$lastName: String
		$ssNumber: String
		$birthDate: Date!
	) {
		usaNiprAdjusterLookup(
			npnNumber: $npnNumber
			firstName: $firstName
			lastName: $lastName
			ssNumber: $ssNumber
			birthDate: $birthDate
		) {
			name
			npn
			stateOfResidence
			birthDate
		}
	}
`;

/**
 * useLookupNIPRQueryValidation() - This hook provides
 * validation schema rules for the NIPR lookup query inputs.
 *
 * @return {*}
 */
export const useLookupNIPRQueryValidation = () => {
	const validation = useValidation('usaNiprAdjusterLookup');
	let validationSchema: ValidationSchema | undefined;
	if (validation.schema) {
		validationSchema = validation.schema.shape({
			lastName: yup
				.string()
				.nullable()
				.test('lastNameMin', ' Please enter at least 2 characters', (val) => {
					if (val && val.length < 2) return false;
					return true;
				})
				.test('lastNameRequired', 'Last Name required', (val, context) => {
					let { parent } = context;
					if (parent.npnNumber) return true;
					if (!val && parent.firstName) return false;
					return true;
				}),
			firstName: yup
				.string()
				.nullable()
				.test('firstNameRequired', 'First name required', (val, context) => {
					let { parent } = context;
					if (parent.npnNumber) return true;
					if (!val && parent.lastName) return false;
					return true;
				}),
			npnNumber: yup
				.string()
				.nullable()
				.test('npnRequired', 'First and Last Name or NPN is required', (val, context) => {
					return !val && !context.parent.lastName && !context.parent.firstName
						? false
						: true;
				}),
		});
	}

	return { loading: validation.loading, validationSchema };
};

/**
 * useLookupNIPRQuery - This query provides lookup results for NIPR information.
 *
 * @param {NiprLookupArgs} lookup
 * @return {*}
 */
export const useLookupNIPRQuery = (lookup: QueryUsaNiprAdjusterLookupArgs) => {
	const { loading, data, error } = useQuery<
		Pick<Query, 'usaNiprAdjusterLookup'>,
		QueryUsaNiprAdjusterLookupArgs
	>(NIPRLOOKUP, {
		fetchPolicy: 'cache-and-network',
		variables: lookup,
	});
	return { results: data?.usaNiprAdjusterLookup ?? [], loading, error };
};

/**
 * Mutation for setting the NIPR data of a user.
 * @type {*}
 */
export const SET_USA_ADJUSTER_LICENSING_NIPR = gql`
	mutation userProfileUsaAdjusterLicensingSetNipr($userId: ID!, $npnNumber: Int!) {
		userProfileUsaAdjusterLicensingSetNipr(userId: $userId, npnNumber: $npnNumber) {
			success
			message
			adjusterLicensingSummary {
				userId
				licensingProvenance
				countryId
				adjusterLicensingText
				adjusterLicensingTextSensitive
				numLicenses
				numActiveLicenses
				numExpiredLicenses
				numExpiredWithin60DaysLicenses
			}
		}
	}
`;

/**
 * useUsaAdjusterLicensingNIPRMutation(userId) - Hook to update the user licenses using NIPR.
 *
 * @param {string} userId
 * @return {*}
 */
export const useUsaAdjusterLicensingNIPRMutation = (userId: string) => {
	const [updateLicensingMut, { error, loading }] = useMutation<
		Pick<Mutation, 'userProfileUsaAdjusterLicensingSetNipr'>,
		MutationUserProfileUsaAdjusterLicensingSetNiprArgs
	>(SET_USA_ADJUSTER_LICENSING_NIPR, {
		refetchQueries: [{ query: ADJUSTERLICENSESUMMARIES, variables: { userId } }],
		awaitRefetchQueries: true,
	});
	const updateLicenses = async (npnNumber: number) => {
		return updateLicensingMut({
			variables: {
				userId,
				npnNumber,
			},
		});
	};

	return { updateLicenses, error, loading };
};

export const EXISTINGUSAADJUSTERLICENSING = gql`
	query usaAdjusterLicensing($userId: ID!) {
		usaAdjusterLicensing(userId: $userId) {
			npn
		}
		account(userId: $userId) {
			id
			name {
				firstName
				lastName
			}
			birthDate
		}
	}
`;

/**
 * useExistingLicenseQuery - This query provides the user account info and npn number of an existing user.
 *
 * @param {NiprLookupArgs} lookup
 * @return {*}
 */
export const useExistingLicenseQuery = (userId: string, skip: boolean) => {
	const { loading, data, error } = useQuery<
		Pick<Query, 'usaAdjusterLicensing' | 'account'>,
		QueryUsaAdjusterLicensingArgs & QueryAccountArgs
	>(EXISTINGUSAADJUSTERLICENSING, {
		fetchPolicy: 'cache-and-network',
		variables: {
			userId,
		},
		skip,
	});

	return {
		...data?.account,
		npn: data?.usaAdjusterLicensing?.npn,
		loading,
		error,
	};
};

export const DELETE_USA_ADJUSTER_LICENSING = gql`
	mutation userProfileUsaAdjusterLicensingDelete($userId: ID!) {
		userProfileUsaAdjusterLicensingDelete(userId: $userId)
	}
`;

/**
 * useDeleteUsaAdjusterLicensesMutation(userId) - Hook to delete the user usa licenses.
 *
 * @param {string} userId
 * @return {*}
 */
export const useDeleteUsaAdjusterLicensesMutation = (userId: string) => {
	const [updateLicensingMut, { error, loading }] = useMutation<
		Pick<Mutation, 'userProfileUsaAdjusterLicensingDelete'>,
		MutationUserProfileUsaAdjusterLicensingDeleteArgs
	>(DELETE_USA_ADJUSTER_LICENSING, {
		refetchQueries: [{ query: ADJUSTERLICENSESUMMARIES, variables: { userId } }],
		awaitRefetchQueries: true,
	});
	const deleteLicenses = async () => {
		return updateLicensingMut({
			variables: {
				userId,
			},
		});
	};

	return { deleteLicenses, error, loading };
};

/** Gets the existing USA Licensing information for the requested standard user. */
export const USAADJUSTERLICENSING = gql`
	query usaAdjusterLicensing($userId: ID!) {
		usaAdjusterLicensing(userId: $userId) {
			npn
			birthDate
			firstName
			lastName
			middleName
			suffix
			statesLicensing {
				stateAddressFieldLookupId
				fullNameAlias
				stateLicenses {
					number
					originalIssueDate
					expirationDate
					active
					licenseClassId
				}
				stateAppointments {
					companyName
					lineOfAuthorityId
					status
					renewalDate
				}
			}
		}
	}
`;

/**
 * useUsaLicensingQuery - This query provides the license info for a user.
 *
 * @param {userId} userid to query.
 * @return {*}
 */
export const useUsaLicensingQuery = (userId: string) => {
	const { loading: loadingStates, states } = useUsStates();

	const { loading, data, error } = useQuery<
		Pick<Query, 'usaAdjusterLicensing'>,
		QueryUsaAdjusterLicensingArgs
	>(USAADJUSTERLICENSING, {
		fetchPolicy: 'cache-and-network',
		variables: {
			userId,
		},
	});

	if (error) throw new PageError(error);

	return {
		licensing: data?.usaAdjusterLicensing
			? {
					...data.usaAdjusterLicensing,
					statesLicensing: data.usaAdjusterLicensing.statesLicensing
						.map((sl) => ({
							...sl,
							// Regulatory actions are not supported.
							stateRegulatoryActions: [],
						}))
						.sort((a, b) => {
							const aOrder = states.find(
								(state) => state.id === a.stateAddressFieldLookupId
							)?.displayOrder;
							const bOrder = states.find(
								(state) => state.id === b.stateAddressFieldLookupId
							)?.displayOrder;
							if (aOrder === undefined || bOrder === undefined) return 0;
							return aOrder - bOrder;
						}),
			  }
			: undefined,
		loading: loading || loadingStates,
		error,
	};
};

/** Gets the USA License classes for the requested state */
export const USALICENSECLASSES = gql`
	query usaLicenseClasses($state: String!) {
		usaLicenseClasses(state: $state) {
			id
			stateId
			code
			name
		}
	}
`;

/*  Gets the USA Lines of Authority for the requested state */
export const USALINESOFAUTHORITY = gql`
	query usaLinesofAuthority($state: String!) {
		usaLinesOfAuthority(state: $state) {
			id
			stateId
			code
			name
		}
	}
`;

/**
 * Queries for the license classes of a given state.
 *
 * @param {string} state
 * @return {*}  {{ loading: boolean; licenseClasses: QUsaLicenseClass[] }}
 */
export const useStateLicenseClassesQuery = (state: string) => {
	const { data, loading } = useQuery<
		Pick<Query, 'usaLicenseClasses'>,
		QueryUsaLicenseClassesArgs
	>(USALICENSECLASSES, {
		variables: { state: state },
	});
	return {
		loading,
		licenseClasses: [...(data?.usaLicenseClasses ?? [])].sort(function (a, b) {
			return a.name.localeCompare(b.name) || a.code - b.code;
		}),
	};
};

/**
 * Queries for the lines of authority of a given state.
 *
 * @param {string} state
 * @return {*}  {{ loading: boolean; linesOfAuthority: QUsaLineOfAuthority[] }}
 */
export const useStateLinesOfAuthorityQuery = (state: string) => {
	const toast = useToast();
	const { data, loading } = useQuery<
		Pick<Query, 'usaLinesOfAuthority'>,
		QueryUsaLinesOfAuthorityArgs
	>(USALINESOFAUTHORITY, {
		variables: { state: state },
		onError: () => {
			toast.push('Unable to load lines of authority', {
				variant: 'error',
			});
		},
	});

	return {
		loading,
		linesOfAuthority: [...(data?.usaLinesOfAuthority ?? [])].sort(function (a, b) {
			return a.name.localeCompare(b.name) || a.code - b.code;
		}),
	};
};

/* Mutation for setting the USA licensing data manually. */
export const SET_USA_ADJUSTER_LICENSING_MANUAL = gql`
	mutation userProfileUsaAdjusterLicensingSetManual(
		$userId: ID!
		$usLicensing: UsaAdjusterLicensingInput!
	) {
		userProfileUsaAdjusterLicensingSetManual(userId: $userId, usLicensing: $usLicensing) {
			userId
			licensingProvenance
			countryId
			adjusterLicensingText
			adjusterLicensingTextSensitive
			numLicenses
			numActiveLicenses
			numExpiredLicenses
			numExpiredWithin60DaysLicenses
		}
	}
`;

/**
 * useUsaAdjusterLicensingManualMutation(userId) - Hook to update the user licenses using manual licenses.
 *
 * @param {string} userId
 * @return {*}
 */
export const useUsaAdjusterLicensingManualMutation = (userId: string) => {
	const toast = useToast();
	const [updateLicensingMut, { error, loading }] = useMutation<
		Pick<Mutation, 'userProfileUsaAdjusterLicensingSetManual'>,
		MutationUserProfileUsaAdjusterLicensingSetManualArgs
	>(SET_USA_ADJUSTER_LICENSING_MANUAL, {
		refetchQueries: [{ query: USAADJUSTERLICENSING, variables: { userId } }],
		awaitRefetchQueries: true,
	});
	const updateLicenses = async (usLicensing: UsaAdjusterLicensingInput) => {
		return await updateLicensingMut({
			variables: {
				userId,
				usLicensing,
			},
		})
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	return { updateLicenses, error, loading };
};
