import {
	Box,
	Card,
	CardContent,
	CardHeader,
	CardMedia,
	Divider,
	Skeleton,
	Stack,
	Tab,
	Tabs,
} from '@mui/material';
import { SocialInteractionBar } from 'components/pages/network/user/SocialInteractionBar/SocialInteractionBar';
import { useState } from 'react';
import { useIsMobile } from 'utils/useScreenSize';
import { isDemo } from 'utils/utils';
import { ProfileHeaderEdit } from './edit';
import { useProfileHeaderQuery } from './hooks';
import { ProfileHeaderRead } from './read';

/**
 * useProfileHeader() - Hook for loading editing the ProfileHeader
 *
 * @param {string} userId
 * @return {*}
 */
const useProfileHeader = (userId: string) => {
	const [isEditing, setIsEditing] = useState<boolean>(false);

	// initialFocusedField references which Formik field name will be focused when edit mode is opened,
	// for instance companyTitle when clicking the Add Title link.
	const [initialFocusedField, setIntitialFocusedField] = useState<string | null>(null);
	const { profile, loading: loadingQuery } = useProfileHeaderQuery(userId);

	const onBeginEdit = (initialFocusedField?: string) => {
		if (initialFocusedField) setIntitialFocusedField(initialFocusedField);
		setIsEditing(true);
	};

	const onCancel = () => {
		if (initialFocusedField) setIntitialFocusedField(null);
		setIsEditing(false);
	};

	return {
		profile,
		loading: loadingQuery,
		isEditing,
		onBeginEdit,
		onCancel,
		initialFocusedField,
	};
};

/**
 * ProfileHeader is the top card in profile
 *
 * @param {{ userId: string }} props
 * @return {*}
 */
export const ProfileHeader = (props: { userId: string }) => {
	const { profile, isEditing, onBeginEdit, onCancel, initialFocusedField } = useProfileHeader(
		props.userId
	);

	const isMobile = useIsMobile();

	if (!profile) return <ProfileHeaderSkeleton />;
	return (
		<Card sx={isMobile ? { borderRadius: '0px' } : undefined}>
			{isEditing && profile ? (
				<ProfileHeaderEdit
					profile={profile}
					onCancel={onCancel}
					initialFocusedField={initialFocusedField}
				/>
			) : (
				<ProfileHeaderRead profile={profile} onBeginEdit={onBeginEdit} />
			)}
			{isMobile && isDemo() && (
				<Box paddingX={2} paddingBottom={2} paddingTop={{ xs: 2, sm: undefined }}>
					<SocialInteractionBar profile={profile} />
				</Box>
			)}
			<Divider />
			<Stack
				direction="row"
				justifyContent="space-between"
				alignItems="center"
				px={{ xs: 2, sm: 2.5 }}
				spacing={3}
				overflow="auto">
				<Tabs value="Profile" indicatorColor="primary" scrollButtons={false}>
					<Tab label={'Profile'} value="Profile" />
				</Tabs>
				{!isMobile && isDemo() && <SocialInteractionBar profile={profile} />}
			</Stack>
		</Card>
	);
};

const ProfileHeaderSkeleton = () => (
	<Card>
		<CardMedia
			component="img"
			alt="Profile Banner"
			image="/img/profile-banner.png"
			height="160"
		/>
		<CardHeader
			style={{ height: 'inherit' }}
			avatar={<Skeleton variant="circular" width="160px" height="160px" />}
			title={<Skeleton height="2.5rem" width="10rem" />}
			subheader={
				<Stack spacing={1}>
					<Skeleton height="2rem" width="25rem" />
					<Skeleton height="2rem" width="25rem" />
					<Skeleton height="2rem" width="25rem" />
				</Stack>
			}
		/>
		<Divider />
		<CardContent>
			<Skeleton height="2rem" width="100%" />
		</CardContent>
	</Card>
);
