import {
	ArrowBackIos,
	DeleteOutlined,
	MarkEmailReadOutlined,
	Notifications,
	NotificationsNoneOutlined,
} from '@mui/icons-material';
import { Box, Button, Divider, IconButton, List, Skeleton, Stack, Typography } from '@mui/material';
import { Fragment } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import {
	useNotificationDismissAllMutation,
	useNotificationMarkAllReadMutation,
	useNotificationsQuery,
} from 'utils/useNotifications';
import { useIsMobile } from 'utils/useScreenSize';
import { NotificationListItem } from './notification-list-item';
import { EmptyStateAvatar } from '../empty-state-avatar';

interface NotificationsPopupProps {
	onClose: () => void;
}

export const NotificationsPopup = ({ onClose }: NotificationsPopupProps) => {
	const { dismissAllNotifications } = useNotificationDismissAllMutation();
	const { readAllNotifications } = useNotificationMarkAllReadMutation();
	const { notifications, totalCount, loading, loadMore } = useNotificationsQuery();

	const isMobile = useIsMobile();

	return (
		<Stack width="100%" overflow="hidden">
			<Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="space-between">
				<Stack direction="row" alignItems="center" spacing={1.5} px={2} py={1.5}>
					{isMobile && (
						<Stack direction="row" alignItems="center">
							<IconButton onClick={onClose}>
								<ArrowBackIos />
							</IconButton>
							<Notifications color="primary" />
						</Stack>
					)}
					<Typography variant="h1">Notifications</Typography>
				</Stack>
				<Divider sx={{ display: { xs: 'block', sm: 'none' } }} />
				<Stack direction="row" spacing={2} px={2} py={1.5} justifyContent="space-evenly">
					<Button startIcon={<MarkEmailReadOutlined />} onClick={readAllNotifications}>
						Mark all as read
					</Button>
					<Button startIcon={<DeleteOutlined />} onClick={dismissAllNotifications}>
						Dismiss all
					</Button>
				</Stack>
			</Stack>
			<Divider />
			{loading ? (
				[...Array(3)].map((_, index) => (
					<Fragment key={index}>
						<Stack direction="row" alignItems="center" pl={5} py={1} spacing={2}>
							<Skeleton variant="circular" height={40} width={40} />
							<Box flex={1}>
								<Skeleton variant="text" height={20} width="60%" />
								<Skeleton variant="text" height={20} width="20%" />
							</Box>
						</Stack>
						<Divider />
					</Fragment>
				))
			) : notifications.length === 0 ? (
				<Stack alignItems="center" p={5}>
					<EmptyStateAvatar icon={<NotificationsNoneOutlined />} />
					<Box px={{ sm: 10 }} textAlign="center">
						<Typography variant="h3" textAlign="center" pt={2} pb={0.5}>
							You have no notifications.
						</Typography>
						<Typography variant="body1">
							This is where you’ll receive notifications for network requests,
							messages, and more.
						</Typography>
					</Box>
				</Stack>
			) : (
				<List disablePadding sx={{ overflow: 'auto' }}>
					<InfiniteScroll
						loadMore={loadMore}
						hasMore={notifications.length < totalCount}
						useWindow={false}>
						{notifications.map((notification) => (
							<NotificationListItem
								key={notification.id}
								notification={notification}
								onClose={onClose}
							/>
						))}
					</InfiniteScroll>
				</List>
			)}
		</Stack>
	);
};
