import { SearchOutlined } from '@mui/icons-material';
import { Box, Button, Divider, IconButton, Link, Popover, Stack } from '@mui/material';
import { OrgDropDown } from 'components/pages/org-dropdown';
import RootQuickSearch from 'components/pages/root-quick-search';
import { useState } from 'react';
import { useSession } from 'utils/session';
import { useIsSmallScreen } from 'utils/useScreenSize';
import { isDemo } from 'utils/utils';
import { NotificationsPopup } from '../notifications/notifications-popup';
import { UserPanel } from '../user-menu';
import { DesktopPrimaryNavLink } from './desktop-primary-nav-link';
import { siteAdminLinkInfo, usePrimaryNavLinks } from './hooks';

export const PrimaryNavDesktop = () => {
	const { user } = useSession();
	const isSiteUser = Boolean(user.siteUserId);

	const [notificationsAnchor, setNotificationsAnchor] = useState<HTMLElement | null>(null);
	const notificationsOpen = Boolean(notificationsAnchor);

	const links = usePrimaryNavLinks(notificationsOpen, (e) =>
		setNotificationsAnchor(e.currentTarget)
	);

	const isSmallScreen = useIsSmallScreen();
	const [openSearch, setOpenSearch] = useState(false);

	const handleSkipToContent = (e: React.MouseEvent<HTMLAnchorElement>) => {
		e.preventDefault();
		const mainContent = document.querySelector('#main-content') as HTMLElement;
		if (mainContent) {
			const firstFocusableElement = mainContent.querySelector(
				'a, button, input, textarea, select, [tabindex]:not([tabindex="-1"])'
			) as HTMLElement;
			if (firstFocusableElement) {
				firstFocusableElement.focus();
			} else {
				// If no focusable element is found, focus the main content itself
				mainContent.focus();
			}
		}
	};

	return (
		<Stack
			direction="row"
			alignItems="center"
			spacing={1}
			px={2}
			minHeight={64}
			sx={{
				backgroundColor: 'background.paper',
				borderBottom: '1px solid',
				borderBottomColor: 'neutral.200',
			}}>
			<Button
				component="a"
				size="large"
				onClick={handleSkipToContent}
				variant="contained"
				color="primary"
				disableRipple
				sx={{
					'position': 'absolute',
					'left': '-10000px',
					'&:focus': {
						left: 10,
						top: 10,
					},
					'zIndex': 1,
				}}>
				Skip to content
			</Button>
			<Stack pr={1}>
				<Link href="/" lineHeight={0}>
					<Box
						component="img"
						height="2.5rem"
						src="/img/evolve-logo.svg"
						alt="FTEvolve"
					/>
				</Link>
			</Stack>
			{!isSiteUser && <OrgDropDown />}
			{isDemo() && (
				<>
					{openSearch || !isSmallScreen ? (
						<RootQuickSearch
							setSearchFocused={setOpenSearch}
							searchFocused={openSearch}
						/>
					) : (
						<IconButton onClick={() => setOpenSearch(true)}>
							<SearchOutlined />
						</IconButton>
					)}
				</>
			)}
			<Box flex={1} />
			{(!isSmallScreen || !openSearch) &&
				links.map((link, index) => <DesktopPrimaryNavLink key={index} linkInfo={link} />)}
			<UserPanel />
			{isSiteUser && (
				<>
					<Divider orientation="vertical" sx={{ height: '60%' }} />
					<DesktopPrimaryNavLink linkInfo={siteAdminLinkInfo} />
				</>
			)}
			{/** Notifications */}
			<Popover
				open={notificationsOpen}
				anchorEl={notificationsAnchor}
				onClose={() => setNotificationsAnchor(null)}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
				transformOrigin={{ vertical: 'top', horizontal: 'right' }}>
				<Stack width={700} maxHeight="75vh" overflow="hidden">
					<NotificationsPopup onClose={() => setNotificationsAnchor(null)} />
				</Stack>
			</Popover>
		</Stack>
	);
};
