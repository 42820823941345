import { ModalContainer } from 'components/ui/modal';
import { PrimaryNav } from 'components/ui/primary-nav/primary-nav';
import { SideRail } from 'components/ui/side-rail/side-rail';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useLoginRedirect } from 'utils/loginRedirect';
import { useSession } from 'utils/session';
import { useIsMobile } from 'utils/useScreenSize';

export const RootOutlet = () => {
	const { user } = useSession();
	const location = useLocation();
	const { redirectUrl, setRedirectUrl } = useLoginRedirect();
	const isMobile = useIsMobile();

	if (user.registered === false) {
		if (redirectUrl === null) {
			// use setTimeout to make setState call async, and avoid the following issue:
			// Warning: Cannot update a component while rendering a different component
			setTimeout(() => setRedirectUrl(location.pathname + location.search), 0);
		}
		return <Navigate to="app/register" />;
	}

	if (redirectUrl !== null) {
		const url = redirectUrl;
		// same as above
		setTimeout(() => setRedirectUrl(null), 0);
		return <Navigate to={url} />;
	}
	if (
		location.pathname === '/' ||
		location.pathname.startsWith('/orgs') ||
		isMobile ||
		user.siteUserId
	) {
		return (
			<ModalContainer>
				<PrimaryNav>
					<Outlet />
				</PrimaryNav>
			</ModalContainer>
		);
	}

	return (
		<ModalContainer>
			<PrimaryNav>
				<SideRail>
					<Outlet />
				</SideRail>
			</PrimaryNav>
		</ModalContainer>
	);
};
