import { Close } from '@mui/icons-material';
import { Divider, IconButton, Stack, Typography } from '@mui/material';
import { UserEmblemAvatar } from 'components/ui/emblem/emblem-avatar';
import { format } from 'date-fns';
import { filesize } from 'filesize';
import { FileInstanceInformation } from 'middleware-types';
import { VirusStatusChip } from 'utils/fileUtils';
import { AccessLevelChip } from '../components/access-level';
import { useVersionHistoryContext } from '../components/version-history-provider';

interface FileDetailsDrawerProps {
	file: FileInstanceInformation;
	onClose: () => void;
}

export const FileDetailsDrawer = ({ file, onClose }: FileDetailsDrawerProps) => {
	const { isVersionHistory } = useVersionHistoryContext();
	const dateString = format(new Date(file.uploadedUtc), 'M/d/yy h:mm a');
	return (
		<Stack>
			<Stack
				px={2}
				py={1}
				direction="row"
				alignItems="center"
				justifyContent="space-between"
				spacing={1}>
				<Typography variant="h3" noWrap>
					{file.fileName}
					{isVersionHistory && ` (v${file.versionNumber})`} (Details)
				</Typography>
				<IconButton onClick={onClose}>
					<Close />
				</IconButton>
			</Stack>
			<Divider />
			<Stack p={2.5} spacing={3.5}>
				<Stack spacing={0.5}>
					<Typography variant="h5">
						{isVersionHistory ? 'Upload Date' : 'Last Updated'}
					</Typography>
					<Typography variant="body1">{dateString}</Typography>
				</Stack>
				{file.uploadedBy && (
					<Stack spacing={0.5}>
						<Typography variant="h5">
							{isVersionHistory ? 'Uploaded By' : 'Last Updated By'}
						</Typography>
						<Stack direction="row" alignItems="center" spacing={1}>
							<UserEmblemAvatar id={file.uploadedBy.id} size={24} />
							<Typography variant="body1">
								{file.uploadedBy.name.firstName} {file.uploadedBy.name.lastName}
							</Typography>
						</Stack>
					</Stack>
				)}
				<Stack spacing={0.5} alignItems="flex-start">
					<Typography variant="h5">Privacy</Typography>
					<AccessLevelChip level={file.accessLevel} />
				</Stack>
				<Stack spacing={0.5} alignItems="flex-start">
					<Typography variant="h5">Virus Status</Typography>
					<VirusStatusChip virusStatus={file.virusStatus} />
				</Stack>
				<Stack spacing={0.5}>
					<Typography variant="h5">File Size</Typography>
					<Typography variant="body1">{filesize(file.fileSize)}</Typography>
				</Stack>
			</Stack>
		</Stack>
	);
};
