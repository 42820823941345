import { AddOutlined, ErrorOutlined } from '@mui/icons-material';
import { Box, Card, CardContent, CardHeader, Divider, IconButton, Skeleton } from '@mui/material';
import {
	AddressType,
	useAddressInputPage,
} from 'components/pages/user/account/locations/locations-form';
import { LocationInformationItem } from 'components/pages/user/account/locations/locations-item';
import { Alert } from 'components/ui/alert';
import {
	ConfirmModalContent,
	ModalActionButton,
	ModalActions,
	ModalLoadingButton,
	useModal,
} from 'components/ui/modal';
import { LocationAddress } from 'middleware-types';
import { Permission } from 'utils/permissions';
import { useAssociateUser } from 'utils/useAssociateUser';
import { useSiteUser } from 'utils/useSiteUser';
import {
	useAddOrUpdateOrgLocationAddressMutation,
	useDeleteOrgLocationAddressMutation,
	useOrgLocationsQuery,
} from './hooks';
import { EmptyStateAvatar } from 'components/ui/empty-state-avatar';
import React from 'react';

/**
 * useLocationEditModal(userId) - Opens an edit/add location modal.
 *
 * @param {string} userId
 * @return {*}
 */
const useLocationEditModal = (orgId: string) => {
	const { addOrUpdate } = useAddOrUpdateOrgLocationAddressMutation(orgId);
	const { showAddressInputPage } = useAddressInputPage();

	return {
		addOrEditLocation: (locationAddress?: Omit<LocationAddress, 'parentId'>) => {
			const title = locationAddress ? `Edit Address` : 'Add New Address';
			showAddressInputPage(addOrUpdate, title, locationAddress);
		},
	};
};

/**
 * useDeleteLocationModal(orgId) - Modal to delete a given location address.
 *
 * @param {*} orgId
 * @return {*}
 */
const useDeleteLocationModal = (orgId: string) => {
	const { deleteLocation } = useDeleteOrgLocationAddressMutation(orgId);
	const { showModal } = useModal();

	return {
		deleteLocation: (locationId: string) => {
			showModal({
				title: 'Are you sure?',
				content: (
					<ConfirmModalContent
						visual={
							<EmptyStateAvatar
								avatarProps={{ bgcolor: 'error.50' }}
								iconProps={{ color: 'error.500' }}
								icon={<ErrorOutlined />}
							/>
						}
						subheadline="Delete Address?"
						informativeContent="Do you really want to delete the address? This process cannot be undone."
					/>
				),
				actions: (
					<ModalActions>
						<ModalActionButton size="large" variant="outlined">
							Cancel
						</ModalActionButton>
						<ModalLoadingButton
							size="large"
							variant="contained"
							color="error"
							onClick={async () => await deleteLocation(locationId)}>
							Delete
						</ModalLoadingButton>
					</ModalActions>
				),
			});
		},
	};
};

/**
 * useMakeLocationPrimaryModal(userId) - Confirmation Modal to make a location primary
 *
 * @param {string} userId
 * @return {*}
 */
const useMakeLocationPrimaryModal = (userId: string) => {
	const { showModal } = useModal();
	const { addOrUpdate } = useAddOrUpdateOrgLocationAddressMutation(userId);

	return {
		makePrimary: (locationAddress: Omit<LocationAddress, 'parentId'>) => {
			const { id, ...update } = {
				...locationAddress,
				addressType: AddressType.Primary,
			};
			showModal({
				title: 'Are you sure?',
				content: (
					<ConfirmModalContent
						visual="/img/star-primary-large.svg"
						subheadline="Make Primary?"
						informativeContent="Do you really want to make this the primary address?"
					/>
				),
				actions: (
					<ModalActions>
						<ModalActionButton size="large" variant="outlined">
							Cancel
						</ModalActionButton>
						<ModalLoadingButton
							size="large"
							variant="contained"
							color="primary"
							onClick={async () => addOrUpdate(update, id)}>
							Make Primary
						</ModalLoadingButton>
					</ModalActions>
				),
			});
		},
	};
};

/**
 * Hook that provides the organization location information.
 *
 * @param {string} orgId
 * @return {*}
 */
const useOrgLocationInformation = (orgId: string) => {
	const { locations, loading: queryLoading, error: queryError } = useOrgLocationsQuery(orgId);

	return {
		locations,
		loading: queryLoading,
		error: queryError,
	};
};

/**
 * Returns the Organization Location Information Section.
 * @param param0
 * @returns
 */
export const OrgLocationInformation = ({ orgId }: { orgId: string }): React.JSX.Element => {
	const { locations, loading, error } = useOrgLocationInformation(orgId);
	const { deleteLocation } = useDeleteLocationModal(orgId);
	const { makePrimary } = useMakeLocationPrimaryModal(orgId);
	const { hasPermission: hasAssociateUserPermission } = useAssociateUser(orgId);
	const { hasPermission: hasSiteUserPermission } = useSiteUser();
	const { addOrEditLocation } = useLocationEditModal(orgId);

	const canAdd =
		hasAssociateUserPermission(Permission.Org_Account_U) ||
		hasSiteUserPermission(Permission.Site_OrgAcct_U);

	return (
		<>
			<Card>
				<CardHeader
					title="Location Information"
					action={
						canAdd && (
							<IconButton onClick={() => addOrEditLocation(undefined)} edge="end">
								<AddOutlined />
							</IconButton>
						)
					}
				/>
				<Divider />
				<CardContent>
					{error && <Alert error={error} />}
					{loading ? (
						<LocationInformationSkeleton />
					) : (
						locations && (
							<Box
								display="grid"
								pt={1}
								gap={2}
								gridTemplateColumns={{
									xs: 'repeat(auto-fill)',
									sm: 'repeat(auto-fill, 15rem)',
								}}>
								{[...locations]
									.sort((a) => (a.addressType === AddressType.Primary ? -1 : 0))
									.map((locationAddress) => (
										<LocationInformationItem
											key={locationAddress.id}
											disabled={canAdd}
											locationAddress={locationAddress}
											labels={{
												Alternate: 'Additional',
											}}
											onEdit={addOrEditLocation}
											onDelete={deleteLocation}
											onMakePrimary={makePrimary}
										/>
									))}
							</Box>
						)
					)}
				</CardContent>
			</Card>
		</>
	);
};

/**
 * The location information skeleton to display on load.
 * @returns
 */
const LocationInformationSkeleton = () => (
	<Box display="flex" flexWrap="wrap">
		{[...Array(3)].map((e, i) => (
			<Box key={i} mr={1} mb={1}>
				<Skeleton height="14rem" width="14rem" variant="rectangular" />
			</Box>
		))}
	</Box>
);
