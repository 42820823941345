import { Box, Paper, Stack, Typography } from '@mui/material';
import { useEditor } from '@tiptap/react';
import { EmblemAvatar } from 'components/ui/emblem/emblem-avatar';
import { extensions } from 'components/ui/rich-text-editor/extensions';
import { RichTextContent } from 'components/ui/rich-text-editor/rich-text-content';
import { format } from 'date-fns';
import { Message, ParticipantEntityType } from 'middleware-types';
import { MessageFileChip } from '../shared/message-file-chip';
import { ReadReceipt } from './read-receipt';

interface ConversationMessageProps {
	message: Message;
}

export const ConversationMessage = ({ message }: ConversationMessageProps) => {
	if (message.creatorEntityType === ParticipantEntityType.System) {
		return (
			<Box textAlign="center" px={2}>
				<Typography variant="body2">{message.messageText}</Typography>
			</Box>
		);
	}

	const editor = useEditor({
		content: message.messageText,
		extensions,
		editable: false,
	});
	if (!editor) return null;

	const dateString = format(new Date(message.createdUtc), 'MMMM d, y h:mm a');
	return (
		<Stack p={2} spacing={2.5} component={Paper} elevation={1} borderRadius={0}>
			<Stack direction="row" spacing={1.5} alignItems="center">
				<EmblemAvatar emblem={message.creatorEmblem} size={32} />
				<Box flex={1}>
					<Typography variant="h6">{message.creatorEmblem.displayName}</Typography>
					<Typography variant="body2" color="neutral.600">
						{dateString}
					</Typography>
				</Box>
				{/* <IconButton>
					<MoreHoriz />
				</IconButton> */}
			</Stack>
			<RichTextContent editor={editor} />
			{message.attachments.length > 0 && (
				<Stack direction="row" gap={1} flexWrap={'wrap'}>
					{message.attachments.map((a) => {
						const instance = a.currentInstance;
						if (!instance) return null;
						return <MessageFileChip key={a.id} fileData={instance} />;
					})}
				</Stack>
			)}
			<ReadReceipt message={message} />
		</Stack>
	);
};
