import {
	Add,
	ArrowDropDown,
	ChevronRight,
	FolderOutlined,
	ManageSearchOutlined,
	MoreHoriz,
	UploadOutlined,
} from '@mui/icons-material';
import {
	Box,
	Breadcrumbs,
	Button,
	Container,
	Dialog,
	IconButton,
	Link,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	Paper,
	Skeleton,
	Snackbar,
	Stack,
	Typography,
} from '@mui/material';
import { EmptyStateAvatar } from 'components/ui/empty-state-avatar';
import { FileDropOutline } from 'components/ui/file-drop-outline';
import { Loading } from 'components/ui/loading';
import { PageContent } from 'components/ui/page';
import { SortDirection } from 'middleware-types';
import { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { DocumentSearchField } from '../components/document-search-field';
import { DocumentSearchProvider } from '../components/document-search-provider';
import { DocumentsGrid } from '../components/documents-grid';
import { FileDisplay, FileDisplayToggle } from '../components/file-display-toggle';
import { FilesSortColumn, SortOptionsDropdown } from '../components/sort-options-dropdown';
import { useDocumentsContext } from '../documents-page';
import { useFolder } from '../hooks/folders/use-folder';
import { useCurrentFolderId } from '../hooks/use-current-folder-id';
import { useSortFiles } from '../hooks/use-sort-files';
import { useSortFolders } from '../hooks/use-sort-folders';
import { CreateUpdateFolderModal } from '../modals/create-update-folder-modal';
import { UploadModal } from '../modals/upload-modal';
import { getRootFolderName } from '../utils/enum-helpers';
import { DocumentsTable } from './documents-table';

export const DesktopDocumentsPage = () => {
	const [currentFolderId, setCurrentFolderId] = useCurrentFolderId();
	const { entityType, permissions } = useDocumentsContext();

	const [sortColumn, setSortColumn] = useState(FilesSortColumn.LastUpdated);
	const [sortDirection, setSortDirection] = useState(SortDirection.Descending);
	const [displayMode, setDisplayMode] = useState(FileDisplay.List);

	const [uploadModalOpen, setUploadModalOpen] = useState(false);
	const [createFolderModalOpen, setCreateFolderModalOpen] = useState(false);
	const [newAnchorEl, setNewAnchorEl] = useState<HTMLElement | null>(null);
	const [nestedAnchorEl, setNestedAnchorEl] = useState<HTMLElement | null>(null);

	const {
		isSearchActive,
		isRoot,
		name,
		ancestors,
		folders,
		files,
		loading,
		searchText,
		setSearchText,
		searchValue,
	} = useFolder();
	const sortedFiles = useSortFiles(files, sortColumn, sortDirection);
	const sortedFolders = useSortFolders(folders, sortColumn, sortDirection);
	const rootFolderName = getRootFolderName(entityType);
	const numResults = folders.length + files.length;

	const [filesToUpload, setFilesToUpload] = useState<File[]>([]);
	const onDropAccepted = (files: File[]) => {
		setFilesToUpload(files);
		setUploadModalOpen(true);
	};

	const { getRootProps, open, isDragActive } = useDropzone({
		onDropAccepted,
		noClick: true,
		noDragEventsBubbling: true,
		disabled: permissions.update.length === 0,
	});

	return (
		<PageContent>
			<Container maxWidth="xxl" disableGutters sx={{ height: '100%' }}>
				<Box height="100%" position="relative" component={Paper} {...getRootProps()}>
					<Stack height="100%" p={3} spacing={2}>
						<Stack
							direction="row"
							alignItems="center"
							justifyContent="space-between"
							spacing={2}>
							{loading ? (
								<Skeleton variant="text" width={300} />
							) : isSearchActive ? (
								<Typography variant="h3">
									{numResults} Result{numResults === 1 ? '' : 's'}
								</Typography>
							) : (
								<Breadcrumbs
									separator={<ChevronRight />}
									sx={{
										'flexShrink': 1,
										'overflow': 'hidden',
										'& .MuiBreadcrumbs-li': {
											flex: 1,
											overflow: 'hidden',
											maxWidth: 'fit-content',
										},
										'& .MuiLink-button': {
											width: '100%',
											overflow: 'hidden',
											whiteSpace: 'nowrap',
											textOverflow: 'ellipsis',
											color: 'neutral.900',
										},
									}}>
									{!isRoot && (
										<Link
											variant="h3"
											component="button"
											onClick={() => setCurrentFolderId(undefined)}>
											{rootFolderName}
										</Link>
									)}
									{ancestors.length > 0 &&
										(ancestors.length === 1 ? (
											<Link
												variant="h3"
												component="button"
												onClick={() => setCurrentFolderId(ancestors[0].id)}>
												{ancestors[0].name}
											</Link>
										) : (
											<IconButton
												onClick={(e) => setNestedAnchorEl(e.currentTarget)}
												sx={{ borderRadius: 1, p: 0.25 }}>
												<MoreHoriz />
											</IconButton>
										))}
									<Typography variant="h3" noWrap>
										{name}
									</Typography>
								</Breadcrumbs>
							)}
							<Stack direction="row" spacing={2} flexShrink={0}>
								<DocumentSearchField
									searchText={searchText}
									setSearchText={setSearchText}
								/>
								<SortOptionsDropdown
									column={sortColumn}
									setColumn={setSortColumn}
									direction={sortDirection}
									setDirection={setSortDirection}
								/>
								<FileDisplayToggle value={displayMode} setValue={setDisplayMode} />
								{permissions.update.length > 0 && (
									<Button
										variant="contained"
										color="primary"
										startIcon={<Add />}
										endIcon={<ArrowDropDown />}
										onClick={(e) => setNewAnchorEl(e.currentTarget)}>
										New
									</Button>
								)}
							</Stack>
						</Stack>
						<DocumentSearchProvider
							isSearchActive={isSearchActive}
							searchValue={searchValue}>
							{loading ? (
								<Stack flex={1} alignItems="center" justifyContent="center">
									<Loading />
								</Stack>
							) : numResults === 0 ? (
								isSearchActive ? (
									<Stack
										flex={1}
										alignItems="center"
										justifyContent="center"
										spacing={2}>
										<EmptyStateAvatar icon={<ManageSearchOutlined />} />
										<Box textAlign="center">
											<Typography variant="h5">No results found</Typography>
										</Box>
									</Stack>
								) : (
									<Stack
										flex={1}
										alignItems="center"
										justifyContent="center"
										spacing={2}>
										<EmptyStateAvatar icon={<UploadOutlined />} />
										<Box textAlign="center">
											<Typography variant="h5">
												Drop files here to upload
											</Typography>
											<Typography variant="body1">
												or use the &apos;New&apos; button above
											</Typography>
										</Box>
									</Stack>
								)
							) : displayMode === FileDisplay.List ? (
								<DocumentsTable folders={sortedFolders} files={sortedFiles} />
							) : (
								<Box overflow="auto">
									<DocumentsGrid folders={sortedFolders} files={sortedFiles} />
								</Box>
							)}
						</DocumentSearchProvider>
					</Stack>
					{/** file drop outline */}
					{isDragActive && <FileDropOutline borderRadius={1} />}
				</Box>
			</Container>
			{/** snackbar */}
			<Snackbar
				open={isDragActive}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				message={
					<Stack direction="row" alignItems="center">
						<UploadOutlined sx={{ mr: 1 }} />
						Drop file(s) to upload to &quot;{name}&quot;
					</Stack>
				}
				sx={{ '&.MuiSnackbar-root': { bottom: 48 } }}
			/>
			{/** new menu */}
			<Menu
				open={Boolean(newAnchorEl)}
				anchorEl={newAnchorEl}
				onClose={() => setNewAnchorEl(null)}
				onClick={() => setNewAnchorEl(null)}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
				transformOrigin={{ vertical: 'top', horizontal: 'right' }}>
				<MenuItem onClick={open}>
					<ListItemIcon>
						<UploadOutlined />
					</ListItemIcon>
					<ListItemText>Upload Files</ListItemText>
				</MenuItem>
				<MenuItem onClick={() => setCreateFolderModalOpen(true)}>
					<ListItemIcon>
						<FolderOutlined />
					</ListItemIcon>
					<ListItemText>New Folder</ListItemText>
				</MenuItem>
			</Menu>
			{/** nested folders */}
			<Menu
				open={Boolean(nestedAnchorEl)}
				anchorEl={nestedAnchorEl}
				onClose={() => setNestedAnchorEl(null)}
				onClick={() => setNestedAnchorEl(null)}>
				{[...ancestors].reverse().map((ancestor) => (
					<MenuItem key={ancestor.id} onClick={() => setCurrentFolderId(ancestor.id)}>
						<Typography variant="inherit" noWrap>
							{ancestor.name}
						</Typography>
					</MenuItem>
				))}
			</Menu>
			{/** upload files modal */}
			<Dialog open={uploadModalOpen} fullWidth maxWidth="md">
				<UploadModal
					files={filesToUpload}
					folderId={currentFolderId}
					folderName={name}
					onClose={() => setUploadModalOpen(false)}
				/>
			</Dialog>
			{/** create folder modal */}
			<Dialog open={createFolderModalOpen} fullWidth maxWidth="sm">
				<CreateUpdateFolderModal onClose={() => setCreateFolderModalOpen(false)} />
			</Dialog>
		</PageContent>
	);
};
